@import '../../stylesheets/imports/index';

.eb-chat-bubble-content {
  display: block;

  &--inner {
    width: auto;
    height: auto;
    background-color: #ffffff;
    overflow: hidden;

    &__text {
      padding: 10px 16px;

      p {
        margin: 0;
        word-break: break-word;
        white-space: pre-line;
        font-family: 'Noto Sans KR', 'Malgun Gothic', 'Helvetica', 'Arial',
          sans-serif;
      }
    }

    &__image {
      button {
        display: block;
        margin: 0;
        padding: 0;
        width: 100px;
        height: 100px;
        border: 0;
        background-position: center !important;
        background-size: cover !important;
      }
    }

    &__file {
      padding: 16px;
      background: color('white') !important;

      &--icon {
        display: inline-block;
        margin-right: 8px;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        background: color('gray-lighter');
        vertical-align: top;

        .eb-text {
          width: 100%;
        }
      }

      &--link {
        display: inline-table;
        max-width: 170px;
        height: 32px;
        line-height: 16px;
        word-break: break-all;
        vertical-align: middle;
        overflow: hidden;

        .eb-link {
          display: table-cell;
          height: 32px;
          color: #524fa1;
          vertical-align: middle;
          overflow: hidden;
        }
      }
    }
  }

  &--mine > &--inner {
    background: color('purple-light');
    color: color('white');
  }

  &--yours > &--inner {
    background: color('white');
  }
}

/* stylelint-disable no-descending-specificity */
/* stylelint-disable no-duplicate-selectors */

// ========== markdown content ==========

.eb-chat-bubble-content--inner > p {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
  line-height: 1.5;
  white-space: normal;

  // ===== markdown code snippet =====

  .eb-chat-bubble-content--code {
    position: relative;
    padding-left: 8px;
    border-radius: 4px;
    font-size: 14px;
    text-align: left;
    background: #282c34 !important;
    overflow: hidden;

    .eb-chat-bubble-content--code-shadow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset -20px -20px 20px 10px rgba(40, 44, 52, 0.8);
      border: none;
      background: none;
      background: transparent;
      cursor: zoom-in;
    }
  }

  // ===== others =====
}

.eb-chat-bubble-content {
  .eb-markdown {
    a {
      color: #6200ff;
      text-decoration: none;
      background-color: transparent;

      &:active,
      &:hover {
        outline-width: 0;
        text-decoration: underline;
      }
    }

    table {
      color: #333;

      & td,
      & th {
        border: 1px solid #dfe2e5;
        padding: 6px 13px;
      }

      & tr {
        background-color: #fff;
        border-top: 1px solid #c6cbd1;
      }

      & tr:nth-child(2n) {
        background-color: #f6f8fa;
      }
    }

    code {
      white-space: pre-wrap;
      max-height: 500px;
    }

    .katex-display {
      white-space: normal;
    }
  }
}
