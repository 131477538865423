@import '../../stylesheets/imports/index';

.eb-empty-state {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  text-align: center;
  padding: 1rem;
}
