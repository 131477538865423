@import '../../stylesheets/imports/index';

.eb-page-header {
  position: relative;

  &--wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
    max-width: calc(#{$container-xl-width} + 1.5rem * 2);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &__left-block {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
  }

  &__title-block {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
  }

  &--size-default {
    height: 11rem;

    @media #{$mobile} {
      height: 10rem;
    }
  }

  &--size-medium {
    height: 9.75rem;
  }

  &--size-small {
    height: 4rem;
    .eb-page-header--wrapper {
      justify-content: center;
    }
    .eb-page-header__left-block {
      align-items: center;
      padding-top: 0;
    }
  }

  &--role-educator {
    background: linear-gradient(162.44deg, #02a0b1 0%, #098392 100%);
  }

  &--role-default,
  &--role-user {
    background-color: color('purple');
  }

  &--role-admin {
    background-color: color('purple-black');
  }

  &--role-white {
    background-color: color('white');
  }

  &--role-user {
    .eb-page-header__title-block {
      display: flex;
      height: 6rem;
      flex-direction: column;
    }
  }

  &__background-image {
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center center;

    &--not-exist {
      display: none;
    }
  }

  &__detail {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
  }

  &__detail--is-title {
    .eb-text {
      @media #{$mobile} {
        font-size: 1.75rem;
      }
    }
  }

  &__right-block {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
  }

  &__tabs {
    .eb-horizontal-scroll-handler__hider {
      background-color: transparent;
    }
  }

  &__subtitle {
    opacity: 0.7;
    margin-top: 0.5rem;

    @media #{$mobile} {
      font-size: 0.875rem !important;
      margin-top: 0.5rem;
    }
  }

  &--is-mobile-wrap {
    @media #{$tabletOrSmaller} {
      height: auto;

      .eb-page-header__detail {
        flex-wrap: wrap;
        padding: 2rem 0;
      }

      .eb-page-header__left-block {
        flex: none;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 1rem;
      }

      .eb-page-header__right-block {
        flex: none;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      .eb-page-header__title-block {
        flex: none;
        width: auto;
        align-items: center;
      }
    }
  }
}
