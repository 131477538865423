@import '../../stylesheets/imports/index';

.eb-badge {
  display: inline-block;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  line-height: 1;

  &__icon {
    margin-right: 0.25rem;
  }

  &--role-primary {
    border-color: color('purple');
    color: color('purple');
  }

  &--fill-primary {
    &-full {
      background-color: color('purple');
      color: color('white');
    }

    &-half {
      background-color: rgba(color('purple'), 0.12);
    }
  }

  &--fill-purple-light {
    &-full {
      background-color: color('purple-light');
      color: color('white');
    }

    &-half {
      background-color: rgba(color('purple-light'), 0.12);
    }
  }

  &--role-purpleblack {
    border-color: color('purple-black');
    color: color('purple-black');
  }

  &--fill-purpleblack {
    &-full {
      background-color: color('purple-black');
      color: color('white');
    }

    &-half {
      background-color: rgba(color('purple'), 0.12);
    }
  }

  &--role-primary {
    border-color: color('purple');
    color: color('purple');
  }

  &--fill-primary {
    &-full {
      background-color: color('purple');
      color: color('white');
    }

    &-half {
      background-color: rgba(color('purple'), 0.12);
    }
  }

  &--role-secondary {
    border-color: color('turq');
    color: color('turq');
  }

  &--fill-secondary {
    &-full {
      background-color: color('turq');
      color: color('white');
    }

    &-half {
      background-color: rgba(color('turq'), 0.12);
    }
  }

  &--role-darkblue {
    border-color: color('darkblue');
    color: color('darkblue');
  }

  &--fill-darkblue {
    &-full {
      background-color: color('darkblue');
      color: color('white');
    }

    &-half {
      background-color: rgba(color('darkblue'), 0.12);
    }
  }

  &--role-darkblue-white {
    border-color: color('darkblue-white');
    color: color('darkblue');
  }

  &--fill-darkblue-white {
    &-full {
      background-color: color('darkblue-white');
      color: color('darkblue');
    }

    &-half {
      background-color: rgba(color('darkblue-white'), 0.12);
    }
  }

  &--role-dark {
    border-color: color('grey6');
    color: color('grey6');
  }

  &--fill-dark {
    &-full {
      background-color: color('grey6');
      color: color('white');
    }

    &-half {
      background-color: rgba(color('grey6'), 0.12);
    }
  }

  &--role-light {
    border-color: color('grey9');
    color: color('grey9');
  }

  &--fill-lightblack {
    &-full {
      background-color: color('greyd');
      color: color('grey6');
    }

    &-half {
      background-color: rgba(color('greyd'), 0.12);
    }
  }

  &--fill-light {
    &-full {
      background-color: color('grey9');
      color: color('white');
    }

    &-half {
      background-color: rgba(color('grey9'), 0.12);
    }
  }

  &--role-success {
    border-color: color('lime');
    color: color('lime');
  }

  &--fill-success {
    &-full {
      background-color: color('lime');
      color: color('white');
    }

    &-half {
      background-color: rgba(color('lime'), 0.12);
    }
  }

  &--role-warning {
    border-color: color('yellow');
    color: color('yellow');
  }

  &--fill-warning {
    &-full {
      background-color: color('yellow');
      color: color('white');
    }

    &-half {
      background-color: rgba(color('yellow'), 0.12);
    }
  }

  &--role-danger {
    border-color: color('red');
    color: color('red');
  }

  &--fill-danger {
    &-full {
      background-color: color('red');
      color: color('white');
    }

    &-half {
      background-color: rgba(color('red'), 0.12);
    }
  }

  &--role-white {
    border-color: color('white');
    color: color('white');
  }

  &--fill-white {
    &-full {
      background-color: color('white');
      color: color('black');
    }

    &-half {
      background-color: rgba(color('white'), 0.12);
    }
  }

  &--role-gray4 {
    border-color: color('gray4');
    color: color('gray4');
  }

  &--fill-gray4 {
    &-full {
      background-color: color('gray4');
      color: color('white');
    }

    &-half {
      background-color: rgba(color('gray4'), 0.12);
    }
  }

  &--size-small {
    border-radius: 2px;
    padding: 0 6px;
    height: rem(20px);
    line-height: rem(18px);
    font-size: 12px;
  }

  &--size-default {
    border-radius: 2px;
    padding: 0 8px;
    height: rem(32px);
    line-height: rem(30px);
    font-size: 14px;
  }

  &--size-large {
    border-radius: 2px;
    padding: 0 10px;
    height: rem(36px);
    line-height: rem(34px);
    font-size: 14px;
  }

  &--fixed-font-size {
    font-size: 14px;
  }

  &--clickable {
    cursor: pointer;
  }

  &--transparent {
    background-color: transparent !important;
  }

  &--round {
    border-radius: 100px;
  }

  &--no-border {
    border: 0;
  }

  &--bold {
    font-weight: bold;
  }
}
