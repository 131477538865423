@import '../../stylesheets/imports/index';

.eb-modal {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  z-index: 981;
  word-break: keep-all;

  &__content {
    display: inline-block;
    background-color: color('white');
    margin: 40px auto;
    border-radius: 4px;
    z-index: 982;
    text-align: left;
    cursor: default;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.3);

    @media #{$tabletOrSmaller} {
      margin: 0;
      display: flex;
      flex-direction: column;
      border-radius: 0;
    }

    &--narrow {
      width: 480px;
      .eb-form-input-layout .ant-col-12 {
        width: 100%;
      }

      @media #{$tabletOrSmaller} {
        width: 100vw;
        height: 100vh;
      }
    }

    &--default {
      width: 640px;

      @media #{$tabletOrSmaller} {
        width: 100vw;
        height: 100vh;
      }
    }

    &--wide {
      width: 800px;

      @media #{$tabletOrSmaller} {
        width: 100vw;
        height: 100vh;
      }
    }

    &--full {
      width: 100%;
      height: 100%;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }

  &__content.theme-purple {
    overflow: hidden;
    background-color: color('purple');

    .eb-modal__header {
      border-bottom: 1px solid color('white');
    }

    .eb-modal__title {
      color: color('white');
    }

    .eb-modal__close-button i {
      color: color('white');
    }

    .eb-modal__body {
      background-color: color('white');
    }
  }

  &__content.theme-turq {
    overflow: hidden;
    background-color: color('turq');

    .eb-modal__header {
      border-bottom: 1px solid color('white');
    }

    .eb-modal__title {
      color: color('white');
    }

    .eb-modal__close-button i {
      color: color('white');
    }

    .eb-modal__body {
      background-color: color('white');
    }
  }

  &__header {
    border-bottom: 1px solid color('greyd');
    position: relative;
  }

  &__title-row {
    display: flex;
    align-items: center;
    padding: 1.25rem 2rem;

    @media #{$tabletOrSmaller} {
      padding: 1rem 2.5rem;
    }
  }

  &__header-tabs {
    display: flex;
    align-items: center;
    padding: 0 2.25rem;
  }

  &__header-tab {
    font-size: 1rem;
    color: color('grey9');
    border-bottom: 4px solid transparent;
    padding: 0 4px 12px;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 3rem;
    }

    &:hover {
      color: rgba(color('purple'), 0.8);
      border-color: rgba(color('purple'), 0.4);
    }

    &--active,
    &--active:hover {
      font-weight: bold;
      color: color('purple');
      border-color: color('purple');
    }
  }

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.5rem;
    color: color('purple');

    @media #{$tabletOrSmaller} {
      font-size: 1.25rem;
    }
  }

  &__close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    i {
      color: color('grey9');
      font-size: 14px;
    }
  }

  &__body {
    padding: 2.5rem;
    background-color: color('greyf5');

    @media #{$tabletOrSmaller} {
      flex-grow: 1;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 1.5rem 2.5rem;
    border-top: 1px solid color('greyd');
    background-color: color('white');

    @media #{$tabletOrSmaller} {
      flex-direction: column-reverse;
      align-items: stretch;
    }

    .eb-button {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }

    @media #{$tabletOrSmaller} {
      > .eb-button {
        margin-right: 0 !important;

        &:not(:first-child) {
          margin-bottom: 1rem;
        }
      }

      > .eb-button-dropdown {
        margin-bottom: 1rem;
      }
    }

    .eb-button-dropdown {
      .eb-button:not(:last-child) {
        margin-right: 0;
      }
    }

    &__button-wrapper--tooltip {
      margin-right: 0.5rem;

      @media #{$tabletOrSmaller} {
        margin-right: 0;
      }
    }
  }

  .eb-checkbox {
    margin: 0;
  }
}

.eb-modal-shade {
  position: fixed;
  top: 0;
  left: 0;
  background-color: color('black');
  opacity: 0.5;
  z-index: 980;
  width: 100vw;
  height: 100vh;
}

.eb-modal__layertop {
  .eb-modal {
    z-index: 981 * 2;
  }

  .eb-modal-shade {
    z-index: 980 * 2;
  }
}
