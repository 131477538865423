@import '../../stylesheets/imports/index';

.eb-code-editor-next {
  width: 100%;
  height: 100%;

  &--content {
    width: 100%;
    height: 100%;
  }

  // === remote markers ===

  &--remote {
    &-cursor {
      position: absolute;
      pointer-events: none;
      z-index: 4000;
      width: 2px;

      &::before {
        content: '';
        width: 6px;
        height: 5px;
        display: block;
        margin-left: -2px;
        margin-top: 0;
        z-index: 4000;
        background: inherit;
      }

      &-tooltip {
        position: absolute;
        white-space: nowrap;
        color: #ffffff;
        text-shadow: 0 0 3px #000000;
        opacity: 1;
        font-size: 12px;
        padding: 2px;
        font-family: sans-serif;
        z-index: 4000;
        transition: opacity 500ms ease-out;
      }
    }

    &-selection {
      position: absolute;
      pointer-events: auto;
      opacity: 0.3;
    }
  }
}
