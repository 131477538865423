@import '../../stylesheets/imports/index';

.eb-emoji-gallery {
  overflow: hidden;

  .emoji-mart {
    .emoji-mart-scroll + .emoji-mart-bar {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      outline: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }
}
