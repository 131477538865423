@import '../../stylesheets/imports/index';

.eb-label {
  display: inline-block;
  color: color('grey2');
  font-size: rem(16px);

  &--size {
    &-large {
      font-size: rem(20px);
    }
    &-medium {
      font-size: rem(18px);
    }
    &-default {
      font-size: rem(16px);
    }
    &-small {
      font-size: rem(14px);
    }
    &-tiny {
      font-size: rem(12px);
    }
  }

  &--block {
    display: block;
    padding-bottom: rem(16px);
  }

  &--bold {
    font-weight: bold;
  }

  &--gray {
    color: color('gray6');
  }

  &--section {
    line-height: rem(20px);
    font-size: rem(20px);
  }

  &__required-icon {
    display: inline-block;
    padding-left: rem(4px);
    line-height: rem(16px);
    color: color('red');
    font-size: rem(16px);
  }

  &--checkable {
    .eb-label__check-icon,
    .eb-label__text {
      cursor: pointer;
    }
  }

  &__check-icon {
    float: left;
    color: color('greyc');

    &--checked {
      color: color('purple');
    }
  }

  &__text {
    .eb-label--checkable & {
      padding-left: 0.5rem;
    }
  }

  &__tooltip-icon {
    display: inline-block;
    margin-left: 0.25rem;
    color: color('grey6');
    font-size: rem(14px);
  }
}
