@import '../../stylesheets/imports/index';

.eb-course-card-wrapper {
  text-decoration: none;
}

.eb-course-card {
  background-color: color('white');
  border: 1px solid color('greyd');
  border-radius: rem(4px);
  cursor: pointer;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
  text-decoration: none;
  transition: all 500ms;
  position: relative;
  overflow: hidden;

  // &:hover {
  //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.36);
  // }

  &__type-default {
    display: flex;
    flex-direction: column;
    width: 18.5rem;
    height: 22.5rem;

    &.eb-course-card__mobile-fluid {
      @media #{$mobile} {
        width: 100%;
        height: auto;
      }
    }

    .eb-course-card {
      // &__badges .eb-badge {
      //   margin-bottom: 0;
      // }

      &__logo--wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }

      &__title {
        height: 4.5rem;
        padding-top: 1rem;
      }

      // &__subtitle {
      //   height: 2.5rem;
      // }

      // &__profile--wrapper {
      //   width: 2.8rem;
      // }
    }
  }

  &__type-paid {
    display: flex;
    flex-direction: column;
    width: 18.5rem;
    height: rem(346px);

    &.eb-course-card__mobile-fluid {
      @media #{$mobile} {
        width: 100%;
        height: auto;
      }
    }

    .eb-course-card__logo--wrapper {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .eb-course-card__body {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      max-height: rem(170px);
    }

    .eb-course-card__detail--title {
      line-height: 1.125;
    }

    .eb-course-card__title {
      height: 4rem;
      padding-top: 1rem;
    }

    .eb-course-card__subtitle {
      min-height: 1.25rem;
    }

    .eb-course-card__profile {
      white-space: nowrap;
    }

    .eb-course-card__tag {
      font-size: rem(12px);
      height: rem(18px);
      line-height: rem(18px);
      color: color('gray5');
      margin-right: 0.5rem;
    }

    .eb-course-card__price {
      height: 1.25rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .eb-course-card__logo {
      height: rem(120px);
      width: rem(120px);
    }

    .eb-course-card__profile--title {
      font-size: 0.75rem;
      color: color('gray5');
      height: 1.125rem;
      line-height: 1.125rem;
      padding-left: 0.5rem;

      &:after {
        content: '';
        width: 1px;
        background-color: color('gray5');
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        height: 10px;
        display: inline-block;
      }
    }
    .eb-course-card__description {
      &:last-child {
        padding-bottom: 0;
      }
    }

    &.eb-course-card__fluid {
      width: 100%;
      height: auto;

      .eb-course-card__body {
        max-height: none;
        min-height: rem(257px);
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 1.5rem;
      }
      .eb-course-card__head--wrapper {
        height: rem(150px);
        flex: 0 0 rem(150px);
      }
      .eb-course-card__title {
        height: auto;
        padding-top: 4.125rem;

        .eb-text {
          font-size: 1.25rem;
        }
      }
      .eb-course-card__logo {
        height: rem(150px);
        width: rem(150px);
      }
      .eb-course-card__logo--wrapper {
        bottom: auto;
        top: rem(46px);
      }
      .eb-course-card__logo-pattern--wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }
      .eb-course-card__logo-pattern {
        width: 208px;
        height: 96px;
        margin-top: 1.5rem;
      }
      .eb-course-card__profile-divider {
        border: 1px solid color('gray5');
        height: 1rem;
        padding-left: 0.5rem;
        margin-right: 0.5rem;
      }

      .eb-course-card__price {
        margin-top: 1.5rem;
      }
    }
  }

  &__type-enrolled {
    width: 18.5rem;
    // height: rem(362px);

    // &.eb-course-card__mobile-fluid {
    //   @media #{$mobile} {
    //     width: 100%;
    //     height: auto;
    //     padding: 1.5rem;
    //   }

    //   .eb-course-card__head {
    //     @media #{$mobile} {
    //       position: absolute;
    //       left: 0;
    //       top: 0;
    //       padding: 1.5rem 0 0 1.5rem;
    //     }
    //   }

    //   .eb-course-card__badges {
    //     @media #{$mobile} {
    //       display: none;
    //     }
    //   }

    //   .eb-course-card__logo {
    //     @media #{$mobile} {
    //       width: 4.5rem;
    //       height: 4.5rem;
    //       min-width: 4.5rem;
    //     }
    //   }

    //   .eb-course-card__body {
    //     @media #{$mobile} {
    //       margin-top: 0;
    //     }
    //   }

    //   .eb-course-card__detail__title {
    //     @media #{$mobile} {
    //       height: 4.5rem;
    //       display: flex;
    //       align-items: center;
    //       justify-content: flex-start;
    //     }
    //   }

    //   .eb-course-card__detail--block {
    //     @media #{$mobile} {
    //       margin-top: 0;
    //       margin-left: 5.5rem;
    //     }
    //   }
    //   .eb-course-card__educator {
    //     @media #{$mobile} {
    //       display: none;
    //     }
    //   }
    // }

    .eb-course-card__head {
      background-color: color('purple-black');
      background-position: center bottom;
      background-repeat: no-repeat;
      position: relative;
      height: rem(176px);
      z-index: 2;
    }

    .eb-course-card__logo--wrapper {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
    }

    .eb-course-card__logo {
      width: 5.5rem;
      height: 5.5rem;
      min-width: 5.5rem;
    }

    .eb-course-card__badges {
      .eb-badge {
        margin-bottom: 0;
      }
    }

    .eb-course-card__body {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .eb-course-card__title--wrapper {
      margin-top: 1rem;
      height: 3rem;
    }

    // .eb-course-card__detail--block {
    //   display: flex;
    //   flex-direction: column;
    //   min-height: 5rem;
    //   margin-top: 1.25rem;
    // }

    .eb-course-card__educator {
      margin-top: 0.5rem;
    }
    .eb-course-card__course-type--wrapper {
      height: 1.25rem;
      padding-top: 1.5rem;
    }
    .eb-course-card__progress {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
  }

  &__type-new-course {
    padding: 1.5rem;
    width: 18.5rem;
    height: 17.375rem;
    border-radius: 4px;

    &--theme-grey {
      background-color: color('greyd');
      color: color('grey9');
    }

    &--theme-primary {
      @include button-color-set('purple');
      background-color: color('purple');
      color: color('white');
    }

    &__title {
      color: inherit;
      font-size: 1.25rem;
    }

    &__icon {
      color: inherit;
      font-size: 4rem;
    }

    &--interim {
      background-color: transparent;
      box-shadow: none;
      border-style: dashed;
      border-color: color('greyc');

      &:hover {
        box-shadow: none;
      }
    }
  }

  &__type-educator {
    width: 18.5rem;
    height: rem(316px);

    // &.eb-course-card__mobile-fluid {
    //   @media #{$mobile} {
    //     display: flex;
    //     width: 100%;
    //     height: auto;
    //     padding: 1.5rem;
    //   }

    //   .eb-course-card__logo {
    //     @media #{$mobile} {
    //       min-width: 4.5rem;
    //       width: 4.5rem;
    //       height: 4.5rem;
    //     }
    //   }

    //   .eb-course-card__badges {
    //     @media #{$mobile} {
    //       display: none;
    //     }
    //   }

    //   .eb-course-card__detail__profile {
    //     @media #{$mobile} {
    //       display: none;
    //     }
    //   }

    //   .eb-course-card__members {
    //     @media #{$mobile} {
    //       display: none;
    //     }
    //   }

    //   .eb-course-card__body {
    //     @media #{$mobile} {
    //       margin-top: 0;
    //       margin-left: 1rem;
    //     }
    //   }

    //   .eb-course-card__detail--block {
    //     @media #{$mobile} {
    //       min-height: 0;
    //     }
    //   }
    // }

    .eb-course-card__head {
      background-color: color('purple-black');
      background-position: center bottom;
      background-repeat: no-repeat;
      position: relative;
      height: rem(176px);
      z-index: 2;
    }

    .eb-course-card__logo--wrapper {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
    }

    .eb-course-card__logo {
      width: 7.5rem;
      height: 7.5rem;
      min-width: 7.5rem;
    }

    .eb-course-card__badges {
      .eb-badge {
        margin-bottom: 0;
      }
    }

    .eb-course-card__body {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .eb-course-card__badges {
      flex-direction: row-reverse;
      justify-content: end;

      &--item {
        margin-left: 0.5rem;

        &:last-child {
          margin-left: 0;
        }
      }
    }

    .eb-course-card__title {
      height: 3rem;
      margin-top: 1rem;
    }

    .eb-course-card__profile {
      line-height: normal;
      height: 1rem;
    }

    .eb-course-card__members {
      margin-top: 1rem;
    }
  }

  &__type-main {
    padding: 2.5rem 2.5rem;

    @media #{$mobile} {
      flex-direction: column;
      align-items: flex-start;
      padding: 1.5rem 2rem;
    }

    .eb-course-card__buttons {
      margin-left: 4rem;
      display: flex;
      align-items: center;

      @media #{$tablet} {
        display: none;
      }

      @media #{$mobile} {
        display: none;
      }
    }

    .eb-course-card__logo--wrapper {
      position: relative;
    }

    .eb-course-card__logo {
      background-color: color('gray2');
      height: 7.5rem;
      width: 7.5rem;
      min-width: 7.5rem;

      @media #{$mobile} {
        height: 6.5rem;
        width: 6.5rem;
        min-width: 6.5rem;
      }
    }

    .eb-course-card__body {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      width: 100%;
      padding-left: 1.5rem;

      @media #{$mobile} {
        padding-left: 0;
        margin-top: 1.5rem;
      }
    }

    .eb-course-card__educators {
      margin-top: 0.5rem;

      @media #{$mobile} {
        display: none;
      }
    }

    .eb-course-card__progress {
      margin-top: 1.5rem;

      @media #{$mobile} {
        margin-top: 1rem;
      }
    }

    .eb-ant-progress {
      @media #{$mobile} {
        display: block;
        margin-top: 0.75rem;
      }
    }

    .eb-course-card__title {
      @media #{$mobile} {
        height: 3rem;
      }
    }
    // .eb-course-card__detail--column {
    //   flex-grow: 1;
    //   flex-shrink: 1;
    //   flex-basis: 0%;
    // }

    .eb-course-card__badges {
      margin-left: 1rem;

      @media #{$mobile} {
        display: none;
      }

      .eb-badge {
        margin-bottom: 0;
      }
    }
  }

  &__type-listed {
    height: 8.5rem;
    padding: 1.5rem 2rem;
    position: relative;

    @media #{$mobile} {
      height: auto;
      padding: 1.5rem;
    }

    .eb-course-card__head {
      @media #{$mobile} {
        position: absolute;
        top: 0;
        left: 0;
        padding: 1.5rem 0 0 1.5rem;
        z-index: 2;
      }
    }

    .eb-course-card__logo {
      width: 5.5rem;
      height: 5.5rem;
      min-width: 5.5rem;

      @media #{$mobile} {
        width: 4.5rem;
        height: 4.5rem;
        min-width: 4.5rem;
      }
    }

    .eb-course-card__body {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      padding-left: 1.5rem;

      @media #{$mobile} {
        padding-left: 0;
      }
    }

    .eb-course-card__detail--inner {
      @media #{$mobile} {
        flex-direction: column;
        align-items: unset;
      }
    }

    .eb-course-card__title {
      @media #{$mobile} {
        margin-top: 1rem;
        order: 2;
      }
    }

    .eb-course-card__badges {
      margin-left: 1rem;

      @media #{$mobile} {
        align-items: baseline;
        height: 4.5rem;
        margin-left: 0;
        order: 1;
      }

      .eb-badge {
        margin-bottom: 0;
      }
    }

    .eb-course-card__detail--column {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
    }

    .eb-course-card__educators {
      margin-top: 0.2rem;
    }

    .eb-course-card__tags {
      margin-top: 1rem;

      @media #{$mobile} {
        display: none;
      }
    }

    .eb-course-card__icontext {
      line-height: 1;
      margin-right: 1.5rem;

      &__icon {
        margin-right: 0.5rem;
      }

      &__title {
        margin-right: 0.5rem;
      }
    }
  }

  &__head {
    position: relative;

    &--background {
      background-repeat: repeat;
    }

    &--wrapper {
      background-color: color('purple-black');
      background-repeat: no-repeat;
      background-position: center bottom;
      display: flex;
      height: 11rem;
      flex: 0 0 11rem;
      position: relative;
    }

    &--content {
      width: 100%;
      height: 100%;
      padding: 1rem 1rem 0 1rem;
    }
  }

  &__footer {
    border-top: 1px solid color('greyd');
    position: relative;
  }

  &__logo {
    line-height: 1;
  }

  &__logo--wrapper {
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &__detail {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;

    &__title {
      white-space: pre-wrap;
      word-break: keep-all;
    }
  }

  &__title {
    text-decoration: none;
    white-space: pre-wrap;
    word-break: keep-all;
  }

  &__subtitle {
    white-space: pre-wrap;
    word-break: keep-all;
  }

  &__profile {
    &--wrapper {
      font-size: 0;
    }
  }

  &__description {
    height: rem(14px);
    margin-bottom: rem(4px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__language {
    & + & {
      margin-left: 0.5rem;
    }

    &--logo {
      margin-left: 0.5rem;
    }
  }

  &__close-button {
    margin-left: auto;
    align-self: flex-start;
  }

  &__badges {
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;

    &--item {
      display: inline-block;

      & + & {
        margin-left: 0.5rem;
      }

      &__icon {
        height: 20px;
        line-height: 1.5em;
        margin-bottom: 0.5rem;
      }
    }
  }

  &__completed__badge {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;

    &__item {
      color: color('white');
      background-color: color('purple');
      position: relative;

      &::before {
        bottom: 0;
        left: 50%;
        border: solid transparent;
        content: '';
        position: absolute;
        pointer-events: none;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 17.5px 11px 17.5px;
        border-color: transparent transparent #fff transparent;
        margin-left: -17.5px;
      }
    }
  }

  &__description {
    .eb-text {
      color: color('gray5');
    }
  }

  &__progress {
    .ant-progress-outer {
      display: block;
      line-height: 1;
      font-size: 0;
    }
  }

  &__actions {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: auto;

    &--item {
      display: inline-block;

      & + & {
        margin-left: 0.5rem;
      }
    }
  }

  &__type-library {
    width: 18.5rem;
    height: rem(346px);

    &.eb-course-card__mobile-fluid {
      @media #{$mobile} {
        // display: flex;
        width: 100%;
        height: auto;
        // padding: 1.5rem;
      }
    }

    .eb-course-card__head {
      background-color: color('purple-black');
      background-position: center bottom;
      background-repeat: no-repeat;
      position: relative;
      height: rem(176px);
      z-index: 2;
    }

    .eb-course-card__logo--wrapper {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
    }

    .eb-course-card__logo {
      width: 5.5rem;
      height: 5.5rem;
      min-width: 5.5rem;
    }

    .eb-course-card__badges {
      padding-top: 1rem;
      padding-left: 1rem;

      .eb-badge {
        margin-bottom: 0;
      }
    }

    .eb-course-card__body {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 1.25rem;
    }

    .eb-course-card__badges {
      flex-direction: row-reverse;
      justify-content: end;

      &--item {
        margin-left: 0.5rem;

        &:last-child {
          margin-left: 0;
        }
      }
    }

    .eb-course-card__title {
      height: 3rem;
      margin-top: 1rem;
    }

    .eb-course-card__profile {
      line-height: normal;
      height: 1rem;
    }

    .eb-course-card__members {
      margin-top: 1rem;
    }
  }

  &__track {
    padding: 0 0.375rem;
    height: 1.5rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    background-color: color('greye');
    border-radius: 0.25rem;
    margin-right: 0.25rem;
    white-space: nowrap;

    &:last-child {
      flex: 1 1 0%;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 0;
    }

    &:first-child {
      flex: none;
    }

    &--more {
      border: 1px solid color('greyd');
      border-radius: 100%;
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      top: 50%;
      text-align: center;
      position: absolute;
      font-size: 0.75rem;
      margin-top: -0.75rem;
      left: 100%;
    }

    &--wrapper {
      // height: 2rem;
      position: relative;
      // margin-top: 1.25rem;
    }
  }

  &__tags {
    font-size: rem(12px);
    color: color('gray5');
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@-moz-document url-prefix() {
  .eb-course-card__type-defaut,
  .eb-course-card__type-paid,
  .eb-course-card__type-enrolled,
  .eb-course-card__type-educator {
    .eb-text {
      display: flex;
      align-content: baseline;
      justify-content: flex-start;
    }
  }
}
