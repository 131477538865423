@import '../../stylesheets/imports/index';

.eb-chat-contents {
  position: relative;
  width: 100%;
  height: 100%;

  &--list {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    overflow-x: hidden;
    overflow-y: auto;

    &--status {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    &--spinner {
      display: flex;
      justify-content: center;
      max-height: 3rem;

      &__fullsize {
        height: 100%;
        max-height: 100%;
      }

      &[aria-hidden='true'] {
        transition: max-height 100ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        max-height: 0;
        overflow: hidden;
      }
    }
  }

  &--items {
    padding: 0 16px;

    &__full {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
    }
  }

  &--scrolltobottom {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    margin-left: -2.75rem;
    padding: 0.75rem;
    border: none;
    border-radius: 1.5rem;
    width: 5.5rem;
    font-size: 0.875rem;
    color: #fff;
    background-color: #7d7e80;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.36);
    cursor: pointer;

    &:active,
    &:focus {
      outline: none;
    }
  }
}
