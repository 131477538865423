@import '../../stylesheets/imports/index';

.eb-video-input {
  &:not(&--preview-only) &__input.eb-textbox {
    margin-bottom: 1rem;
  }

  &__preview {
    position: relative;
    height: 22.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &__delete-button {
      color: color('grey6');
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      cursor: pointer;
    }

    &__deletable {
      background-color: color('white');
      border: 2px solid color('purple');
    }
  }

  &__preview-icon {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    border: 1px solid color('greyd');
    background-color: color('white');
    width: 100%;
    height: 100%;
    color: color('greyc');
    font-size: 4rem;

    &--loaded {
      display: none;
    }
  }

  &__preview-video {
    max-width: 100%;
  }
}
