@import '../../stylesheets/imports/index';

.eb-index-point-card {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.eb-index-point-card-item {
  width: 21rem;
  padding: 1.5rem 2rem;
  border-right: 1px solid color('greyd');

  &:last-child {
    border-right: 0;
  }

  &__description {
    min-height: rem(72px);
    max-width: 100%;
    white-space: pre-line;
    text-align: center;
    word-break: keep-all;
  }
}

@media (max-width: $media-lg-width) {
  .eb-index-point-card-item {
    border-right: 0;
  }
}
