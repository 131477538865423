@import '../../stylesheets/imports/index';

.eb-pdf-input {
  &__preview {
    display: none;
    position: relative;
    height: 20rem;
    width: 100%;
    margin-top: 1rem;

    &--loading {
      display: block;
      border: 2px dashed color('greyc');
    }

    &--loaded {
      display: block;
    }
  }

  &__preview-data {
    width: 100%;
    height: 100%;
  }
}
