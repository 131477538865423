@import '../../stylesheets/imports/index';

.eb-calendar-heatmap {
  height: 100%;
  text {
    font-size: 14px;
    fill: color('grey6');
  }

  rect:hover {
    stroke: #555;
    stroke-width: 1px;
  }

  &__rect {
    &--empty {
      fill: color('greyf5');
    }

    &--scale-0 {
      fill: #e5e5f1;
    }

    &--scale-1 {
      fill: #d4d3e7;
    }

    &--scale-2 {
      fill: #a8a7d0;
    }

    &--scale-3 {
      fill: #7d7bb9;
    }

    &--scale-4 {
      fill: color('purple');
    }
  }

  &__weekday-labels {
    text-anchor: middle;
  }
}
