// Font weights
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;

// Font stacks
@import url(https://fonts.googleapis.com/css?family=Inconsolata);
$monospace-font-stack: 'Inconsolata', 'Lucida Console', 'Courier New', monospace;

// Max widths
$standard-max-width: rem(1080px);

// Media width thresholds
$media-lg-width: 1280px;
$media-md-width: 768px;
$media-sm-width: 576px;
$media-xs-width: 375px;

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// https://github.com/twbs/bootstrap/blob/v4-dev/scss/mixins/_breakpoints.scss

$desktop: 'only screen and (min-width: #{$media-lg-width})';
$laptop: 'only screen and (min-width: #{$media-md-width}) and (max-width: #{$media-lg-width - 0.02px})';
$tablet: 'only screen and (min-width: #{$media-sm-width}) and (max-width: #{$media-md-width - 0.02px})';
$mobile: 'only screen and (max-width: #{$media-sm-width - 0.02px})';

$container-xl-width: 1232px;
$container-lg-width: 920px;
$container-md-width: 608px;
$container-sm-width: 100%;

$laptopOrLarger: 'only screen and (min-width: #{$media-md-width})';
$tabletOrLarger: 'only screen and (min-width: #{$media-sm-width})';
$laptopOrSmaller: 'only screen and (max-width: #{$media-lg-width - 0.02px})';
$tabletOrSmaller: 'only screen and (max-width: #{$media-md-width - 0.02px})';

$border-radius-sm: 6px;
$border-width-base: 1px;
$border-style-base: solid;
$border-color-base: color('greyd');

// Z-indexes
$header-z-index: 900;
$common-z-index-top: 120;
$common-z-index-middle: 110;
$common-z-index-bottom: 100;

// Column grid
$col-1: 5.5rem; // 88px
$col-2: 12rem; // 192px
$col-3: 18.5rem; // 296px
$col-4: 25rem; // 400px
$col-5: 31.5rem; // 504px
$col-6: 38rem; // 608px
$col-7: 44.5rem; // 712px
$col-8: 51rem; // 816px
$col-9: 57.5rem; // 920px
$col-10: 64rem; // 1024px
$col-11: 70.5rem; // 1128px
$col-12: 77rem; // 1232px

// container
$padding-between-container-content: 1rem;
