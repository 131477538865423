@import '../../stylesheets/imports/index';

.eb-icon-button {
  display: inline-block;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    color 0.3s ease;
  cursor: pointer;
  border: 1px solid;
  text-align: center;
  border-radius: 0;

  &:focus {
    outline: 0;
  }

  &--size-default {
    border-radius: 2px;
    height: rem(20px);
    width: rem(20px);
    font-size: rem(12px);
    line-height: rem(12px);
  }

  &--size-large {
    border-radius: 2px;
    height: rem(24px);
    width: rem(24px);
    font-size: rem(14px);
    line-height: rem(14px);
  }

  &--size-huge {
    border-radius: 2px;
    height: rem(26px);
    width: rem(26px);
    font-size: rem(16px);
    line-height: rem(16px);
  }

  &--size-elephant {
    border-radius: 2px;
    height: rem(28px);
    width: rem(28px);
    font-size: rem(18px);
    line-height: rem(18px);
  }

  &--role-primary {
    @include button-color-set('purple');

    .t-dark &,
    &.t-dark {
      @include button-color-set('purple-light');
    }
  }

  &--role-secondary {
    @include button-color-set('turq');
  }

  &--role-lightpurple {
    @include button-color-set('purple-light');
  }

  &--role-darkblue {
    @include button-color-set('darkblue');
  }

  &--role-grey {
    @include button-color-set('grey9', 'grey2');
  }

  &--role-white {
    @include button-color-set('white');
    color: color('grey2');
  }

  &--role-grey6 {
    @include button-color-set('grey6');
  }

  &--role-success {
    @include button-color-set('lime');
  }

  &--role-warning {
    @include button-color-set('yellow');
  }

  &--role-danger {
    @include button-color-set('red');
  }

  &--role-greyfb {
    @include button-color-set('greyfb', 'black', 'black');
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--loading {
    pointer-events: none;
  }

  &--no-border {
    border: 0;

    &:focus {
      outline: 0;
    }
  }
}

.eb-icon-button__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
