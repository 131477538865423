@import '../../stylesheets/imports/index';

.eb-listview {
  &__body {
    background-color: color(greyf9);
    width: 65%;
    border: solid 1px color(greyd);
    border-top: 0px;
  }
  &__item {
    display: block;
    border-top: solid 1px color(greyd);
    padding: 14px 24px;
    &:nth-child(even) {
      background-color: white;
    }
  }
}
