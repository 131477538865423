@import '../../stylesheets/imports/index';

.eb-grid-row {
  display: flex;
  flex-wrap: wrap;

  &--has-padding {
    margin: 0 -0.75rem;
  }
}

@media (max-width: $media-lg-width) {
  .eb-grid-row {
    &--min-size-large {
      display: block;
      margin-right: 0;
      margin-left: 0;
    }
  }
}

@media (max-width: $media-sm-width) {
  .eb-grid-row {
    &--min-size-small {
      display: block;
      margin-right: 0;
      margin-left: 0;
    }
  }
}
