@import '../../stylesheets/imports/index';

.eb-file-tab-bar {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  &__tab-scroller {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: transparent;
    .t-light & {
      background-color: transparent;
    }
  }

  &__tabs {
    display: flex;
    height: 100%;
    white-space: nowrap;
  }
}

.eb-file-tab {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  min-width: 50px;
  max-width: 150px;
  display: inline-block;
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  font-weight: $regular;
  line-height: 38px;
  color: color('grey6');

  .t-light & {
    background-color: color('greyd');
    color: color('grey6');
  }

  &--is-active {
    background-color: color('navy1');
    color: color('greyfb');

    &:before {
      opacity: 1;
      transition-duration: 0.32s;
    }

    .t-light & {
      background-color: color('white');
      color: color('greyd');
    }
  }

  &--is-dragging {
    &:before,
    .eb-file-tab__close-button {
      visibility: hidden;
    }
  }

  &__icon {
    display: inline-block;
    margin-right: 3px;
  }

  &__title {
    display: inline-block;
    margin-right: 12px;
    transition: background-color 0.25s ease, color 0.25s ease;
    width: 100%;
    text-align: center;
    overflow: hidden;

    &:hover {
      color: color('greyd');

      .t-light & {
        color: color('grey2');
      }
    }
  }

  &--is-active &__title {
    color: color('greyd');
    cursor: default;

    .t-light & {
      color: color('grey2');
    }
  }

  &__close-button {
    position: absolute;
    opacity: 0;
    transition: all 0.25s;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 8px;
    vertical-align: middle;
    color: color('grey9');
    cursor: pointer;
    background-color: transparent;
    padding-left: 3px;
    padding-right: 5px;

    .t-light & {
      background-color: color('greyd');
    }

    &:hover {
      color: color('greyd');

      .t-light & {
        background-color: color('greyd');
        color: color('grey2');
      }
    }
  }

  &:hover &__close-button {
    opacity: 1;
  }

  &--is-active &__close-button {
    color: color('greyc');

    &:hover {
      color: color('white');
    }

    .t-light & {
      background-color: color('white');
      &:hover {
        color: color('grey2');
      }
    }
  }
}
