@import '../../stylesheets/imports/index';

.eb-index-organization-card {
  border: 1px solid color('greyd');
  border-radius: 4px;
  background-color: color('white');
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  display: flex;
  align-items: center;
  padding: 1rem;

  &:hover {
    background-color: color('purple-white');
    transition: 0.3s all ease-in-out;
  }

  &--academy {
    border-color: color('purple');
    background-color: color('purple');
    transition: 0.3s all ease-in-out;

    &:hover {
      background-color: color('purple-dark');
      transition: 0.3s all ease-in-out;
    }

    .eb-index-organization-card__title {
      color: color('white');
    }

    .eb-index-organization-card__arrow {
      color: color('white');
    }

    .eb-index-organization-card__url {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
