@import '../../stylesheets/imports/index';

.eb-searchbox {
  $p: &;
  width: 300px;
  position: relative;

  &--loading {
    background-color: color('white');
  }

  &--block {
    width: 100%;
  }

  &__form-inner {
    display: flex;

    > .eb-dropdown {
      height: 100%;
    }
  }

  &__dropdown {
    border-right: none;
    min-width: inherit;
    font-size: 13px;
    outline: none;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__textbox-wrapper {
    position: relative;
    flex: 2;
  }

  &__textbox .eb-textbox__input {
    outline: none;
    padding-right: 2.5rem;
    text-overflow: ellipsis;

    &:placeholder-shown {
      text-overflow: ellipsis;
    }
  }

  &__action-icon {
    position: absolute;
    color: color('greyc');
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    font-size: 12px;
  }

  &__search-results {
    position: absolute;
    top: 38px;
    z-index: 101;
    margin: 0 10px;
    border: 1px solid color('grey9');
    background-color: color('white');
    padding: 0;
    width: calc(100% - 20px);
    list-style: none;
    font-size: 14px;
  }

  &__search-result-item {
    cursor: pointer;
    padding: 3px 10px;

    &--active {
      background-color: color('greyd');
    }
  }

  &--size-tiny {
    .eb-searchbox__action-icon {
      font-size: 12px;
      &.icon-cross {
        font-size: 10px;
      }
    }

    .eb-searchbox__search-results {
      top: 42px;
      font-size: 1rem;
    }
  }

  &--size-small {
    .eb-searchbox__action-icon {
      font-size: 16px;
      &.icon-cross {
        font-size: 12px;
      }
    }

    .eb-searchbox__search-results {
      top: 42px;
      font-size: 1rem;
    }
  }

  &--size-default {
    .eb-searchbox__action-icon {
      font-size: 20px;
      &.icon-cross {
        font-size: 14px;
      }
    }

    .eb-searchbox__search-results {
      top: 42px;
      font-size: 1rem;
    }
  }

  &--width-small {
    width: auto;
    .eb-textbox {
      @media #{$laptopOrLarger} {
        width: 240px;
      }
    }
  }

  &--width-large {
    width: auto;
    .eb-textbox {
      @media #{$laptopOrLarger} {
        width: 400px;
      }
    }
  }
}

.eb-searchbox--active {
  & .icon-cross:hover {
    color: color('grey6');
    cursor: pointer;
  }
}
