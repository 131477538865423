@import '../../stylesheets/imports/index';

.eb-chat {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .eb-chat--inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .eb-chat--chats {
      position: relative;
      flex: 1 1 100%;
      overflow: hidden;

      .eb-chat--chats--errmsg {
        position: absolute;
        bottom: 15px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        &-enter {
          opacity: 0;

          &-active {
            opacity: 1;
            transition: opacity 300ms ease-in;
          }
        }

        &-exit {
          opacity: 1;
          transition: opacity 300ms ease-out;

          &-active {
            opacity: 0;
          }
        }

        &--inner {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          min-width: 100px;
          max-width: 330px;
          min-height: 48px;
          border-radius: 4px;
          background: #fff;
          box-shadow: 0 8px 40px 10px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  .eb-chat-drop {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    user-select: none;
    pointer-events: none;
    z-index: 1;

    &-enter {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
      }
    }

    &-exit {
      opacity: 1;
      transition: opacity 300ms ease-out;

      &-active {
        opacity: 0;
      }
    }

    &--circle {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.6);

      .eb-text {
        text-align: center;
        white-space: pre-line;
      }
    }
  }
}
