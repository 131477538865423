.react-contextmenu {
  min-width: 122px;
  background-color: color('greye');
  border: 1px solid color('greye');
  outline: none;
  opacity: 0;
  pointer-events: none;
  z-index: 500;
  box-shadow: 0px 8px 16px 0px rgba(color('grey2'), 0.2);

  .t-dark & {
    border: 1px solid #222222;
    background-color: #31313a;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  }
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
}

.react-contextmenu-item {
  padding: 0 12px;
  font-weight: 400;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: color('white');
  transition: background-color 0.5s ease;
  white-space: nowrap;
  cursor: pointer;

  .t-dark & {
    color: color('white');
  }

  &:hover {
    background-color: color('greyc');

    .t-dark & {
      background-color: #494951;
    }
  }
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  color: color('grey9');
  cursor: not-allowed;
  background-color: transparent;

  .t-dark & {
    color: color('grey9');
  }
}

.react-contextmenu-item--divider {
  margin-bottom: 3px;
  padding: 2px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: '▶';
  display: inline-block;
  position: absolute;
  right: 7px;
}
