@import '../../stylesheets/imports/index';

.eb-container {
  position: relative;
  background-color: color('white');
  border: 1px solid color('greyd');

  &--is-type-standard {
    width: 100%;
    max-width: $standard-max-width;

    @media #{$laptopOrSmaller} {
      max-width: 100%;
    }
  }

  &--is-type-narrow {
    width: 100%;
    max-width: rem(585px);

    @media #{$laptopOrSmaller} {
      max-width: 100%;
    }
  }

  &--is-type-middle {
    width: 100%;
    max-width: rem(880px);

    @media #{$laptopOrSmaller} {
      max-width: 100%;
    }
  }

  &--is-type-wide {
    width: 100%;
    max-width: rem(1040px);

    @media #{$laptopOrSmaller} {
      max-width: 100%;
    }
  }

  &--is-type-full {
    width: 100%;
  }

  &--role-transparent {
    background-color: transparent;
  }

  &--role-primary {
    background-color: color('purple');
  }

  &--role-secondary {
    background-color: color('turq');
  }

  &--role-grey {
    background-color: color('grey9');
  }

  &--role-lightgrey {
    background-color: color('greyd');
  }

  &--role-lightestgrey {
    background-color: color('greyfb');
  }

  &--role-white {
    background-color: color('white');
  }

  &--role-success {
    background-color: color('lime');
  }

  &--role-warning {
    background-color: color('yellow');
  }

  &--role-danger {
    background-color: color('red');
  }

  &--role-purplewhite {
    background-color: color('purple-white');
  }

  &--role-purplelight {
    background-color: color('purple-light');
  }

  &--no-border {
    border: 0;
  }

  &__header {
    border-bottom: 1px solid color('greyd');
    padding: rem(20px) rem(24px);

    .eb-container--is-type-narrow &,
    .eb-container--is-type-middle & {
      border: 0;
      padding: rem(24px) rem(32px);
      text-align: center;
    }

    & > div:first-of-type {
      margin: 0 !important;
    }
  }

  &__header--custom-height {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
  }

  &__title {
    font-size: 1rem;
    font-weight: bold;
    color: color('grey2');
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }

  &__toolbar {
    height: rem(32px);
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
  }

  &__tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2rem;
  }

  &__tab {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    border: 1px solid color('greyc');
    padding: 1rem;
    line-height: 1;
    color: color('grey9');
    cursor: pointer;

    &:not(:first-of-type) {
      border-left-width: 0;
    }

    &--active {
      background-color: color('greye');
      color: color('grey2');
    }
  }

  &__subtitle {
    padding: rem(16px) 0 0 0;
    font-size: rem(14px);
    line-height: rem(24px);
    color: color('grey2');
    white-space: pre-wrap;

    .eb-container--is-type-narrow &,
    .eb-container--is-type-middle & {
      padding: rem(24px) 0 0 0;
    }
  }

  &__switchButton {
    margin-top: 1rem;
  }

  &__body {
    position: relative;
    padding: 1.5rem 2rem;

    &--disabled {
      position: absolute;
      background-color: color('white');
      z-index: 99;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  &__footer {
    padding: 1.5rem 2rem;
  }

  &--padding-narrow {
    .eb-container__body {
      padding: 0.75rem 1.5rem;
    }
  }

  &--padding-none {
    .eb-container__body {
      padding: 0;
    }
  }

  &--margin-none {
    margin: 0 auto;
  }
}
