@import '../../stylesheets/imports/index';

.eb-filetree-wrapper {
  height: 100%;
  border: 1px dashed transparent;
  overflow: auto;
  display: flex;
  flex-direction: column;

  &--dropping {
    opacity: 0.5;
    border-color: color('grey2');

    .t-dark & {
      border-color: color('greye');
    }
  }

  .react-contextmenu-wrapper {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }

  .eb-tree {
    overflow: initial;
  }
}

.eb-filetree {
  color: color('grey2');

  &--is-empty {
    display: none;
  }

  .t-dark & {
    color: color('greye');
  }

  &__show-tip-button {
    color: color('grey9');
    opacity: 0.7;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 9px;
    z-index: 1;
    &:hover {
      opacity: 1;
    }
  }
}

.eb-filetree-row {
  display: block;
  position: relative;
  word-break: break-all;
  transition: background-color 0.2s ease, color 0.2s ease;
  cursor: pointer;
  padding: 0 1.125rem;
  color: color('greyc');

  &::after {
    content: ' ';
    display: block;
    height: 0;
    clear: both;
  }

  [class^='icon-'],
  [class^='fa '] {
    color: color('grey9');
  }

  &--is-contextmenu-opened {
    color: color('grey2');
    background-color: rgba(color('grey2'), 0.2);

    .t-dark & {
      color: color('white');
      background-color: rgba(color('white'), 0.2);
    }
  }

  &--is-contextmenu-not-opened {
    pointer-events: none;
  }

  &--is-target {
    background-color: rgba(color('grey2'), 0.1);
    color: color('turq');

    .t-dark & {
      background-color: rgba(color('white'), 0.1);
      color: color('turq');
    }
  }

  &__empty-message {
    position: relative;
    padding-bottom: 8px;
    word-break: break-all;

    &:hover {
      color: inherit;
    }
  }

  &__main-contents {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__left {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    display: flex;
    align-content: flex-start;
    min-width: 0;
  }

  &__right {
    flex: 0 0 auto;
    display: flex;
    align-content: flex-end;
    align-items: center;
    min-width: 0;
    margin-top: 3px;
  }

  &__checkbox {
    display: none;

    &--active {
      display: unset;
    }
  }

  &__icon {
    display: block;
    font-size: 14px;
  }

  &__title {
    padding-left: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title-input {
    border: 1px solid color('purple-light');
    width: 120px;
    height: 28px;
    outline: none;
    font-size: 12px;
    color: color('greyc');
    background-color: transparent;
    padding-left: 0.25rem;
  }

  &__icon-button {
    display: none;
    float: left;
    margin-left: 4px;
    cursor: pointer;

    &:hover {
      color: color('turq');
    }

    &--is-visible {
      display: block;
    }
  }
}

.eb-tree__body:hover {
  .eb-filetree-row__checkbox {
    display: unset;
  }
}

.eb-filetree-action {
  flex: 0 0 3rem;
  height: rem(48px);
  border-bottom: 1px solid color('navy2');
  padding: 0 10px;
  font-size: 1rem;
  color: color('greyc');
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  i {
    padding: 0 6px;
  }

  &__button {
    display: inline-block;
    cursor: pointer;

    &:hover {
      color: color('greyc');
    }
  }
}
