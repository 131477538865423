@import '../../stylesheets/imports/index';

.eb-index-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4.5rem;
  background-repeat: no-repeat;
  background-position: center center;
  word-break: keep-all;

  &--role-white {
    .eb-index-container__title {
      color: color('white');
    }

    .eb-index-container__subtitle {
      color: color('white');
      opacity: 0.7;
    }

    .eb-index-container__footer {
      color: color('white');
    }
  }

  &__title {
    text-align: center;
    font-size: rem(32px);
    line-height: 1.4;
  }

  &__footer {
    font-size: rem(16px);
  }

  &__subtitle-container {
    max-width: rem(816px);
    font-size: rem(20px);
    line-height: rem(28px);
    color: color('grey6');
    text-align: center;
  }
}

@media (max-width: $media-sm-width) {
  .eb-index-container {
    padding: rem(56px) rem(28px);

    &__title {
      font-size: rem(24px);
      font-weight: bold;
    }

    &__subtitle {
      color: color('grey6');
      font-size: rem(16px);
      line-height: rem(24px);
    }
  }
}
