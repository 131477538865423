@import '../../stylesheets/imports/index';

.eb-index-action-input {
  display: flex;

  &__textbox-wrapper {
    display: flex;
    flex: 1 0 0;
    align-items: center;
    background-color: color('white');
    border-radius: 8px 0 0 8px;
    border: 1px solid color('greye');
    border-right: 0;
  }

  &__icon {
    color: color('purple');
    font-size: 1rem;
    line-height: 1rem;
    padding: 0 0 0 1rem;
  }

  &__textbox {
    flex: 1 1 0;
    margin: 0;
    display: inline-block;
    padding: 1rem 1rem 1rem 0;
    color: color('purple');
    font-size: 1rem;
    line-height: 1rem;
    border: 0;
    -webkit-appearance: none;

    &:focus {
      border: 0;
      outline: none;
    }

    @media #{$mobile} {
      width: 100%;
    }
  }

  &__submit {
    flex: 0 0 auto;
    cursor: pointer;
    margin: 0;
    display: inline-block;
    padding: 1rem 2rem;
    font-size: 1rem;
    line-height: 1rem;
    border-radius: 0 8px 8px 0;
    outline: none;
    -webkit-appearance: none;
  }

  &--role-default {
    .eb-index-action-input__textbox,
    .eb-index-action-input__icon {
      color: color('purple');
    }

    .eb-index-action-input__submit {
      background-color: color('purple');
      border: 1px solid color('purple');
      color: color('white');
      transition: all 0.3s ease;

      &:hover {
        background-color: color('purple-dark');
        transition: all 0.3s ease;
      }
    }
  }

  &--role-info {
    .eb-index-action-input__textbox,
    .eb-index-action-input__icon {
      color: color('turq-dark');
    }

    .eb-index-action-input__submit {
      background-color: color('turq');
      border: 1px solid color('turq');
      color: color('white');
      transition: all 0.3s ease;

      &:hover {
        background-color: color('turq-dark');
        transition: all 0.3s ease;
      }
    }
  }

  &--role-course {
    .eb-index-action-input__textbox,
    .eb-index-action-input__icon {
      color: color('purple');
    }

    .eb-index-action-input__submit {
      background-color: color('purple-light');
      border: 1px solid color('purple-light');
      color: color('white');
      transition: all 0.3s ease;

      &:hover {
        background-color: hover-color('purple-light');
        transition: all 0.3s ease;
      }
    }
  }

  &--size-small {
    .eb-index-action-input__textbox-wrapper {
      border-radius: 4px 0 0 4px;
    }

    .eb-index-action-input__textbox {
      padding: 0 1rem 0 0;
      font-size: 0.875rem;
      line-height: 1em;
    }

    .eb-index-action-input__submit {
      padding: 0.75rem 1.25rem;
      border-radius: 0 4px 4px 0;
      font-size: 0.875rem;
    }
  }
}

@media (max-width: $media-sm-width) {
  .eb-index-action-input {
    width: 100%;
    flex-shrink: 1;
  }
}
