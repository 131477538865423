@import '../../stylesheets/imports/index';

.eb-file {
  display: inline-block;

  &--block {
    display: block;
    width: 100%;
  }

  &__file-row {
    padding-bottom: 0.5rem;
    max-width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.75rem;
  }

  &__description-row {
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }

  &__file-input {
    display: none;
  }

  &__file-delete {
    color: color('grey6');
    font-size: 0.5rem;
    margin-left: 0.25rem;
  }

  &__choose-button {
    margin-right: 0.5rem;

    &.eb-button--size-small {
      height: 2rem;
      line-height: 2rem;
    }
  }

  &__file-link {
    text-decoration: underline;
    color: color('purple');

    &:link,
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }

  &__file-icon {
    margin-right: 0.25rem;
  }
}
