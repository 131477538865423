@import '../../stylesheets/imports/index';

.eb-safe-image {
  display: inline-block;

  &__img {
    width: 100%;
    height: 100%;
  }

  &--block {
    display: block;
    width: 100%;
    height: 100%;
  }
}
