@import '../../stylesheets/imports/index';

.eb-striped-container {
  position: relative;

  &__body {
    margin: 0 auto;
    padding: rem(16px) rem(32px) rem(32px) rem(32px);
    max-width: $standard-max-width;

    &--full-width {
      max-width: none;
    }
  }

  &:nth-of-type(odd) {
    background-color: color('white');
  }

  &:nth-of-type(even) {
    background-color: color('greyfb');
  }
}
