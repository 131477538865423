@import '../../stylesheets/imports/index';

.ant-timeline {
  &-item {
    padding-bottom: 1.5rem;
    &-last {
      padding-bottom: 0;
    }
    &-tail {
      left: 6px;
      border-left: 2px solid color('greyc');
    }
    &-head {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      &-purple {
        border: 2px solid color('purple');
      }
    }
    &-content {
      padding: 0 0 0 1.5rem;
      top: -7px;
    }
  }
}
