@import '../../stylesheets/imports/index';

.eb-borderless-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 981;
  transition: all 0.3s ease;
}

.eb-borderless-dialog-shade {
  position: fixed;
  top: 0;
  left: 0;
  background-color: color('black');
  opacity: 0.5;
  z-index: 980;
  width: 100vw;
  height: 100vh;

  &.clickable {
    cursor: pointer;
  }
}
