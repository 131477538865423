@import '../../stylesheets/imports/index';

.eb-side-nav {
  width: 100%;
  max-width: 100%;

  &--mobile {
    position: relative;

    &__dropdown {
      color: color('grey9');
      display: flex;
      height: 20px;
      width: 20px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 12px;

      &--selected {
        background-color: color('purple-white');
      }
    }
  }
}

.eb-side-nav-group {
  width: 100%;
  max-width: 100%;
  position: relative;
  margin-bottom: 1rem;

  &__header {
    display: block;
    padding-left: 1rem;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    height: 2.25rem;
    line-height: 2.25rem;
    align-items: center;

    &__text {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__reorder-button {
      flex: 0 0 auto;
      padding: 0.25rem 0.5rem;
      color: color('grey9');
      cursor: pointer;

      &--selected {
        background-color: rgba(color('greyd'), 0.5);
        border-radius: 0.25rem;
      }
    }
  }

  &__items {
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  &--ellipsis {
    .eb-side-nav-item__child {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &--divider:not(:last-child) {
    border-bottom: 1px solid color('greyd');
    margin-bottom: 0;
  }
}

.eb-side-nav-item {
  list-style-type: none;
  color: color('grey2');
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  border-radius: 2px;
  position: relative;

  &:hover {
    background-color: color('greyd');
  }

  &__required-icon {
    display: inline-block;
    padding-left: rem(4px);
    line-height: rem(16px);
    color: color('red');
    font-size: rem(16px);
  }

  &__prev-line {
    position: absolute;
    top: 0;
    left: 23px;
    width: 4px;
    height: 8px;
    background-color: color('greyd');
    z-index: 0;
  }

  &__next-line {
    position: absolute;
    bottom: 0;
    left: 23px;
    width: 4px;
    height: 8px;
    background-color: color('greyd');
    z-index: 0;
  }

  &__selector {
    flex: 0 0 auto;
    padding-right: 0.5rem;
    color: color('grey9');
  }

  &__icon {
    padding-right: 0.5rem;
    font-size: rem(18px);
    display: flex;
    align-items: center;
    z-index: 1;
  }

  &__child {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    min-width: 0;
    height: 2.25rem;
    line-height: 2.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &--no-flex {
      flex: none;
    }
  }

  &--action {
    color: color('purple');
  }

  &--selected {
    background-color: rgba(color('greyd'), 0.5);
  }

  &--selected .eb-side-nav-item__child {
    font-weight: bold;
    color: color('purple');
  }

  &--is-draggable {
    cursor: move;
  }

  &--dragging,
  &--drag-over {
    border: 1px solid color('purple');
    margin: -1px 0 0 0;
    color: color('purple');
    font-weight: bold;
    border-radius: 3px;
  }

  &--dragging {
    background-color: color('white');
  }

  &--drag-over {
    background-color: color('purple-white');
  }

  &__type__darkblue {
    .eb-side-nav-item__icon {
      color: color('darkblue');
    }

    &.eb-side-nav-item--selected .eb-side-nav-item__child {
      font-weight: bold;
      color: color('darkblue');
    }

    &.eb-side-nav-item--dragging,
    &.eb-side-nav-item--drag-over {
      border: 1px solid color('darkblue');
      margin: -1px 0 0 0;
      color: color('darkblue');
      font-weight: bold;
      border-radius: 3px;
    }

    &.eb-side-nav-item--drag-over {
      background-color: color('darkblue-white');
    }
  }
}

.eb-side-nav-group-mobile {
  border-bottom: 1px solid color('greyd');

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 rem(20px);
    height: rem(36px);
    font-size: 16px;
    font-weight: bold;
  }

  &__items {
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}

.eb-side-nav--mobile__dropdown__button {
  cursor: pointer;
}

.eb-side-nav-mobile-menu {
  border: 1px solid color('greyd');
  background-color: color('white');
  position: absolute;
  top: calc(100% + 0.5rem);
  width: 100%;
  max-width: rem(192px);
  max-height: rem(336px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  z-index: 128;
  overflow-y: auto;

  &--hidden {
    display: none;
  }

  &__item-list {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    overflow-y: auto;
  }

  &__action {
    flex: 0 0 auto;
  }

  .eb-side-nav-item {
    list-style-type: none;
    display: flex;
    height: rem(36px);
    width: 100%;
    align-items: center;
    font-size: rem(16px);
    font-weight: normal;
    text-decoration: none;
    padding: 0 rem(20px);

    &:hover {
      background-color: color('purple-white');
    }

    &--selected {
      background-color: color('purple-white');
      font-weight: normal;
      color: color('grey2');
    }

    .eb-side-nav-item__child {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.eb-side-nav-mobile--dropdown {
  .eb-dropdown {
    height: 3.25rem;
    z-index: 100;
  }

  .eb-dropdown__items {
    max-height: rem(248px);
    overflow: auto;
  }

  .eb-dropdown__item {
    height: 3.25rem;
    line-height: 3.25rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  .eb-dropdown__inner {
    height: 3.25rem;
  }

  .eb-dropdown__inner--fill {
    background-color: color('gray0');
    box-shadow: inset 0 -1px 0 0 color('greyd');

    .eb-text {
      color: color('gray9');
      font-weight: bold;
    }
  }
}
