@import '../../stylesheets/imports/index';

.eb-hr {
  hr {
    padding: 0;
    border: 0;
    border-top-width: 1px;
    border-top-style: solid;
  }

  &__text {
    color: color('grey9');
  }
}
