@import '../../stylesheets/imports/index';

@mixin grip($direction) {
  #{$direction}: 0;
  border-#{$direction}-width: 1px;
}

@mixin grips {
  @each $direction in (top, bottom, left, right) {
    &--#{$direction} {
      @include grip($direction);
    }
  }
}

@mixin indicator($direction, $size) {
  #{$direction}: calc(-#{$size} / 2 + 1px);

  .icon {
    margin-#{$direction}: -14px;
  }

  $translate-direction: map-get(
    (
      'top': 'Y(-100%)',
      'bottom': 'Y(100%)',
      'left': 'X(-100%)',
      'right': 'X(100%)',
    ),
    $direction
  );

  .eb-resizable:hover &,
  &.eb-resizable__grip-indicator--grabbed,
  &.eb-resizable__grip-indicator--hovered {
    .eb-resizable__grip-indicator__inner {
      transform: translate#{$translate-direction};
    }

    .icon {
      opacity: 1;
    }
  }

  .eb-resizable__grip-indicator__inner {
    #{$direction}: $size;
  }
}

@mixin indicators($size) {
  @each $direction in (top, bottom, left, right) {
    &--#{$direction} {
      @include indicator($direction, $size);
    }
  }
}

.eb-resizable {
  position: relative;
  padding: 0;
  overflow: visible;
  flex: 0 0 auto;

  &--collapsed {
    .eb-resizable__grip-indicator {
      display: none !important;
    }
  }

  &--disabled &__grip {
    display: none;
  }

  &--horizontal {
    height: 100%;
  }

  &--vertical {
    width: 100%;
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .eb-resizable--collapsed & {
      display: none !important;
    }
  }

  &__grip {
    @include grips;
    position: absolute;
    z-index: 400;

    &--horizontal {
      top: 0;
      cursor: ew-resize;
      width: 5px;
      height: 100%;
      border-right: 1px solid color('grey2');
    }

    &--vertical {
      left: 0;
      cursor: ns-resize;
      width: 100%;
      height: 5px;
      border-top: 1px solid color('grey2');
    }

    .eb-resizable--collapsed & {
      border-color: transparent;
    }
  }

  &__grip-mobile {
    display: none;
  }

  &__grip-indicator {
    $size: 40px;
    @include indicators($size);

    display: flex;
    position: absolute;
    z-index: 401;
    overflow: hidden;
    text-align: center;
    line-height: $size;
    color: color('white');
    font-size: 16px;

    &__inner {
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      transition: transform 0.2s ease-out 0s;
      border: 1px solid color('grey2');
      border-radius: 50%;
      cursor: pointer;
      width: $size;
      height: $size;
      text-align: center;

      .t-light & {
        border: 1px solid color('greyc');
      }

      .icon {
        transition: opacity 0.2s ease-out 0s;
        opacity: 0;
        color: color('grey2');
        font-size: 12px;
      }
    }

    &--vertical {
      left: calc(50% - (#{$size} / 2));
      width: $size;
      height: $size / 2;
    }

    &--horizontal {
      top: calc(50% - (#{$size} / 2));
      width: $size / 2;
      height: $size;
    }
  }

  &__collapsed {
    background-color: color('navy1');
    cursor: pointer;
    color: color('white');
    height: 100%;
    padding-top: 1rem;
    justify-content: center;
    display: flex;
    border-right: 1px solid color('grey2');
    border-left: 1px solid color('grey2');

    .t-light & {
      background-color: color('greyd');
      color: color('grey9');

      &:hover {
        background-color: color('greyc');
      }
    }
  }
}

@media #{$tabletOrSmaller} {
  .eb-resizable--horizontal {
    &.eb-resizable--on-mobile-hider {
      .eb-resizable__content {
        padding-right: 2rem;
      }

      .eb-resizable__grip,
      .eb-resizable__grip-indicator {
        display: none;
      }

      .eb-resizable__grip-mobile {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 2rem;
        background-color: color('navy1');
        color: color('white');
        cursor: pointer;
        justify-content: center;
        padding-top: 1rem;
        border-right: 1px solid color('grey2');
        border-left: 1px solid color('grey2');
      }

      &.eb-resizable--collapsed .eb-resizable__grip-mobile {
        display: none;
      }
    }

    &.eb-resizable--on-laptop-hider {
      width: 100%;
      max-width: 100%;
      z-index: 1;

      &.eb-resizable--collapsed {
        width: 2rem;
      }

      .eb-resizable__content {
        padding-right: 2rem;
      }

      .eb-resizable__grip,
      .eb-resizable__grip-indicator {
        display: none;
      }

      .eb-resizable__grip-mobile {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 2rem;
        background-color: color('navy1');
        color: color('white');
        cursor: pointer;
        justify-content: center;
        padding-top: 1rem;
        border-right: 1px solid color('grey2');
        border-left: 1px solid color('grey2');
      }

      &.eb-resizable--collapsed .eb-resizable__grip-mobile {
        display: none;
      }
    }
  }
}

@media #{$mobile} {
  .eb-resizable--horizontal {
    &.eb-resizable--on-mobile-hider {
      width: 100%;
      max-width: 100%;
      z-index: 1;

      &.eb-resizable--collapsed {
        width: 2rem;
      }
    }
  }
}
