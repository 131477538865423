@import '../../stylesheets/imports/index';

.eb-list-card {
  display: flex;
  align-items: center;
  padding: rem(24px);
  height: rem(92px);
  width: 100%;
  background-color: color('white');

  &.clickable {
    cursor: pointer;
  }

  &.border {
    border-bottom: 1px solid color('greye');
  }

  &__content-wrapper {
    width: 100%;
  }

  &__content {
    flex: 1 1 auto;
    margin-left: rem(16px);
    height: rem(40px);
  }

  &__content-upper {
    height: rem(20px);
  }

  &__content-lower {
    margin-top: rem(2px);
    height: rem(20px);
  }

  &__right-content {
    height: rem(40px);
    margin-left: rem(16px);
    align-items: flex-end;

    .eb-time-text {
      line-height: 1;
    }
  }

  &__right-content-upper {
    max-width: rem(40px);
    height: rem(20px);
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
  }

  &__right-content-lower {
    margin-top: rem(4px);
    height: rem(20px);

    &__count {
      display: flex;
      justify-content: center;
      align-items: center;
      height: rem(16px);
      min-width: rem(16px);
      line-height: rem(16px);
      font-size: 10px;
      color: color('white');
      background-color: color('purple');
      border-radius: 50%;

      &--circle {
        font-size: 0;
        height: 0.5rem;
        min-width: 0.5rem;
        margin-top: 0.25rem;
      }
    }
  }

  &__description {
    white-space: nowrap;
    width: rem(200px);
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
  }

  &--selected {
    background-color: color('purple-white');
  }
}
