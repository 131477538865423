@import '../../stylesheets/imports/index';

.eb-resume {
  padding: 2rem;

  .eb-markdown p {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__content-row {
    line-height: 1.5;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
