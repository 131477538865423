@import '../../stylesheets/imports/index';

.eb-checkbox {
  display: inline-block;
  cursor: pointer;

  &:not(&--on-off) {
    margin-bottom: rem(16px);
  }

  &:not(&--disabled):hover {
    .eb-checkbox__icon {
      color: color('purple');
    }
  }

  &:not(last-child) {
    margin-right: rem(32px);
  }

  &--block {
    display: block;
    margin-right: 0;
    margin-bottom: rem(16px);

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  &--transparent {
    &:not(&--disabled):hover &__label {
      color: color('grey6');
    }
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;

    .eb-checkbox {
      &__icon {
        &--checked {
          color: color('purple');
        }
      }

      &__label {
        &--checked {
          color: color('purple');
        }
      }
    }
  }

  &__icon {
    float: left;
    line-height: rem(18px);
    color: color('greyc');
    font-size: rem(18px);

    &--checked {
      color: color('purple');
    }

    &--small {
      font-size: rem(16px);
    }
  }

  &__label {
    padding-left: rem(30px);
    line-height: rem(18px);
    word-break: break-all;
    color: color('grey2');
    font-size: rem(16px);

    &--checked {
      color: color('purple');
    }

    &--size-small {
      padding-left: rem(24px);
      font-size: rem(14px);
    }
  }

  &__on-off {
    color: color('grey9');
    letter-spacing: 1px;

    &--checked {
      color: color('purple');
    }
  }
}
