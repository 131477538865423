@import '../../stylesheets/imports/index';

.eb-tabbed-container {
  margin: 0 auto;
  max-width: rem(880px);

  &__tab-bar {
    display: flex;
    border-top: 1px solid color('greyc');
    border-left: 1px solid color('greyc');
    border-right: 1px solid color('greyc');
    flex-flow: row wrap;
    width: 100%;
  }

  &__tab {
    display: block;
    border-right: 1px solid color('greyc');
    flex-grow: 1;
    flex-shrink: 1;
    height: rem(104px);
    text-align: center;
    font-size: rem(20px);
    line-height: rem(104px);
    cursor: pointer;

    &:last-of-type {
      border-right: 0;
    }

    &:hover {
      background-color: #edecf5;
    }

    &--active {
      background-color: #edecf5;
      font-weight: $bold;
    }
  }

  &__content {
    border: 1px solid color('greyc');
    padding: rem(20px) rem(20px);
  }

  &__footer {
    margin-top: rem(16px);
  }
}

.eb-tabbed-container-item {
}
