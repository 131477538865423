@import '../../stylesheets/imports/index';

.eb-chat-textarea {
  position: relative;
  background: color('white');

  &-enter {
    max-height: 0;
    transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 1);
    overflow: hidden;

    &-active {
      max-height: 100%;
    }
  }

  &-exit {
    max-height: 100%;
    transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 1);
    overflow: hidden;

    &-active {
      max-height: 0;
    }
  }

  &--inner {
    position: relative;
    background: color('white');
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &--emoji {
    position: absolute;
    bottom: 100%;
    margin-bottom: -4px;
    width: 100%;
    transition: bottom 500ms cubic-bezier(0.24, 1.04, 0.54, 1);
    z-index: 0;

    &__hidden {
      bottom: -200%;
      user-select: none;
      pointer-events: none;
    }

    .emoji-mart {
      width: 100% !important;
      border: none !important;
      border-radius: 5px 5px 0 0 !important;

      .emoji-mart-scroll + .emoji-mart-bar {
        @include invisible;
      }
    }
  }

  &--text {
    padding: 14px 16px;
    width: 100%;
    height: auto;

    textarea {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      min-height: 52px;
      border: none;
      background: color('white');
      font-size: inherit;
      font-family: 'Noto Sans KR', 'Malgun Gothic', 'Helvetica', 'Arial',
        sans-serif;
      line-height: 1.5rem;
      resize: none;

      &:focus {
        outline: none;
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  &--toolbox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 4px 16px;
    width: 100%;
    height: 40px;

    &--help {
      flex: 1 1 auto;
      font-family: monospace;
      transition: opacity 200ms ease-in-out;

      .eb-text {
        font-family: 'Inconsolata', monospace;
      }

      &__hidden {
        opacity: 0;
      }
    }

    &--buttons {
      display: flex;
      align-items: center;

      .eb-icon-button {
        font-size: 20px;
        width: 2.25rem;
        height: 2.25rem;
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }

        &--chat__filled {
          i {
            background: #fce133;
            border-radius: 50%;
          }
        }
      }
    }

    &--upload-input {
      @include invisible;
    }
  }
}
