@import '../../stylesheets/imports/index';

.eb-radio {
  display: block;
  transition: background-color 0.5s ease, color 0.5s ease;

  &--block {
    display: flex;

    .eb-radio-option {
      margin-right: 1rem;
      margin-bottom: 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &--inline-block {
    display: inline-block;
  }

  &--flex {
    display: flex;
    flex-flow: row wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    width: 100%;
  }

  &--vertical {
    display: flex;
    flex-direction: column;
  }
}

.eb-radio-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: color('grey2');
  margin-bottom: rem(12px);

  &:last-of-type {
    margin-bottom: 0;
  }

  &--active {
    color: color('purple');
    font-weight: $bold;
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &__icon {
    font-size: rem(18px);
    line-height: rem(18px);
    color: color('greyc');

    &--active {
      color: color('purple');
    }
    &--size-small {
      font-size: rem(16px);
    }
  }

  &:not(&--disabled):hover {
    .eb-radio-option__icon {
      color: color('purple');
    }
  }

  &__label {
    padding-left: rem(10px);
    font-size: rem(16px);
    line-height: rem(18px);
    color: inherit;
    &--size-small {
      font-size: rem(14px);
    }
  }
}

.eb-radio-button {
  display: inline-block;
  cursor: pointer;
  border-top: 1px solid color('greyc');
  border-bottom: 1px solid color('greyc');
  border-right: 1px solid color('greyc');
  padding: 0 rem(20px);
  background-color: color('white');
  color: color('grey2');
  font-size: rem(14px);
  text-align: center;
  height: rem(36px);
  line-height: rem(36px);

  &:first-of-type {
    border-left: 1px solid color('greyc');
  }

  &--active {
    background-color: color('grey9');
    color: color('white');
  }

  &--disabled {
    cursor: not-allowed;
    color: color('greyc');
  }

  &:not(&--disabled):not(&--active):hover {
    background-color: color('greye');
  }

  .eb-radio--block & {
    flex-grow: 1;
  }

  .eb-radio--vertical & {
    border-left: 1px solid color('greyd');
    border-top: 0;
    &:first-of-type {
      border-top: 1px solid color('greyd');
    }

    &--active {
      border: 1px solid color('purple');
      &:first-of-type {
        border-top: 1px solid color('purple');
      }
    }
  }

  .eb-radio--transparent & {
    &--active {
      background-color: transparent;
      color: color('purple');
      font-weight: bold;

      &:first-of-type {
        border-top: 1px solid color('greyd');
      }
    }

    &--disabled.eb-radio-button--active {
      color: color('grey9');
      border-color: color('greyd');
      background-color: transparent;
    }
  }

  .eb-radio--purple & {
    &:not(&--disabled):not(&--active):hover {
      background-color: rgba(121, 121, 211, 0.15);
    }

    &--active {
      background-color: color('purple-white');
      color: color('purple');
      font-weight: bold;
      border: 1px solid color('purple');
    }

    &--disabled.eb-radio-button--active {
      color: color('grey9');
      border-color: rgba(121, 121, 211, 0.5);
      background-color: color('white');
    }
  }

  .eb-radio--purple-white & {
    &:not(&--disabled):not(&--active):hover {
      background-color: rgba(121, 121, 211, 0.15);
    }

    &--active {
      background-color: color('white');
      color: color('purple');
      font-weight: bold;
      border: 1px solid color('purple');
    }

    &--disabled.eb-radio-button--active {
      color: color('purple');
      border: 1px solid color('purple');
      background-color: color('white');
      opacity: 0.5;
    }
  }
  .eb-radio--purple-white-opacity & {
    &:not(&--disabled):not(&--active):hover {
      background-color: color('purple-white');
    }

    &--active {
      background-color: color('purple-white');
      color: color('purple');
      font-weight: bold;
      border: 1px solid color('purple');
    }

    &--disabled.eb-radio-button--active {
      color: color('purple');
      border: 1px solid color('purple');
      background-color: color('purple-white');
    }
  }

  .eb-radio--border-round & {
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}

.eb-radio-card-wrapper {
  padding: 0 0.5rem;
  width: 8.75rem;
  max-width: 50%;

  &--no-padding {
    padding: 0;
    max-width: 100%;
  }
}

.eb-radio-card {
  display: inline-block;
  margin-bottom: 1rem;
  border: 1px solid color('greyd');
  border-radius: 4px;
  cursor: pointer;
  padding: 1rem;
  width: 100%;
  height: 8.5rem;
  text-align: center;
  color: color('grey9');
  background-color: color('white');
  font-size: 1.5rem;

  &--no-margin {
    margin: 0;
  }

  &--active {
    border-color: color('purple');
    color: color('purple');

    .t-dark &,
    &.t-dark {
      border-color: color('purple-light');
      color: color('purple-light');
    }
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--transparent {
    background-color: transparent;
  }

  &:not(&--disabled):not(&--active):hover {
    border-color: hover-color('purple');
    color: hover-color('purple');

    .t-dark &,
    &.t-dark {
      border-color: hover-color('purple-light');
      color: hover-color('purple-light');
    }
  }

  &__icon {
    display: inline-block;
    margin-top: 0.5rem;
    font-size: 1.5rem;
  }

  &__body {
    margin-top: 1rem;
    line-height: 1.25;
    word-break: keep-all;
    word-wrap: break-word;
    font-size: 0.875rem;

    &--without-icon {
      margin-top: 0;
      line-height: 1;
    }
  }

  &--align-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--align-center &__icon {
    margin-top: 0;
  }
}

.eb-radio-difficulty-input {
  display: flex;
  margin-right: 8%;
}
