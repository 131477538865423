@import '../../stylesheets/imports/index';

.eb-textbox {
  display: inline-block;
  position: relative;

  &__input-wrapper {
    display: inline-block;
    position: relative;
    height: 100%;
  }

  &__input {
    $placeholder-color: color('grey9');
    border: 1px solid color('greyd');
    padding: 0 1rem;
    width: 100%;
    height: 100%;
    color: color('grey2');

    &::placeholder {
      color: $placeholder-color;
      opacity: 1;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $placeholder-color;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $placeholder-color;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $placeholder-color !important;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $placeholder-color;
      opacity: 1;
    }

    &:focus {
      border-color: color('purple');
      outline: 0;
    }
  }

  &__input--padding-left {
    /* given big padding left for inner icon left */
    padding-left: 3.125rem;
  }

  &--dark {
    .eb-textbox__input {
      background-color: color('black');
      border: 1px solid color('grey4');
      color: color('white');
    }
  }

  &__inner-icon {
    display: flex;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 20px;
    align-items: center;
    color: color('grey9');
    font-size: 1rem;

    &--left {
      left: 1.5rem;
      right: auto;
    }
  }

  &__word-limit {
    display: flex;
    position: absolute;
    top: 8px;
    right: 8px;
    align-items: center;
    color: color('grey9');
    font-size: 12px;

    &--exceeded {
      color: color('red');
    }
  }

  &__postfix {
    display: inline-block;
    margin-left: 0.5rem;
  }

  &__description {
    margin-top: 0.5rem;
  }

  &--block {
    display: block;
    width: 100%;

    .eb-textbox__input-wrapper {
      width: 100%;
    }
  }

  &--disabled &__input {
    background-color: color('greyd');
    border-color: color('grey9');
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--invalid {
    .eb-textbox__input {
      border-color: color('red');
    }
  }

  &--size-tiny {
    height: 40px;
    font-size: 12px;
    .eb-textbox__input {
      font-size: 12px;
    }
  }

  &--size-small {
    height: 40px;
    font-size: 14px;
    .eb-textbox__input {
      font-size: 14px;
    }
  }

  &--size-default {
    height: 48px;
    font-size: 16px;
    .eb-textbox__input {
      font-size: 16px;
    }
  }

  &--fill {
    height: 100%;
    width: 100%;

    .eb-textbox__input-wrapper {
      width: 100%;
      min-width: 0;
    }

    .eb-textbox__input {
      padding: 0 0.5rem;
    }
  }
}
