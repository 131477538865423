@import '../../stylesheets/imports/index';

.eb-spinner {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &--fill {
    width: 100%;
    height: 100%;
  }

  &--size-tiny {
    transform: scale(0.4);
  }

  &--size-small {
    transform: scale(0.6);
  }

  &--size-default {
    transform: scale(0.8);
  }
}
