@import '../../stylesheets/imports/index';

.eb-top-nav-browser {
  width: 100%;
  height: 100%;

  &__top-nav-container {
    width: 100%;
  }

  &__body {
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.eb-top-nav-item {
  display: inline-block;
  opacity: 0.8;
  margin-right: 1rem;
  text-align: center;
  cursor: pointer;
  position: relative;
  /*
   TopNavItems have transparent border bottom by default, overriding border bottom color set on theme-specific classes in TopNav.
   Setting transparent instead of no border is to preserve height of overall contents, between when there is no active button and when a TopNavItem becomes active.
  */
  border-bottom-color: transparent;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 1;
    border-bottom-color: rgba(color('white'), 0.5);
    transition: all 0.3s ease;
  }

  &--active,
  &--active:hover {
    opacity: 1;
    font-weight: bold;
    // When active, use bottom color set by parent, i.e., theme-specific color.
    border-bottom-color: inherit;
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: default;
  }

  &__unread-marker {
    background-color: color('purple');
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 1;
  }
}

.eb-top-nav {
  .eb-top-nav-item {
    line-height: 1rem;
  }

  &--center {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &--theme-greyfb {
    background-color: color('greyfb');
    border-bottom-color: color('purple');

    .eb-top-nav-item {
      color: color('grey2');

      &--active {
        color: color('purple');
      }
    }
  }

  &--theme-dark {
    background-color: color('grey24');
    border-bottom-color: color('white');

    .eb-top-nav-item {
      color: color('white');
    }
  }

  &--theme-transparent {
    background-color: transparent;
    border-bottom-color: color('white');

    .eb-top-nav-item {
      color: color('white');

      &__unread-marker {
        background: color('white');
      }
    }
  }

  &--theme-purple {
    background-color: transparent;
    border-bottom-color: color('purple');

    .eb-top-nav-item {
      color: color('purple');

      &__unread-marker {
        background: color('purple');
      }
    }
  }

  &--size-small {
    font-size: rem(14px);

    .eb-top-nav-item {
      padding: 1rem 0.75rem;
      border-width: 3px;
      margin-right: 0;
    }
  }

  &--size-default {
    font-size: rem(16px);

    .eb-top-nav-item {
      padding: 1rem 1rem rem(12px);
      border-width: 4px;
    }
  }

  &--underline {
    .eb-top-nav-item {
      border-bottom-style: solid;
    }
  }
}
