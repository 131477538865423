@import '../../stylesheets/imports/index';

.eb-nav-tabs__card {
  .eb-nav-tab-pane {
    background-color: color('greyf5');
  }

  .eb-nav-tabs__tab-list {
    border-bottom: 1px solid color('greyd');

    &__container {
      display: flex;
      margin-bottom: -1px;
    }
  }

  .eb-nav-tabs__tab {
    height: rem(48px);
    padding: rem(16px) rem(24px);
    cursor: pointer;
    font-size: rem(14px);
    color: color('grey2');

    &.active {
      border-top: 1px solid color('greyd');
      border-right: 1px solid color('greyd');
      border-left: 1px solid color('greyd');
      border-radius: 2px 2px 0 0;
      background-color: color('greyf5');
      color: color('purple');
      font-weight: bold;
    }
  }
}

.eb-nav-tabs__head-nav {
  .eb-nav-tabs__tab-list {
    &__container {
      display: flex;
    }

    @media #{$tabletOrSmaller} {
      &__container {
        justify-content: center;
      }
    }
  }

  .eb-nav-tabs__tab {
    height: rem(48px);
    padding: rem(16px) rem(24px);
    font-size: rem(14px);
    cursor: pointer;

    &.active {
      color: color('purple');
      background-color: color('greyf678');
      border-bottom: 4px solid color('purple-bright');
      font-weight: bold;
    }
  }
}

.eb-nav-tabs--theme-white {
  .eb-nav-tab-pane,
  .eb-nav-tabs__tab.active {
    background-color: color('white');
  }
}

.eb-nav-tabs--has-padding {
  .eb-nav-tabs__tab-list {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
