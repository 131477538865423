@import '../../stylesheets/imports/index';

.eb-button-group {
  .eb-button {
    font-size: rem(12px);
    height: inherit;
    line-height: rem(24px);
    color: color('grey6');
    border-color: color('greyc');

    &__font-icon {
      font-size: rem(8px);
      color: color('grey9');
    }

    &:first-child {
      border-bottom-left-radius: rem(4px);
      border-top-left-radius: rem(4px);
    }

    &:last-child {
      border-bottom-right-radius: rem(4px);
      border-top-right-radius: rem(4px);
    }

    &:not(:last-child) {
      border-right: none;
    }
  }
}
