@import '../../stylesheets/imports/index';

.eb-zip-editor {
  width: 100%;
  height: 100%;

  &__image-container {
    width: 100%;
    height: 100%;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
  }

  &__link-container {
    display: table;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  &__markdown-editor-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__markdown-editor {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  &__markdown-preview {
    position: relative;
    float: right;
    height: 100%;
    max-width: 60%;
  }

  &__markdown {
    padding: 20px 20px;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  &__link {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    padding: 20px 20px;
    font-size: 24px;
    cursor: pointer;

    &:hover {
      color: color('turq');
    }
  }

  .eb-resizable__grip--horizontal {
    border: 0;
  }
}

.t-dark .eb-zip-editor__markdown {
  color: color('white');
}
