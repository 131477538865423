@import '../../stylesheets/imports/index';

.eb-json-editor {
  width: 500px;
  &--block {
    width: 100%;
  }

  &__inputs {
    position: relative;

    &:last-child {
      .eb-json-editor__add-block-button {
        opacity: 1;
        margin-top: 1rem;
        padding: 0.25rem;
        height: auto;
      }
    }

    .eb-json-editor--disabled & {
      margin-top: 1rem;
    }
  }

  &__inputs-label {
    margin-bottom: 0.5rem;
  }

  &__inputs-block {
    position: relative;
    margin-bottom: 0;

    .eb-json-editor--fixed & {
      margin-bottom: 1rem;
    }

    .eb-json-editor--one-line & {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &__add-block-button {
    opacity: 0;
    border: 1px dashed color('grey6');
    text-align: center;
    cursor: pointer;
    margin: 0.25rem 0;
    font-size: 14px;
    height: 0.5rem;

    &:hover {
      background-color: color('greye');
      opacity: 1;
      padding: 0.25rem;
      transition: opacity 0.25s ease, padding 0.25s ease;
      height: auto;
    }
  }

  &__delete-block-button {
    position: absolute;
    top: 0.5rem;
    right: 0;
    cursor: pointer;
    font-size: 0.5rem;
    z-index: 1;
    color: color('grey6');
    &:hover {
      color: color('turq');
    }

    &--no-label {
      position: relative;
      top: 0;
      margin-bottom: 0.5rem;
      text-align: right;
    }
  }

  &__input.eb-form-group {
    margin-bottom: 0;
  }

  &__input__type--image.eb-form-group {
    line-height: 1;
    margin-bottom: 0.75rem;

    .eb-safe-image {
      display: block;
    }
  }
}
