@import '../../stylesheets/imports/index';

.eb-quiz-container {
  display: flex;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 7rem);
    background-color: color('white');
    max-width: 51rem;
    width: 100%;
    border-radius: 2px;
    text-align: left;
    cursor: default;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: color('purple-white');
    border-radius: 2px 2px 0 0;
    padding: 1.25rem 3rem;
  }

  &__title {
    color: color('purple');
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5rem;
  }

  &__body {
    overflow-y: auto;
    padding: 2rem 3rem;
    border-radius: 2px;

    &--title--wrapper {
      display: block;
      font-size: 1.25rem;
      line-height: 1.875rem;
      margin-bottom: 1.5rem;
    }

    &--title--mark {
      display: inline-block;
      margin-right: 0.5rem;
      color: color('purple');
      font-weight: bold;
    }

    &--title--content {
      color: #222222;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    border-top: 1px solid #dddddd;
    padding: 1rem 3rem;
  }

  &__footer--status {
    margin-left: 1rem;
  }
}
