@import '../../stylesheets/imports/index';

.eb-logo-image-bar {
  padding: rem(32px) 0;
  text-align: center;
}

.eb-logo-image {
  display: inline-block;
  margin: 0 rem(60px);
  height: rem(38px);
  width: auto;
}
