.eb-emoji {
  display: inline-block;

  &__block {
    display: block;
  }

  &__custom {
    > span {
      display: block;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__custom,
  &__non-native {
    vertical-align: middle;
  }
}
