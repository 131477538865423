@import '../../stylesheets/imports/index';

.eb-index-tutor-card {
  background-size: contain;
  background-repeat: no-repeat;
  height: 332px;
  width: 264px;
  flex: 0 0 auto;

  &__title {
    opacity: 0.7;
  }

  &__profile-wrapper {
    width: 100%;
    padding-left: 3rem;
  }

  &__profile {
    list-style: initial;
    white-space: pre;
    line-height: 1.5;
    color: color('white');
    text-align: left;
    font-size: 14px;
    margin: 0;
    padding: 0;

    li {
      padding: 0;
    }
  }
}
