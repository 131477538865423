@import '../../stylesheets/imports/index';

.eb-banner {
  position: relative;
  padding: 0.75rem;
  width: 100%;
  min-height: 3rem;
  color: color('white');

  &.bg-color--turq {
    background-color: color('turq');
  }

  &.bg-color--purple {
    background-color: color('purple');
  }

  &__close-button {
    cursor: pointer;
    font-size: 0.75rem;
    color: color('gray0');
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateX(-50%) translateY(-50%);

    &.close-btn-color--gray0 {
      color: color('gray0');
    }

    &.close-btn-color--white {
      color: color('white');
    }
  }
}
