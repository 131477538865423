@import '../../stylesheets/imports/index';

.eb-chat-contents-date-divider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  height: 20px;
  overflow: hidden;

  &--date {
    padding: 0;
    width: 113px;
    min-width: 113px;
    text-align: center;
    color: #999 !important;
  }

  hr {
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    margin: 9.5px 0;
    border: 0;
    border-top: 1px solid #ccc;
  }
}
