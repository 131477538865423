@import '../../stylesheets/imports/index';

.eb-social-button {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 4px;

  &--facebook {
    background-color: #3b5998;
  }

  &--google {
    background-color: color('white');
  }

  &--naver {
    background-color: #3eaf0e;
  }

  &--kakao {
    background-color: #ffcd00;
  }

  &--github {
    background-color: #221e1b;
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &__shadow {
    display: none;
  }

  &--selected {
    .eb-social-button__shadow {
      border-radius: 4px;
      display: inherit;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(82, 79, 161, 0.6);
      border: 2px solid color('purple');

      &__icon {
        position: absolute;
        top: 7px;
        left: 8px;
        color: color('white');
        font-size: 20px;
      }
    }
  }

  &__type--vertical {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: rem(44px);

    &.eb-social-button {
      &--facebook,
      &--naver,
      &--github {
        .eb-text {
          color: color('white');
        }
      }
    }

    .eb-social-button__help-text {
      margin-left: 0.5rem;
    }
  }
}
