@import '../../stylesheets/imports/index';

.eb-sticky-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: white;
  z-index: 1000;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: right;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}
