@import '../../stylesheets/imports/index';

.eb-table {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  background-color: transparent;
  border: 1px solid color('greyd');

  &--border-none {
    border: none;
  }

  &--border-row {
    tr {
      border-bottom: 1px solid color('greyd');
    }
  }

  &--border-all {
    border: none;
    padding-bottom: 1px;

    th,
    td {
      border: 1px solid color('greyd');
    }
  }

  &--responsive {
    display: block;
    width: 100%;
    overflow-x: auto;

    .eb-table__table {
      table-layout: auto;
    }
  }

  &--min-height {
    min-height: 18rem;
  }

  &--no-hover {
    .eb-table__table-row:hover {
      cursor: default;
    }
  }

  &--font-size-tiny {
    font-size: rem(12px);
  }

  &--font-size-small {
    font-size: rem(14px);
  }

  &__table {
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    color: color('grey2');
    text-align: left;
    table-layout: fixed;
    word-break: break-all;

    &--striped tbody tr:nth-of-type(even) {
      background-color: color('greyfb');
    }

    &--emphasize-value td:last-child {
      font-weight: 500;
    }

    &__wrapper {
      .eb-table__table-head {
        background-color: color('greyfb');
      }

      &-head {
        overflow-x: hidden;
        padding-right: rem(8px);
        background-color: color('greyfb');
      }

      &-body {
        overflow-x: hidden;
        overflow-y: auto;

        & thead {
          background-color: color('greyfb');
        }
      }
    }

    &--is-loading {
      opacity: 0.5;
    }
  }

  &__placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    color: color('grey9');
    padding: 2rem 0;
    text-align: center;
    font-size: rem(14px);
    background-color: color('white');

    &--is-loading {
      margin: 2rem auto 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &-icon {
      font-size: 4rem;
      margin-bottom: 1rem;
      color: color('greyc');
    }
  }

  &__sort {
    &-indicator {
      visibility: inherit;

      &--hidden {
        visibility: hidden;
      }
    }
  }

  &__table-body {
    background-color: color('white');
  }

  &__table-head {
    th {
      font-weight: normal;
      line-height: 1.4;
    }
  }

  &__table-foot {
    .eb-table__table-row {
      background-color: color('purple-white');
    }
  }

  &__table-row {
    &--checkable {
      user-select: none;
    }

    &--checked {
      background-color: color('purple-white');
    }

    &:not(.eb-table__table-row--checked):not(.eb-table__table-row-head):not(.eb-table__table-row--no-hover):hover {
      background-color: color('greyfb');

      .eb-table__table--selectable & {
        cursor: pointer;
      }
    }

    &--disabled {
      background-color: color('greyf9');
      color: color('grey9');
      cursor: initial;

      & * {
        color: color('grey9') !important;
        cursor: initial;
      }
    }
  }

  &__table-cell {
    vertical-align: middle;

    &.sortable {
      cursor: pointer;
      transition: background-color 0.5s ease;

      &:hover {
        background-color: color('greye');
        transition: background-color 0.5s ease;
      }
    }

    &:first-of-type {
      padding-left: rem(24px);
    }

    &:last-of-type {
      padding-right: rem(24px);
    }

    &--head {
      padding: 12px 16px;
    }

    &--head--bold {
      font-weight: bold !important;
    }

    &--body {
      padding: 12px 16px;
      user-select: auto;
    }

    &--foot {
      padding: 12px 16px;
    }

    &--select-icon {
      font-size: 12px;
      color: color('grey9');
      padding: 0;

      .icon-radio-fill:not(.icon-disabled),
      .icon-checkbox-fill:not(.icon-disabled) {
        color: color('purple');
      }
    }

    &--no-padding {
      padding: 0;
    }
  }
}
