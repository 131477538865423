@import '../../stylesheets/imports/index';

.eb-ant-progress {
  .ant-progress-outer {
    display: flex;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .ant-progress-inner {
    background-color: color('gray2');
    overflow: hidden;
    border-radius: 2px;
  }

  .ant-progress-bg {
    border-radius: 2px;
    background-color: color('purple');
  }

  .ant-progress-success-bg {
    background-color: color('lime');
  }

  .ant-progress-circle-path {
    stroke: color('purple');
  }
}

.eb-ant-progress.ant-progress-status-disabled {
  .ant-progress-bg {
    background-color: color('grey9');
  }
  .ant-progress-text {
    color: color('grey9');
  }
}

.eb-ant-progress.ant-progress-status-success {
  .ant-progress-bg {
    background-color: color('lime');
  }
  .ant-progress-text {
    color: color('lime');
  }

  &.eb-ant-progress--status-disabled {
    .ant-progress-bg {
      background-color: color('grey9');
    }
    .ant-progress-text {
      color: color('grey9');
    }
  }
}

.eb-ant-progress.ant-progress-status-exception {
  .ant-progress-bg {
    background-color: color('red');
  }
  .ant-progress-text {
    color: color('red');
  }
}

.eb-ant-progress.ant-progress-status-exception {
  .ant-progress-circle-path {
    stroke: color('red');
  }
  .ant-progress-text {
    color: color('red');
  }
}

.eb-ant-progress.ant-progress-status-success {
  .ant-progress-circle-path {
    stroke: color('lime');
  }
  .ant-progress-text {
    color: color('lime');
  }
}
