@import '../../stylesheets/imports/index';

.eb-selector-group {
  &__button {
    border: solid 1px color('greyd');
    background-color: white;
    cursor: pointer;
    &--selected {
      border: solid 1px color('purple');
      background-color: color('purple-white');
    }
    &:hover {
      background-color: color('greyf5');
    }
  }
}
.eb-selector-group-item {
  &__container {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 12px 20px;
  }
  &__content-container {
    margin-left: 1rem;
  }
  &__icon {
    font-size: rem(28px);
  }
  &__title {
    color: color('grey2');
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
  }
  &__description {
    color: color('grey2');
    font-size: 14px;
    line-height: 22px;
  }
}
