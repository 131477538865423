@function color($color-name) {
  $color: map-get(
    (
      'purple-black': #2d2e42,
      'purple-dark': #3c3a74,
      'purple': #524fa1,
      'purple-light': #7979d3,
      'purple-white': #efeff6,
      'purple-bright': #dcdcec,
      'lightblue': #63c2c2,
      'darkblue': #0078b5,
      'darkblue-bright': #cae7e9,
      'darkblue-white': #e5f1f7,
      'turq': #3ab6bc,
      'turq-dark': #2b878c,
      'red': #f94669,
      'yellow': #e57800,
      'lime': #00ab53,
      'white': #fff,
      'greyfb': #fbfbfb,
      'greyf9': #f9f9f9,
      'greyf5': #f5f5f5,
      'greye': #eee,
      'greyd': #ddd,
      'greyc': #ccc,
      'greyb': #bbb,
      'greya': #aaa,
      'grey9': #999,
      'grey6': #666,
      'grey48': #484848,
      'grey4': #444,
      'grey3': #333,
      'grey2': #222,
      'black': #000,
      'grey24': #24252f,
      'grey31': #31313a,
      'grey37': #373744,
      'grey38': #383844,
      'grey49': #494951,
      'greyf678': #f6f7f8,
      'navy1': #20202a,
      'navy2': #2e2e3b,
      'navy3': #46465a,
      'pink': #e6556c,
      'blue': #00add9,
      'facebook': #464b68,
      'naver': #179b46,
      'kakao': #edb459,
      'google': #c93253,
      'gray0': #f9fafc,
      'gray1': #f0f1f3,
      'gray2': #e1e2e4,
      'gray3': #c9cacc,
      'gray4': #a8a9ab,
      'gray5': #7d7e80,
      'gray6': #5e5f61,
      'gray7': #464749,
      'gray8': #292a2c,
      'gray9': #151618,
    ),
    $color-name
  );
  @return $color;
}

@function mix-white($color-name, $percent) {
  @return mix(color('white'), color($color-name), $percent);
}

@function mix-black($color-name, $percent) {
  @return mix(color('black'), color($color-name), $percent);
}

@function hover-color($color-name) {
  @return mix-black($color-name, 20);
}
