@import '../../stylesheets/imports/index';

.eb-ant-popover {
  &__overlay {
    &--theme-dark {
      & .ant-popover-arrow {
        background-color: color('grey24');
      }
      & .ant-popover-inner {
        background-color: color('grey24');
      }
    }
  }
}
