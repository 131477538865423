@import '../../stylesheets/imports/index';

.ant-layout {
  background-color: color('greye');

  &-header {
    background: transparent;
    padding: 0;
    line-height: inherit;
    height: auto;
  }

  &-content {
    max-width: 77rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }

  &-sider {
    max-width: 12rem;
    min-width: 12rem;
    width: 12rem;
    flex: 0 0 12rem;
    background: transparent;
    transition: none;
    padding: 0;
  }
}
