@import '../../stylesheets/imports/index';

.eb-message-box {
  display: inline-block;
  position: relative;
  text-align: left;

  &__icon {
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    height: 3rem;
    width: 3rem;
    padding: 0 12px;
    font-size: 24px;
    color: color('grey9');
    background-color: color('white');
    transition: background-color 0.3s ease, color 0.3s ease;
    outline: none;
    cursor: pointer;

    &--active,
    &:hover {
      background-color: color('purple-white');
    }

    &--active {
      color: color('purple');
    }

    .t-dark & {
      background-color: color('grey37');
      color: color('grey9');

      &--active,
      &:hover {
        background-color: color('grey6');
      }

      &--active {
        color: color('grey9');
      }
    }
  }

  &--disabled &__icon {
    cursor: not-allowed;

    &:hover {
      background-color: color('white');
    }
  }

  &__btn .eb-button {
    border-radius: 4px;
  }

  &__inner {
    position: absolute;
    display: none;
    right: 0;
    top: 3rem;
    z-index: 951;
    background-color: color('white');
    border: 1px solid color('greyd');
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12);

    &--visible {
      display: block;
    }
  }

  &--fill-window &__inner {
    position: fixed;
    width: 100%;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    height: calc(100% - 3rem);
    box-shadow: none;
  }

  &__header {
    height: 3rem;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid color('greyd');
  }

  &__title {
    color: color('grey2');
    font-size: 16px;
    font-weight: bold;
  }

  &__item-list {
    overflow: auto;

    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      padding: 1rem 0;
      color: #999;
      text-align: center;

      &__icon {
        display: block;
        color: #ccc;
        font-size: 4rem;
        line-height: 6rem !important;
      }
    }

    &--loading {
      padding: 1rem 0;
    }
  }

  &--fill-window &__item-list {
    height: 100%;
  }

  &__show-all {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid color('greyd');
    font-size: 14px;
    color: color('purple');
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      background-color: color('greye');
    }
  }

  &__unread {
    position: absolute;
    top: 6px;
    right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    height: 16px;
    background-color: color('red');
    color: color('white');
    font-size: 10px;
    font-weight: bold;
    line-height: 16px;
    padding: 3px 5px;
    border-radius: 8px;

    &__help-center {
      top: -4px;
      right: -8px;
    }

    .t-dark & {
      background-color: color('purple-light');
    }
  }
}

.eb-message-box-item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem;
  border-bottom: 1px solid color('greyd');
  background-color: color('white');
  cursor: pointer;
  text-decoration: none;
  color: color('grey2');

  &:hover {
    background-color: color('greye');
  }

  &__image {
    background-color: color('white');
    flex: 0 0 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid color('greyd');
    border-radius: 50%;

    img {
      border-radius: 50%;
    }
  }

  &__inner {
    margin-left: 12px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    overflow: hidden;
  }

  &__row {
    min-height: 18px;
    font-size: 14px;
    line-height: 1.5;

    &--flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  &__title-wrapper {
    display: flex;
  }

  &__title {
    color: color('grey2');
    font-weight: bold;
    font-size: 14px;
    max-width: 100%;
    margin-right: 4px;

    .eb-badge {
      vertical-align: bottom;
      margin-left: 8px;
    }
  }

  &__participants {
    font-weight: normal;
  }

  &__description {
    color: color('grey9');
    font-size: 12px;

    &--time:not(:first-child) {
      flex: 0 0 4rem;
      margin-left: 8px;
      text-align: right;
    }
  }

  &__unread {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    min-width: 16px;
    background-color: color('purple');
    color: color('white');
    font-size: 10px;
    font-weight: bold;
    padding: 3px 5px;
    margin-left: 8px;
    border-radius: 8px;
    line-height: 16px;
  }

  &__help-center {
    &__content-left {
      position: relative;
      overflow: hidden;
    }

    &__content-right {
      margin-left: 1rem;
    }

    &__time {
      font-size: rem(12px);
      color: color('grey9');
    }
  }
}
