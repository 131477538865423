@import '../../stylesheets/imports/index';

.eb-image-input {
  display: flex;

  @media #{$tabletOrSmaller} {
    flex-wrap: wrap;
  }

  &__box-wrapper {
    display: flex;
  }

  &__drop-box {
    position: relative;
    width: 100%;
    height: 100%;

    &--is-active {
      .eb-image-input__drop-box-message {
        display: flex;
      }
    }
  }

  &__drop-box-message {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: color('grey9');
    border: 2px dashed color('greyd');
    border-radius: 4px;
    background-color: color('greyfb');
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__image-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 0;
    border: 2px solid color('greye');
    cursor: pointer;
    width: 100%;
    height: 100%;

    .eb-image-input--disabled & {
      border-color: color('greyd');
      background-color: color('greye');
      cursor: not-allowed;
    }
  }

  &__image {
    margin: auto;
    background-size: cover;
    max-width: 100%;
    max-height: 100%;
  }

  &__clear-button {
    position: absolute;
    top: rem(5px);
    right: rem(5px);
    opacity: 0.4;
    cursor: pointer;
    color: color('grey2');
    font-size: rem(12px);

    &:hover {
      opacity: 1;
    }
  }

  &__info {
    vertical-align: top;

    @media #{$tabletOrSmaller} {
      width: 100%;
      margin-top: 1.5rem;
    }
  }
}
