@import '../../stylesheets/imports/index';

.eb-ant-pagination {
  &--steps {
    .ant-pagination-item {
      height: 8px;
      min-width: 8px;
      border-radius: 100%;
      background-color: #ced0da;
      border: 0;

      &-active {
        background-color: #6b6bab;
      }
    }
  }
}
