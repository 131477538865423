@import '../../stylesheets/imports/index';

.eb-key-value-table {
  margin-right: auto;
  margin-left: auto;
  border: rem(1px) solid color('greyd');
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;

  &__table {
    margin-right: auto;
    margin-left: auto;
    background-color: color('white');
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    text-align: left;
    color: color('grey2');
    font-size: rem(14px);

    tr:first-child th,
    tr:first-child td {
      padding-top: rem(20px);
    }

    tr:last-child th,
    tr:last-child td {
      padding-bottom: rem(20px);
    }
  }

  &__table-cell {
    padding: rem(10px) rem(24px);
    vertical-align: middle;
    font-weight: normal;

    &--head {
      border-right: rem(1px) solid color('greyd');
      background-color: color('greyfb');
      font-weight: normal;
    }

    @for $i from 1 through 12 {
      &--head-#{$i} {
        width: $i * 100 / 12 * 1%;
      }
    }

    &--body {
      min-height: rem(42px);
    }
  }
}
