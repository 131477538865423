@import '../../stylesheets/imports/index';

.eb-listbox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid color('greyd');
  padding: 1px 4px;
  min-height: 41px;

  &--focused {
    outline: 1px solid color('purple');
  }

  &--disabled {
    opacity: 0.5;
    background-color: color('greyd');
    border-color: color('grey9');
    cursor: not-allowed;
  }

  &--invalid {
    border-color: color('red');
  }

  &__list-item {
    display: inline-block;
    margin: 2px;
    border: 1px solid color('greyd');
    border-radius: 3px;
    background-color: color('greyfb');
    cursor: default;
    padding: 3px;

    &:focus {
      outline: 1px solid color('turq');
    }

    .eb-listbox--disabled & {
      cursor: not-allowed;
      background-color: color('grey9');
    }
  }

  &__item-content {
    display: inline-block;
    margin-right: 4px;
    font-size: 14px;
  }

  &__delete-icon {
    display: inline-block;
    opacity: 0.3;
    cursor: pointer;
    vertical-align: top;
    font-size: 13px;

    &:hover {
      opacity: 1;
    }
  }

  &__input.eb-textbox {
    flex: 2 1 auto;
    width: auto;
  }

  &__input .eb-textbox__input-wrapper {
    min-width: 0;
  }

  &__input .eb-textbox__input {
    border: 0;
    padding: 0 8px;
    width: 100%;
    height: 100%;

    &:focus {
      outline: none;
    }
  }
}
