@import '../../stylesheets/imports/index';

.eb-index-header {
  background-color: color('white');
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $header-z-index;
  box-shadow: inset 0 -1px 0 0 #dddddd, 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;

  &__wrapper {
    @include container;
    width: 100%;
  }

  &__logo {
    position: relative;

    &-container {
      display: flex;
      align-items: center;
    }

    &__link {
      display: flex;
      align-items: center;
      height: 3rem;
    }

    &__image {
      width: 106px;
      height: 32px;
      opacity: 1;
      transition: all 0.5s ease;

      &--transparent {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .eb-index-header-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 3rem;
    font-size: 0.875rem;
    align-content: center;
    padding: 0 12px;
    margin-right: 0.5rem;
    color: color('black');
    transition: all 0.5s ease;

    &--border {
      border: 1px solid color('purple');
      padding: 13px 20px;
      color: color('purple');
      transition: all 0.5s ease;
      font-size: 0.75rem;
    }

    &--bold {
      font-weight: bold;
    }

    &--primary {
      border: 0;
      padding: 13px 20px;
      background-color: color('purple');
      color: color('white');
      font-size: 0.75rem;
    }

    &--active {
      font-weight: bold;
      color: color('purple');
    }
  }

  &--transparent {
    background-color: transparent;
    box-shadow: none;
    transition: all 0.5s ease;

    .eb-index-header__logo__image {
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease;

      &--transparent {
        opacity: 1;
        visibility: visible;
        transition: all 0.5s ease;
      }
    }

    .eb-index-header-button {
      color: color('white');
      transition: all 0.5s ease;

      &--border {
        border: 1px solid color('white');
        color: color('white');
        transition: all 0.5s ease;
      }
    }
  }
}

.eb-index-header-mobile {
  z-index: 2;
  background-color: color('white');
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  z-index: $header-z-index;
  justify-content: center;
  box-shadow: inset 0 -1px 0 0 #dddddd, 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border-bottom: 0px solid transparent;
  transition: all 0.5s ease;

  &--menu-open {
    box-shadow: none;
    border-bottom: 1px solid color('greyd');
  }

  &__menu {
    z-index: 1;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    padding: 0;

    &__actions {
      display: flex;
      padding: 20px 4px 7px 20px;
    }

    &__docs {
      padding: 0 20px;

      .eb-hspace {
        display: none;
      }
    }
  }

  &__menu-button {
    cursor: pointer;
    padding: 1rem;
  }

  &__logo {
    position: relative;
    display: flex;
    align-items: center;

    &-container {
      display: flex;
      align-items: center;
    }

    &__link {
      display: flex;
      align-items: center;
      height: 3rem;
    }

    &__image {
      width: 106px;
      height: 32px;
      opacity: 1;
      visibility: visible;
      transition: all 0.5s ease;

      &--large {
        width: 123px;
        height: auto;
      }

      &--transparent {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .eb-index-header-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 13px 0;
    align-content: center;
    color: color('black');
    transition: all 0.5s ease;

    &--border {
      padding: 13px 20px;
      border: 1px solid color('purple');
      color: color('purple');
      transition: all 0.5s ease;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      justify-content: center;
    }

    &--primary {
      padding: 13px 20px;
      border: 0;
      background-color: color('purple');
      color: color('white');
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      justify-content: center;
    }

    &--size--large {
      height: 3.25rem;
      line-height: 3.25rem;
      padding: 0;
    }

    &--no-margin {
      @media (max-width: $tabletOrSmaller) {
        margin-right: 0;
      }
    }
  }

  &--transparent {
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
    transition: all 0.5s ease;

    .eb-index-header-mobile__logo__image {
      opacity: 0;
      transition: all 0.5s ease;

      &--transparent {
        opacity: 1;
        visibility: visible;
        transition: all 0.5s ease;
      }
    }

    .eb-index-header-button {
      color: color('white');
      transition: all 0.5s ease;

      &--border {
        border: 1px solid color('white');
        color: color('white');
        transition: all 0.5s ease;
      }
    }
  }
}

.eb-index-header-mobile--drawer {
  // height: auto;
  transition: none;

  &.eb-index-header-mobile--menu-open {
    .eb-index-header-mobile--drawer__inner {
      left: 0;
    }
  }

  &__button.eb-text {
    height: 3rem;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__toolbar {
    background-color: color('purple');
    width: 100%;
    height: 4.5rem;
    padding-right: 4px;
    align-items: center;
  }

  &__inner {
    max-width: rem(375px);
    background-color: color('greyfb');
    height: 100%;
    position: fixed;
    left: rem(-375px);
    top: 0;
    transition: left 200ms;
    z-index: 2;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    overflow: auto;

    &-button {
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      padding: 0;
      text-align: center;
    }

    &__profile {
      padding-left: rem(32px);
      background-color: color('purple');

      .eb-user-profile-image {
        width: 4rem;
        height: 4rem;
      }
    }

    &__docs,
    &__actions {
      padding-left: rem(32px);

      .eb-index-header-button {
        background-color: transparent;
        color: color('grey2');
        padding: 0;
        border: 0;
        justify-content: flex-start;
      }
    }
  }

  &__menu__overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(color('black'), 0.1);
    z-index: 0;
  }
}
