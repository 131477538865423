@import '../../stylesheets/imports/index';
@import './imports/styles';

.eb-code-editor {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 400px;

  .CodeMirror {
    width: 100%;
    height: 100%;
    font-family: $monospace-font-stack;
    font-size: inherit;
    border: none;
  }
}

.eb-code-editor-marker {
  $marker-opacity: 0.7;
  position: relative;
  background-color: rgba(0, 255, 0, $marker-opacity);

  &--color1 {
    background-color: rgba(color('purple'), $marker-opacity);
  }
  &--color2 {
    background-color: rgba(color('turq'), $marker-opacity);
  }
  &--color3 {
    background-color: rgba(color('red'), $marker-opacity);
  }
  &--color4 {
    background-color: rgba(color('yellow'), $marker-opacity);
  }
  &--color5 {
    background-color: rgba(color('lime'), $marker-opacity);
  }
  &--color6 {
    background-color: rgba(color('blue'), $marker-opacity);
  }
  &--color7 {
    background-color: colorrgba(('lightpink'), $marker-opacity);
  }
}

@mixin vertical-inner-shadow($shadow-width, $shadow-color) {
  -webkit-box-shadow: inset $shadow-width 0px 0px 0px $shadow-color;
  -moz-box-shadow: inset $shadow-width 0px 0px 0px $shadow-color;
  box-shadow: inset $shadow-width 0px 0px 0px $shadow-color;
}

.eb-code-editor-line-cursor {
  @include vertical-inner-shadow(2px, rgba(0, 255, 255, 1));
  margin-left: 4px;
  z-index: 300;

  &--color1 {
    @include vertical-inner-shadow(2px, color('purple'));
  }
  &--color2 {
    @include vertical-inner-shadow(2px, color('turq'));
  }
  &--color3 {
    @include vertical-inner-shadow(2px, color('red'));
  }
  &--color4 {
    @include vertical-inner-shadow(2px, color('yellow'));
  }
  &--color5 {
    @include vertical-inner-shadow(2px, color('lime'));
  }
  &--color6 {
    @include vertical-inner-shadow(2px, color('blue'));
  }
  &--color7 {
    @include vertical-inner-shadow(2px, color('red'));
  }
}

.eb-code-editor-cursor {
  position: relative;
  @include vertical-inner-shadow(2px, rgba(0, 255, 255, 1));

  &--color1 {
    @include vertical-inner-shadow(2px, color('purple'));
  }
  &--color2 {
    @include vertical-inner-shadow(2px, color('turq'));
  }
  &--color3 {
    @include vertical-inner-shadow(2px, color('red'));
  }
  &--color4 {
    @include vertical-inner-shadow(2px, color('yellow'));
  }
  &--color5 {
    @include vertical-inner-shadow(2px, color('lime'));
  }
  &--color6 {
    @include vertical-inner-shadow(2px, color('blue'));
  }
  &--color7 {
    @include vertical-inner-shadow(2px, color('red'));
  }
}

.eb-code-editor-end-cursor {
  position: relative;
  @include vertical-inner-shadow(-2px, rgba(0, 255, 255, 1));

  &--color1 {
    @include vertical-inner-shadow(-2px, color('purple'));
  }
  &--color2 {
    @include vertical-inner-shadow(-2px, color('turq'));
  }
  &--color3 {
    @include vertical-inner-shadow(-2px, color('red'));
  }
  &--color4 {
    @include vertical-inner-shadow(-2px, color('yellow'));
  }
  &--color5 {
    @include vertical-inner-shadow(-2px, color('lime'));
  }
  &--color6 {
    @include vertical-inner-shadow(-2px, color('blue'));
  }
  &--color7 {
    @include vertical-inner-shadow(-2px, color('red'));
  }
}

.eb-code-editor-tag {
  position: absolute;
  border-radius: 3px;
  padding: 0 4px;
  white-space: nowrap;
  color: color('white');
  font-size: 12px;
  line-height: 12px;
  height: 12px;
  left: 0;
  top: -12px;

  &--color1 {
    background-color: color('purple');
  }
  &--color2 {
    background-color: color('turq');
  }
  &--color3 {
    background-color: color('red');
  }
  &--color4 {
    background-color: color('yellow');
  }
  &--color5 {
    background-color: color('lime');
  }
  &--color6 {
    background-color: color('blue');
  }
  &--color7 {
    background-color: color('red');
  }
}
