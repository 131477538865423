@import '../../stylesheets/imports/index';

.eb-ant-input-number {
  &.ant-input-number {
    display: inline-block;
    height: 50px;
    min-width: 120px;
    border-radius: 0;

    input {
      box-sizing: border-box;
      height: 46px;
      font-size: 16px;
    }
  }

  &--block.ant-input-number {
    display: block;
    min-width: none;
    width: 100%;
  }

  &.ant-input-number-sm {
    height: 42px;

    input {
      height: 38px;
      font-size: 16px;
    }
  }

  .ant-input-number-input-wrap {
    border: 1px solid transparent;
  }

  .ant-input-number-focused {
    border-color: color('purple');
  }

  .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
  .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: color('purple');
  }
}
