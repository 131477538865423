@import '../../stylesheets/imports/index';

.eb-step-progress {
  position: relative;
  width: 100%;

  &__type-vertical {
    display: flex;
    align-items: stretch;
    justify-content: center;

    .eb-step-progress__steps-wrapper {
      display: flex;
      align-items: stretch;
    }

    .eb-step-progress__progress-circle__line {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
    }

    .eb-step-progress__progress-circle__icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .eb-step-progress__progress-circle {
      &__wrapper {
        flex-direction: column;
      }
    }
  }

  &__progress-circle {
    color: color('greyc');

    &-default {
      border-color: color('greyc');
    }

    &-progress {
      color: color('purple');
    }

    &-completed {
      border-color: color('purple');
      color: color('purple');
    }
  }

  &__progress-circle__wrapper {
    font-size: 1.25rem;
  }

  &__progress-circle__line {
    background-color: color('greyc');

    &-active {
      background-color: color('purple');
    }
  }

  &__progress-circle__wrapper {
    line-height: 1;
  }

  &__status-closed {
    .eb-step-progress {
      &__progress-circle-progress {
        color: color('grey9');
      }

      &__progress-circle-completed {
        color: color('grey9');
      }

      &__progress-circle__line-active {
        background-color: color('grey9');
      }
    }
  }
}
