@import '../../stylesheets/imports/index';

.ant-steps {
  &-item {
    &--clickable {
      cursor: pointer;
    }

    &-description,
    &-title {
      color: color('grey9');
    }

    &--clickable:hover &-title {
      color: color('purple');
      text-decoration: underline;
    }

    &-tail {
      display: none !important;
    }
  }
}

.ant-steps-item-icon > .ant-steps-icon {
  color: color('purple');
}

.ant-steps-item-wait .ant-steps-item-icon {
  border-color: color('greyc');
  background-color: transparent;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: color('purple');
  background-color: transparent;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: color('purple');
}

.ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
  font-weight: bold;
  color: color('purple');
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: color('purple');
  background-color: color('purple');
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: color('white');
}

.ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title {
  color: color('grey2');
}
