@import '../../stylesheets/imports/index';

.eb-online-light {
  display: inline-block;
  border-radius: 50%;
  background-color: color('greyc');

  &--size-small {
    height: 6px;
    width: 6px;
  }

  &--size-medium {
    height: 8px;
    width: 8px;
  }

  &--size-large {
    height: 10px;
    width: 10px;
  }

  &--role-success.active {
    background-color: color('lime');
  }

  &--role-primary.active {
    background-color: color('purple');
  }

  &--role-danger.active {
    background-color: color('red');
  }

  &--role-warning.active {
    background-color: color('yellow');
  }
}
