@import '../../stylesheets/imports/index';

.eb-pdf-renderer {
  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }

  .eb-pdf-renderer__canvas-wrapper {
    position: relative;
    display: flex;
    flex: 1 1 0%;
    overflow: auto;

    > .react-pdf__Document {
      margin: auto;
    }
  }

  &__bottom-actions {
    background-color: color('grey24');
    flex: 0 0 48px;

    &__fit-pdf-button {
      padding: 0.25rem;
      border-radius: 0.25rem;

      &--active {
        background-color: rgba(255, 255, 255, 0.25);
      }
    }

    .eb-ant-input-number.ant-input-number-sm {
      height: 26px;
      width: 60px;
      min-width: 60px;
      border-radius: 5px;
    }

    .eb-ant-input-number .ant-input-number-input-wrap {
      border: none;
    }

    .eb-ant-input-number.ant-input-number-sm input {
      height: 24px;
      font-size: 14px;
      color: color('greyd');
      background-color: color('grey24');
    }

    .ant-input-number {
      border: 1px solid color('grey9');
      background-color: transparent;
    }

    .ant-input-number-handler-wrap {
      border-left: 1px solid color('grey9');
      background-color: color('grey6');
    }
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
  }

  // ===== react-pdf =====

  .react-pdf {
    &__message {
      &--loading,
      &--error {
        height: 100%;
      }
    }
  }
}
