@import '../../stylesheets/imports/index';

.eb-datetime-picker {
  display: inline-block;
  position: relative;

  &--block {
    display: block;
    width: 100%;
  }

  &--box-hidden {
    .icon,
    .eb-datetime-picker__textbox {
      display: none;
    }
  }

  .icon {
    position: absolute;
    top: 0;
    cursor: pointer;
    line-height: 3rem;
  }

  &--size-small .icon {
    line-height: 40px;
  }

  .icon-calendar {
    left: 1rem;
    font-size: rem(18px);
    z-index: 1;
  }

  .icon-cross-rounded {
    right: 0.5rem;
    opacity: 0.4;
    font-size: rem(12px);

    &:hover {
      opacity: 1;
    }
  }

  &__textbox .eb-textbox__input {
    margin-bottom: rem(8px);
    cursor: pointer;
    padding-left: 2.5rem;
    padding-right: 1.5rem;
  }

  &__datetime {
    z-index: 1;
    /* Override react-datetime's styles. */
    input[type='text'] {
      background-color: color('white');
      border: rem(1px) solid color('greyd');
      padding: rem(10px) rem(18px);
      margin-bottom: rem(24px);
      font-size: rem(14px);
      width: 100%;
      outline: none;
    }

    .rdtPicker {
      position: relative;
      border-radius: rem(3px);
      border: rem(1px) solid color('greyd');
      font-size: rem(14px);
      box-shadow: none;

      & * {
        vertical-align: middle;
      }
    }

    td.rdtToday:before {
      border-bottom-color: color('turq') !important;
    }

    td.rdtActive.rdtToday:before {
      border-bottom-color: color('white') !important;
    }

    td.rdtActive {
      background-color: color('turq') !important;
      color: color('white');
    }

    th {
      font-weight: bold;
    }
  }

  &:not(&--explicit) &__datetime {
    position: absolute;
    z-index: 2;
  }
}
