@import '../../stylesheets/imports/index';

.eb-index-headline {
  padding: rem(32px) 0;

  &__title {
    padding: rem(16px) rem(16px);
    width: 100%;
    text-align: center;
    font-size: rem(40px);
    font-weight: $bold;
    color: color('white');
    white-space: pre-wrap;
    line-height: 1.5em;
  }

  &__subtitle {
    padding: rem(16px) rem(16px);
    width: 100%;
    text-align: center;
    font-size: rem(20px);
    color: color('white');
    white-space: pre-wrap;
    line-height: 1.5em;
    opacity: 0.7;
  }

  &--subpage {
    padding: 2rem 0 0 0;

    .eb-index-headline {
      &__title {
        font-size: 32px;
      }

      &__subtitle {
        padding: 0 1rem;
      }
    }
  }
}

@media (max-width: $media-sm-width) {
  .eb-index-headline {
    padding: rem(32px) 0;

    &__title {
      padding: rem(12px) rem(28px);
      font-size: rem(32px);
    }

    &__subtitle {
      padding: rem(12px) rem(28px);
      font-size: rem(16px);
      line-height: 1.5em;
      opacity: 0.7;
    }

    &--subpage {
      padding: 2rem 0 0 0;

      .eb-index-headline {
        &__title {
          font-size: 32px;
        }

        &__subtitle {
          padding: 0 1rem;
        }
      }
    }
  }
}
