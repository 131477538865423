@import '../../stylesheets/imports/index';

.eb-markdownbox {
  display: inline-block;
  position: relative;
  padding: rem(20px) rem(20px) rem(20px) rem(16px);
  border: 1px solid color('greyd');
  min-height: 10rem;
  background-color: color('white');

  &--block {
    width: 100%;
    display: block;
  }

  &--full-height {
    height: 100%;
  }

  .ant-tabs + &--full-height {
    margin-top: 4px;
  }

  &:focus,
  &--focused {
    outline: 1px solid color('purple');
  }

  &--disabled {
    opacity: 0.5;
    background-color: color('greyd');
    border-color: color('grey9');

    &:focus,
    &.eb-markdownbox--focused {
      outline: 0;
    }

    .eb-markdownbox__content-wrapper,
    .eb-markdownbox__preview {
      padding: 0;
    }
  }

  &__content-wrapper {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 1rem;
    width: 100%;
    height: 100%;
    min-height: 64px;
    font-size: inherit;
    color: inherit;

    .eb-textarea-container--block {
      margin-top: 1px;
      height: 100%;
    }
  }

  &__header {
    position: absolute;
    border-bottom: 1px solid color('greyd');
    top: 0;
    left: 0;
    width: 100%;
  }

  &__header-button {
    cursor: pointer;
    float: left;
    padding: 0 8px 14px 8px;
    margin-right: 8px;
    font-size: 14px;
    color: color('greyc');

    &:hover {
      border-bottom: 2px solid color('greyc');
    }

    &--is-active {
      color: color('grey2');
      border-bottom: 2px solid color('grey2');

      &:hover {
        border-bottom: 2px solid color('grey2');
      }
    }
  }

  &__textarea.eb-textarea {
    font-size: 16px;
    padding: rem(16px) 0;
    border: none;
    line-height: 1.5;

    &:focus {
      outline: 0;
    }
  }

  &__textarea--hidden.eb-textarea {
    display: none;
  }

  &__preview {
    font-size: 16px;
    padding: 15px 0;
    width: 100%;
    height: 100%;
    color: inherit;
    overflow-y: auto;
  }

  &__description {
    position: absolute;
    bottom: 12px;
    color: color('grey6');
    font-size: 12px;

    a,
    a:link,
    a:visited,
    a:focus {
      text-decoration: underline;
      color: color('purple');
    }
  }
}
