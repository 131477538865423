@import '../../stylesheets/imports/index';

.eb-horizontal-scroll-handler {
  position: relative;
  display: flex;
  align-items: center;

  &__scroller {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__hider {
    display: flex;
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    width: 2.25rem;
    cursor: pointer;
    z-index: 1;
    height: 100%;

    &--prev {
      left: 0;
      color: color('white');
    }

    &--next {
      right: 0;
      color: color('white');
    }
  }

  &--backgronud-purple &__hider--prev {
    color: color('white');
    @include prev-hider-gradient(color('purple-black'));
  }

  &--backgronud-greye &__hider--prev {
    color: color('grey6');
    @include prev-hider-gradient(color('greye'));
  }

  &--backgronud-purple &__hider--next {
    color: color('white');
    @include next-hider-gradient(color('purple-black'));
  }

  &--backgronud-greye &__hider--next {
    color: color('grey6');
    @include next-hider-gradient(color('greye'));
  }
}
