@import '../../stylesheets/imports/index';

.eb-ant-card {
  &--header-no-border {
    .ant-card-head {
      border-bottom: 0;
    }
  }
  &--size-large {
    width: rem(368px);
  }
  &--is-overlay {
    position: absolute;
    z-index: 100;
  }

  .ant-card-head {
    padding: 0 1.5rem;
  }
  .ant-card-extra {
    padding-top: 16px;
    padding-bottom: 0;
  }

  .ant-card-body {
    padding: 1rem 2rem;
  }
  .ant-card-actions {
    background-color: color('white');
    padding: 0 1rem;
    li {
      border-right: 0;
      &:first-of-type {
        text-align: left;
      }
      &:last-of-type {
        text-align: right;
      }
    }
  }
}
