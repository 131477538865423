@import '../../stylesheets/imports/index';

.eb-chat-confirm-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 30px);
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;

  &-enter {
    opacity: 0;
    transform: translateY(-30px);

    &-active {
      opacity: 1;
      transform: translateY(0);
      transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 1);
    }
  }

  &-exit {
    opacity: 1;
    transform: translateY(0);
    transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 1);

    &-active {
      opacity: 0;
      transform: translateY(-30px);
    }
  }

  &--inner {
    display: inline-block;
    width: 100%;
    background: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 5px 4px 1px rgba(0, 0, 0, 0.2);
  }

  &--preview {
    margin: 16px;

    &--image {
      width: 100%;
      height: 160px;
      border: 1px solid color('gray-lighter');
      border-radius: 3px;
      background-position: center !important;
      background-size: cover !important;
    }

    &--file {
      padding: 16px;
      border-radius: 3px;
      background: color('gray-lighter');

      .eb-text {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        overflow: hidden;
      }
    }
  }

  &--footer {
    margin: 16px;
    text-align: right;

    .eb-button {
      margin-right: 16px;
      min-width: 70px;
      text-decoration: none;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
