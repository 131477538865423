@import '../../stylesheets/imports/index';

.eb-grid-column {
  .eb-grid-row--has-padding & {
    padding: 0 0.75rem;
  }

  @for $i from 1 through 12 {
    &--width-#{$i} {
      flex: 0 0 $i * 100 / 12 * 1%;
      max-width: $i * 100 / 12 * 1%;
    }
  }
}

@media (max-width: $media-lg-width) {
  .eb-grid-row {
    &--min-size-large {
      > .eb-grid-column {
        flex: 0 1 auto;
        padding: 0;
        max-width: none;
      }
    }
  }
}

@media (max-width: $media-sm-width) {
  .eb-grid-row {
    &--min-size-small {
      > .eb-grid-column {
        flex: 0 1 auto;
        padding: 0;
        max-width: none;
      }
    }
  }
}
