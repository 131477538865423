@import '../../stylesheets/imports/index';

.eb-user-list {
  padding: rem(12px) 0;
  overflow-y: auto;
}

.eb-user-list-group {
  background-color: color('white');

  &__header {
    padding: 0 rem(24px);
    font-size: rem(12px);
    font-weight: bold;
    line-height: 2rem;
    height: 2rem;
    color: color('grey6');
    background-color: color('greye');
    border-top: 1px solid color('greyd');
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.folded {
      border-bottom: 0;
    }

    [class^='icon-'] {
      color: color('grey9');
      padding: 0.25rem;

      &.action-right {
        transform: rotate(-90deg);
      }
    }
  }

  &__title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__fold {
    display: inline-block;
    padding-left: rem(4px);
    transition: transform 0.3s;

    &.folded {
      transform: rotate(-180deg);
    }
  }

  &__uid-toggle-link {
    font-weight: normal;
    color: color('purple');
    text-decoration: underline;
    cursor: pointer;
  }

  &__message {
    color: color('greyc');
    text-align: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &__message-icon {
    font-size: rem(32px);
  }

  &__message-text {
    margin-top: rem(16px);
    font-size: rem(12px);
  }

  &__load-more {
    padding: 0 rem(24px);
    line-height: rem(40px);
    font-size: rem(14px);
    text-align: center;
    color: color('grey2');
    cursor: pointer;

    &:hover {
      background-color: color('purple-white');
    }
  }

  &__loading {
    height: rem(40px);
  }
}

.eb-user-list-item {
  border-bottom: 1px solid color('greyd');
  padding: 0 rem(24px) 0 rem(24px);
  line-height: 3.5rem;
  font-size: rem(14px);
  display: flex;
  position: relative;
  align-items: center;
  text-align: left;
  color: color('grey2');
  position: relative;

  &--clickable {
    cursor: pointer;
  }

  &--clickable:hover {
    background-color: color('purple-white');
  }

  &--active {
    background-color: color('purple-white');
  }

  &__name {
    justify-content: space-between;
    align-items: center;
    width: 7.5rem;
  }

  &--offline {
    color: color('grey9');
  }

  &__image {
    width: rem(32px);
    height: rem(32px);
    border-radius: 50%;
    margin: rem(4px) rem(12px) rem(4px) 0;
    flex: rem(32px) 0 0;
  }

  &__uid {
    display: none;
    font-weight: normal;
    color: color('grey9');
  }

  .eb-user-list-group--show-uid &__uid {
    display: block;
  }

  &__info {
    line-height: rem(40px);
    font-size: rem(14px);
    text-align: right;
    font-weight: $bold;
    margin-left: auto;
  }
}
