.u-center-table {
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
}

.u-center-table-cell {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  color: inherit;
}

.u-fill-parent {
  width: 100%;
  height: 100%;
}

.u-block {
  display: block;
}

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.eb-u-ellipsis,
.u-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-searched {
  background-color: rgba(color('purple-light'), 0.4);
  padding: 0 1px;
  margin: 0 -1px;
}

.u-visibility-hidden {
  visibility: hidden;
}

.u-flex-auto {
  flex: 1 1 0%;
}
