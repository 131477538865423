@import '../../stylesheets/imports/index';

.eb-carousel {
  position: relative;

  &__button {
    display: none;
    position: absolute;
    transition: background-color 0.3s ease;
    border-radius: 50%;
    background-color: color('greyc');
    text-align: center;
    color: color('white');

    &:hover {
      background-color: color('grey9');
    }

    &--default {
      top: calc(50% - 1.5rem);
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      font-size: 1rem;

      &.eb-carousel__button {
        &--left {
          left: -1.5rem;
        }

        &--right {
          right: -1.5rem;
        }
      }
    }

    &--small {
      top: calc(50% - 1rem);
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      font-size: 0.75rem;

      &.eb-carousel__button {
        &--left {
          left: -1rem;
        }

        &--right {
          right: -1rem;
        }
      }
    }

    &--visible {
      display: block;
      cursor: pointer;
    }
  }

  &__items {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    width: 100%;
    display: inline-block;
    margin-right: 0.5rem;
    min-height: 4rem;
    vertical-align: top;

    &:last-child {
      margin-right: 0;
    }
  }
}
