@import '../../stylesheets/imports/index';

.eb-button {
  display: inline-block;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    color 0.3s ease;
  cursor: pointer;
  border: 1px solid;
  text-align: center;
  border-radius: 0;

  &:focus {
    outline: 0;
  }

  &__inner {
    white-space: nowrap;
  }

  &--size-tight {
    border-radius: 2px;
    padding: 0;
    height: auto;
    font-size: inherit;
    line-height: inherit;

    &:hover .eb-button__label {
      text-decoration: underline;
    }
  }

  &--size-nano {
    border-radius: 2px;
    padding: 0 rem(4px);
    height: rem(18px);
    min-width: rem(32px);
    font-size: rem(10px);
  }

  &--size-micro {
    border-radius: 2px;
    padding: 0 rem(6px);
    height: rem(24px);
    min-width: rem(48px);
    font-size: rem(12px);
  }

  &--size-tiny {
    border-radius: 2px;
    padding: 0 rem(12px);
    height: rem(32px);
    min-width: rem(64px);
    font-size: rem(14px);
    line-height: rem(30px);
  }

  &--size-small {
    border-radius: 3px;
    padding: 0 rem(16px);
    height: rem(40px);
    min-width: rem(80px);
    font-size: rem(14px);
    line-height: rem(38px);
  }

  &--size-default {
    border-radius: 3px;
    padding: 0 1.5rem;
    height: 3rem;
    min-width: 8rem;
    font-size: rem(16px);
    line-height: rem(46px);
  }

  &--size-large {
    border-radius: 3px;
    padding: 0 rem(24px);
    height: rem(56px);
    min-width: rem(144px);
    font-size: rem(20px);
    line-height: rem(54px);
  }

  &--no-border-radius {
    border-radius: unset !important;
  }

  &--bold {
    font-weight: $bold;
  }

  &--with-icon {
    display: inline-flex;
  }

  &--underline {
    text-decoration: underline;

    &:link,
    &:visited,
    &:active {
      text-decoration: underline;
    }

    .eb-button__label {
      text-decoration: underline;
    }
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--role-primary {
    @include button-color-set('purple');

    .t-dark &,
    &.t-dark {
      @include button-color-set('purple-light');
    }
  }

  &--role-secondary {
    @include button-color-set('turq');
  }

  &--role-lightpurple {
    @include button-color-set('purple-light');
  }

  &--role-darkblue {
    @include button-color-set('darkblue');
  }

  &--role-grey {
    @include button-color-set('grey9', 'grey2');
  }

  &--role-white {
    @include button-color-set('white');
    color: color('grey2');
  }

  &--role-grey6 {
    @include button-color-set('grey6');
  }

  &--role-grey9 {
    @include button-color-set('grey9');
  }

  &--role-success {
    @include button-color-set('lime');
  }

  &--role-warning {
    @include button-color-set('yellow');
  }

  &--role-danger {
    @include button-color-set('red');
  }

  &--role-pink {
    @include button-color-set('pink');
  }

  &--role-red {
    @include button-color-set('red');
  }

  &--role-gray2 {
    @include button-color-set('gray2');
  }

  &--role-greyfb {
    background-color: color('greyfb');
    border-color: color('greyd');

    &:not(.eb-button--disabled):not(.eb-button--no-border):hover {
      border-color: hover-color('greyfb');
      background-color: hover-color('greyfb');
    }

    &.eb-button--transparent {
      background-color: transparent;
      color: color('greyfb');

      &:not(.eb-button--disabled):hover {
        background-color: transparent;
        color: color('grey9');
      }
    }
  }

  &--role-navy1 {
    @include button-color-set('navy1');
  }

  &--role-navy2 {
    @include button-color-set('navy2');
  }

  &--role-navy3 {
    @include button-color-set('navy3');
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--margined {
    margin-right: rem(16px);

    &:last-of-type {
      margin-right: 0;
    }
  }

  &--loading {
    pointer-events: none;
  }

  &--no-border {
    border: 0;

    &:focus {
      outline: 0;
    }
  }

  &--border-dashed {
    border-style: dashed;
  }

  &--border-double {
    border-style: double;
  }

  &--border-dotted {
    border-style: dotted;
  }

  &--now-wrap {
    white-space: nowrap;
  }

  &__font-icon {
    vertical-align: middle;

    &--left {
      padding-right: 8px;
    }

    &--right {
      padding-left: 8px;
    }
  }

  &--fixed-font-size {
    font-size: 16px;
  }

  &--fluid {
    display: block;
    width: 100%;
  }
}
