@import '../../stylesheets/imports/index';

.eb-index-interview-card {
  margin: 0 auto;
  width: 100%;
  position: relative;

  // @media (max-width: $media-lg-width) {
  //   width: rem(816px);
  // }

  // @media (max-width: $media-sm-width) {
  //   width: 320px;
  // }

  &__prev--button {
    position: absolute;
    top: 6rem;
    right: 100%;
    height: 5rem;
    width: 5rem;
    z-index: 2;

    &:hover {
      opacity: 0.7;
    }
  }

  &__next--button {
    position: absolute;
    top: 6rem;
    left: 100%;
    height: 5rem;
    width: 5rem;
    z-index: 2;

    &:hover {
      opacity: 0.7;
    }
  }

  &__items {
    position: relative;
    z-index: 1;
  }

  &__row {
    & + & {
      margin-right: 1rem;
    }
  }

  &__column {
    width: 26rem;
    padding: 0 0.5rem;
  }

  &__mobile {
    display: block;
    padding: 0 0.5rem;
  }

  .carousel.carousel-slider {
    overflow: initial;
    padding-bottom: 3rem;

    .control-arrow {
      padding: 0;
      width: 1.25rem;
      height: 2rem;
      top: 8rem;
      bottom: auto;

      &.control-prev {
        right: 100%;
        left: auto;
        margin-right: 2.5rem;
      }
      &.control-next {
        right: auto;
        left: 100%;
        margin-left: 2.5rem;
      }
    }
  }

  .slider {
    width: 100%;
  }

  .slide {
    background: transparent;
    text-align: initial;
  }

  .control-dots {
    margin: 0;
    padding-left: 0;

    .dot {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: #c5c5ed;
      opacity: 0.8;
      transition: all 0.3s ease-in-out;
      box-shadow: none;
      margin: 4px;
    }

    .selected {
      width: 32px;
      height: 12px;
      border-radius: 6px;
      background-color: color('purple');
      transition: all 0.3s ease-in-out;
      box-shadow: none;
    }
  }
}

.eb-index-interview-card-item {
  width: 1024px;
  //height: 344px;

  &__left {
    flex-direction: column;
    min-width: 0;
    position: relative;
    padding-right: 8rem;
    text-align: left;

    &__flex {
      flex-direction: column;
    }

    &__description {
      max-width: 100%;
      word-break: keep-all;
      font-size: 20px;
      line-height: 1.6em;
    }

    &__action {
      cursor: pointer;
      font-size: 20px;
      line-height: 1.6em;

      a {
        color: color('grey9');
        text-decoration: underline;
        font-size: 1rem;
      }
    }

    &__title {
      font-size: 20px;
      line-height: 1.6em;
    }

    &__name {
      font-size: 20px;
      line-height: 1.6em;
    }
  }

  &__right {
    position: relative;
    min-width: 0;
    width: 59%;
    height: 21.5rem;
    cursor: pointer;

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &__play-button {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0.7;
      transition: all 0.3s ease;

      &:hover {
        opacity: 1;
        transition: all 0.3s ease;
      }
    }
  }

  @media (max-width: $media-lg-width) {
    width: 70%;

    &__left {
      padding-right: 1rem;
    }

    &__right {
      width: 69%;
    }
  }

  @media (max-width: $media-md-width) {
    width: 93.75%;
    height: auto;
    flex-direction: column-reverse;

    &__left {
      &__description {
        display: none;
      }
      &__flex {
        margin-top: 1.5rem;
        flex-direction: row;
      }
      &__image {
        margin-right: 1rem;
      }
    }

    &__right {
      align-self: center;
      width: 100%;
      height: 25.5rem;
    }
  }

  @media (max-width: $media-sm-width) {
    width: 91.3%;

    &__right {
      height: 18.625rem;
    }
  }
}

.eb-carousel__items .eb-index-interview-card-item {
  white-space: normal;
}

@media (max-width: $media-sm-width) {
  .eb-index-interview-card-item {
    &__left {
      &__action {
        font-size: rem(16px);
      }

      &__title {
        font-size: rem(16px);
        line-height: 1.5em;
      }

      &__name {
        font-size: rem(16px);
        line-height: 1.5em;
      }
    }
  }
}

.eb-index-interview-academy-card-item {
  background-color: color('white');
  border: 1px solid color('gray2');
  min-width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  white-space: normal;
  text-align: left;
  display: flex;
  flex-direction: column;

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;

  @media #{$desktop} {
    height: rem(258px);
  }

  // @media #{$laptop} {
  //   height: rem(275px);
  // }

  // &__profile-image {
  // }

  &__profile-title {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__past-course {
    padding-bottom: 0.5rem;
  }

  &__description {
    word-break: keep-all;
    white-space: pre-wrap;
    max-height: rem(80px);
    max-width: 100%;
    text-overflow: ellipsis;
    line-height: 1.5;

    @media #{$tabletOrSmaller} {
      max-height: auto;
    }
  }
}

.eb-landing-interview-card {
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  position: relative;

  @media #{$desktop} {
    padding-right: 0;
    padding-left: 0;
    max-width: $container-xl-width;
  }

  @media #{$laptop} {
    padding-right: 0;
    padding-left: 0;
    min-width: $container-lg-width;
    width: $container-lg-width;
  }

  &__column {
    @media #{$desktop} {
      min-width: 25rem;
      width: 25rem;
    }

    @media #{$laptop} {
      min-width: rem(452px);
      width: rem(452px);
    }

    @media #{$tabletOrSmaller} {
      width: rem(327px);
      min-width: rem(327px);
    }
  }

  &__list {
    position: relative;

    &__inner {
      margin-left: -0.5rem;

      @media #{$tabletOrSmaller} {
        align-items: stretch;
      }
    }
  }

  // &__quote {
  //   position: absolute;
  //   top: 0;
  //   right: 100%;
  //   width: 80px;
  //   height: 56px;
  //   margin-right: 1.5rem;
  //   background-size: contain;
  //   z-index: 2;

  //   @media #{$tablet} {
  //     display: none;
  //   }

  //   @media #{$mobile} {
  //     display: none;
  //   }
  // }

  // &__quote-pair {
  //   position: absolute;
  //   left: 100%;
  //   top: 0;
  //   width: 80px;
  //   height: 56px;
  //   margin-left: 1.5rem;
  //   background-size: contain;
  //   z-index: 2;

  //   @media #{$tablet} {
  //     display: none;
  //   }

  //   @media #{$mobile} {
  //     display: none;
  //   }
  // }

  // .eb-index-interview-academy-card-item {
  //   @media #{$tablet} {
  //     height: rem(370px);
  //   }

  //   @media #{$mobile} {
  //     height: rem(370px);
  //   }

  //   &__box {
  //     @media #{$tablet} {
  //       display: flex;
  //       flex-direction: column;
  //       height: rem(370px);
  //     }

  //     @media #{$mobile} {
  //       display: flex;
  //       flex-direction: column;
  //       height: rem(370px);
  //     }
  //   }

  //   &__subject {
  //     @media #{$tablet} {
  //       line-height: 1.5;
  //       white-space: pre-wrap;
  //       word-break: keep-all;
  //     }

  //     @media #{$mobile} {
  //       line-height: 1.5;
  //       white-space: pre-wrap;
  //       word-break: keep-all;
  //     }
  //   }

  //   &__description {
  //     @media #{$tablet} {
  //       height: rem(120px);
  //       max-height: rem(120px);
  //     }

  //     @media #{$mobile} {
  //       height: rem(120px);
  //       max-height: rem(120px);
  //     }
  //   }

  //   &__box-profile__image {
  //     @media #{$tablet} {
  //       display: none;
  //     }

  //     @media #{$mobile} {
  //       display: none;
  //     }
  //   }
  // }

  .eb-horizontal-scroll-handler__hider {
    cursor: pointer;
    border-radius: 50%;
    background-color: color('greyc');
    color: color('white');
    height: 3rem;
    width: 3rem;
    margin-top: -1.5rem;
    position: absolute;
    top: 50%;
    overflow: hidden;

    @media #{$mobile} {
      display: none;
    }

    &--prev {
      left: auto;
      right: 100%;
      margin-right: -1.5rem;

      // @media #{$desktop} {
      //   margin-right: -1.5rem;
      // }
    }

    &--next {
      left: 100%;
      right: auto;
      margin-left: -1.5rem;

      // @media #{$desktop} {
      //   margin-left: -1.5rem;
      // }
    }

    // &:hover {
    //   [class*='icon-'] {
    //     color: color('greyb');
    //   }
    // }

    [class*='icon-'] {
      color: color('white');
      height: 3rem;
      width: 3rem;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
