@import '../../stylesheets/imports/index';

.eb-ant-table {
  tr.ant-table-row-hover td {
    background: color('greyf5');
  }

  .ant-table-row:nth-child(even) {
    background: color('greyf9');
  }

  .ant-table-row:nth-child(odd) {
    background: color('white');
  }
}
