@import '../../stylesheets/imports/index';

.eb-toggle {
  display: block;
  overflow: hidden;
  cursor: pointer;

  &__icon {
    float: left;
    font-size: 20px;
    line-height: 20px;
    color: color('greye');

    &--active {
      color: color('purple');
    }
  }

  &__label {
    float: left;
    padding: 0 15px;
    font-size: 14px;
    line-height: 20px;
    color: color('grey2');

    &--checked {
      color: color('purple');
    }
  }

  &--disabled {
    cursor: default;

    .eb-toggle {
      &__icon {
        color: color('greye');

        &--active {
          color: color('purple');
        }
      }

      &__label {
        color: color('greyc');

        &--active {
          color: color('purple');
        }
      }
    }
  }
}
