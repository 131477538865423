@import '../../stylesheets/imports/index';

.eb-markdown {
  &__pseudo-input {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    background: transparent;
  }

  &__copy-button {
    position: absolute;
    top: 3px;
    right: 5px;
    background-color: color('white');
    border: none;
    border-radius: 3px;
    padding: 1px 5px;
  }

  &__contents__styled {
    img {
      max-width: 100%;
      height: auto;
    }

    a {
      color: color('purple');
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
      }
    }

    p {
      word-wrap: break-word;
      overflow-wrap: break-word;
      line-height: 1.5;
    }

    code {
      padding: 4px 6px;
      margin: 0 2px;
      background-color: color('greyd');
      font-size: 0.95em;
      font-weight: bold;
    }

    pre {
      position: relative;
      @include ts-code;
      display: block;
      background-color: color('grey2');
      border: 1px solid color('grey6');
      padding: 10px;
      margin: 10px 0;
      overflow-x: auto;
      color: color('white');
      line-height: 1.5;

      code {
        padding: 0;
        margin: 0;
        background-color: inherit;
        font-weight: normal;
      }
    }

    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }

    h4,
    h5,
    h6 {
      font-size: 18px;
      margin-bottom: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500;
      line-height: 1.5;
      &:first-of-type {
        margin-top: 0;
      }
    }

    table {
      margin: 1rem 0;
      width: 100%;
      font-size: 14px;
      display: block;
      overflow-x: auto;
      border-collapse: collapse;
      border-spacing: 0;
    }

    th,
    td {
      padding: 10px;
      text-align: left;
    }

    tbody > tr:nth-child(odd) > td,
    tbody > tr:nth-child(odd) > th {
      background-color: color('greyfb');
    }

    th {
      font-weight: bold;
      border-bottom: 2px solid color('greyd');
    }

    td {
      border-bottom: 1px solid color('greyd');
    }

    ol,
    ul {
      padding-left: 24px;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;

      li {
        padding-left: 0;
        word-wrap: break-word;
        line-height: 1.5;
      }
    }

    blockquote {
      margin: 15px 0;
      border-left: 4px solid color('greyd');
      padding: 0 15px;
      color: color('grey6');
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
