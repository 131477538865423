@import '../../stylesheets/imports/index';

.eb-image-card {
  border: 1px solid color('greyd');
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;

  &__type-default {
    @media #{$desktop} {
      width: 21rem;
      height: 26.75rem;
    }
    @media #{$laptop} {
      width: 18.5rem;
      height: 26.75rem;
    }

    @media #{$tablet} {
      width: 100%;
      height: 9.25rem;
      margin-bottom: 1.5rem;
      text-align: center;
    }

    @media #{$mobile} {
      width: 100%;
      height: 9.25rem;
      margin-bottom: 1.5rem;
      text-align: center;
    }

    .eb-image-card__logo {
      font-size: 0;
      line-height: 0;
      position: relative;

      @media #{$desktop} {
        width: 21rem;
        height: 16rem;
      }

      @media #{$laptop} {
        width: 18.5rem;
        height: 16rem;
        overflow: hidden;
      }

      @media #{$tablet} {
        display: none;
      }

      @media #{$mobile} {
        display: none;
      }
    }

    .eb-image-card__image {
      @media #{$desktop} {
        background-size: contain;
        width: 21rem;
        height: 16rem;
      }

      @media #{$laptop} {
        background-size: 100%;
        background-position: top center;
        width: 25rem;
        height: 16rem;
        transform: translateX(-50%);
        position: absolute;
        top: 0;
        left: 50%;
      }

      @media #{$tablet} {
        display: none;
      }

      @media #{$mobile} {
        display: none;
      }
    }
  }

  &__type-elice {
    box-sizing: content-box;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

    @media #{$desktop} {
      width: 25rem;
      height: 29rem;
    }

    @media #{$laptop} {
      width: 18.5rem;
    }

    @media #{$tablet} {
      width: 100%;
      height: rem(250px);
      margin-bottom: 1.5rem;
    }

    @media #{$mobile} {
      width: 100%;
      height: auto;
      margin-bottom: 1.5rem;
    }

    .eb-image-card__subtitle {
      @media #{$laptop} {
        height: rem(66px);
      }
    }

    .eb-image-card__logo {
      font-size: 0;
      line-height: 0;
      position: relative;

      @media #{$desktop} {
        width: 25rem;
        height: 12.5rem;
      }

      @media #{$laptop} {
        width: 18.5rem;
        height: 12.5rem;
      }

      @media #{$tablet} {
        width: 100%;
        height: 0.5rem;
      }

      @media #{$mobile} {
        width: 100%;
        height: 0.5rem;
      }
    }

    .eb-image-card__image {
      @media #{$desktop} {
        background-size: contain;
        width: 25rem;
        height: 12.5rem;
      }

      @media #{$laptop} {
        background-size: 100%;
        background-position: top center;
        width: 25rem;
        height: 12.5rem;
        transform: translateX(-50%);
        position: absolute;
        top: 0;
        left: 50%;
      }

      @media #{$tablet} {
        background-size: 100%;
        background-position: top center;
        width: 100%;
        height: 0.5rem;
      }

      @media #{$mobile} {
        background-size: 100%;
        background-position: top center;
        width: 100%;
        height: 0.5rem;
      }
    }
  }

  &__body {
    background-color: #fff;
    line-height: 1;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
  }

  &__title {
    display: block;
    line-height: 1;
  }

  &__subtitle {
    display: block;
    margin-top: 1rem;
    word-break: keep-all;
    white-space: pre-wrap;
  }

  &__cta-wrapper {
    line-height: 1;
  }

  &__bullets {
    .eb-text {
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        line-height: 1;
        padding-bottom: 0.75rem;

        &:last-child {
          padding-bottom: 0;
        }
      }

      .eb-text__icon {
        padding-right: 0.5rem;
      }
    }
  }
}
