@import '../../stylesheets/imports/index';

.eb-breadcrumb {
  line-height: 1;

  &__dropdown {
    &__trigger {
      cursor: pointer;
    }

    .ant-dropdown-menu {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: rem(267px);
    }

    .ant-dropdown-menu-item {
      .eb-text {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .ant-dropdown-menu-item:hover {
      background-color: color('purple-white');
    }
  }

  a {
    color: color('grey9');
    &:hover {
      color: color('purple');
      text-decoration: underline;
    }
  }

  &--size-tiny {
    font-size: rem(12px);
  }

  &--theme-light * {
    color: color('gray4');
  }
}
