@font-face {
  font-family: 'elice_200306';
  src:  url('fonts/elice_200306.eot?wcnu4w');
  src:  url('fonts/elice_200306.eot?wcnu4w#iefix') format('embedded-opentype'),
    url('fonts/elice_200306.ttf?wcnu4w') format('truetype'),
    url('fonts/elice_200306.woff?wcnu4w') format('woff'),
    url('fonts/elice_200306.svg?wcnu4w#elice_200306') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'elice_200306' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home1:before {
  content: "\ea22";
}
.icon-git:before {
  content: "\ea20";
  color: #969696;
}
.icon-web1:before {
  content: "\ea21";
}
.icon-filled:before {
  content: "\ea19";
}
.icon-hd:before {
  content: "\ea1a";
}
.icon-pause:before {
  content: "\ea1b";
}
.icon-qrscan:before {
  content: "\ea1c";
}
.icon-star-line:before {
  content: "\ea1d";
}
.icon-volume:before {
  content: "\ea1e";
}
.icon-web:before {
  content: "\ea1f";
}
.icon-exit-extra-bold:before {
  content: "\ea17";
}
.icon-list-extra-bold:before {
  content: "\ea18";
}
.icon-checkbox-minus:before {
  content: "\ea12";
}
.icon-edit-extra-bold:before {
  content: "\ea13";
}
.icon-info-extra-bold:before {
  content: "\ea14";
}
.icon-material-extra-bold:before {
  content: "\ea15";
}
.icon-tutoring-extra-bold:before {
  content: "\ea16";
}
.icon-noti-fill:before {
  content: "\ea0f";
}
.icon-help-fill:before {
  content: "\ea10";
}
.icon-camera:before {
  content: "\ea11";
}
.icon-prevmore:before {
  content: "\ea0d";
}
.icon-nextmore:before {
  content: "\ea0e";
}
.icon-kakaoplus:before {
  content: "\ea0c";
}
.icon-view-bold:before {
  content: "\ea0b";
}
.icon-arduino:before {
  content: "\ea09";
}
.icon-link-bold:before {
  content: "\ea0a";
}
.icon-add-folder:before {
  content: "\e9b6";
}
.icon-add-member:before {
  content: "\e900";
}
.icon-alert:before {
  content: "\e904";
}
.icon-arrow-left:before {
  content: "\e905";
}
.icon-arrow-right:before {
  content: "\e906";
}
.icon-attachment:before {
  content: "\e907";
}
.icon-back:before {
  content: "\e909";
}
.icon-prev:before {
  content: "\e909";
}
.icon-back-circle-fill:before {
  content: "\e908";
}
.icon-badge:before {
  content: "\e9ff";
}
.icon-bio:before {
  content: "\e9d4";
}
.icon-board:before {
  content: "\e9a9";
}
.icon-boards:before {
  content: "\e90a";
}
.icon-boards-bold:before {
  content: "\e901";
}
.icon-bookmark:before {
  content: "\ea00";
}
.icon-build:before {
  content: "\e90c";
}
.icon-c:before {
  content: "\ea03";
}
.icon-calendar:before {
  content: "\e90d";
}
.icon-calendar-bold:before {
  content: "\e924";
}
.icon-card:before {
  content: "\e93a";
}
.icon-chat:before {
  content: "\e90e";
}
.icon-chat-bold:before {
  content: "\e937";
}
.icon-chat-ellipsis:before {
  content: "\ea02";
}
.icon-chat-emoji:before {
  content: "\e998";
}
.icon-check:before {
  content: "\e911";
}
.icon-check-bold:before {
  content: "\e9a0";
}
.icon-check-circle:before {
  content: "\e910";
}
.icon-check-circle-fill:before {
  content: "\e90f";
}
.icon-check-circle-fill-bold:before {
  content: "\e988";
}
.icon-check-thick:before {
  content: "\e9fd";
}
.icon-checkbox:before {
  content: "\e913";
}
.icon-checkbox-fill:before {
  content: "\e912";
}
.icon-circle:before {
  content: "\e915";
}
.icon-circle-bold:before {
  content: "\e9ce";
}
.icon-circle-fill:before {
  content: "\e914";
}
.icon-clone:before {
  content: "\e9c2";
}
.icon-close:before {
  content: "\e916";
}
.icon-code:before {
  content: "\e918";
}
.icon-code-bold:before {
  content: "\e902";
}
.icon-code-box:before {
  content: "\e917";
}
.icon-collapsedown:before {
  content: "\e919";
}
.icon-comment:before {
  content: "\e91a";
}
.icon-cpp:before {
  content: "\e9bb";
}
.icon-cross:before {
  content: "\e91f";
}
.icon-cross-bold:before {
  content: "\e9d9";
}
.icon-cross-circle:before {
  content: "\e91c";
}
.icon-cross-circle-fill:before {
  content: "\e91b";
}
.icon-cross-rounded:before {
  content: "\e91d";
}
.icon-cross-thick:before {
  content: "\e91e";
}
.icon-curriculum-bold:before {
  content: "\e9da";
}
.icon-delete:before {
  content: "\e920";
}
.icon-disable:before {
  content: "\e921";
}
.icon-discount:before {
  content: "\e922";
}
.icon-dismiss-keyboard:before {
  content: "\e9cc";
}
.icon-down:before {
  content: "\e923";
}
.icon-download:before {
  content: "\e9db";
}
.icon-download-02:before {
  content: "\e9dd";
}
.icon-download-03:before {
  content: "\e9dc";
}
.icon-download-bold:before {
  content: "\ea08";
}
.icon-dropdown-folded:before {
  content: "\e9d8";
}
.icon-dropdown-unfolded:before {
  content: "\e9d7";
}
.icon-duplicate:before {
  content: "\e9b5";
}
.icon-edit:before {
  content: "\e925";
}
.icon-editorview:before {
  content: "\e926";
}
.icon-email:before {
  content: "\e927";
}
.icon-envelope:before {
  content: "\e9de";
}
.icon-message:before {
  content: "\e9de";
}
.icon-exercise:before {
  content: "\e928";
}
.icon-exit:before {
  content: "\e929";
}
.icon-expand:before {
  content: "\e92a";
}
.icon-expandmode:before {
  content: "\e92b";
}
.icon-expandup:before {
  content: "\e92c";
}
.icon-facebook:before {
  content: "\e92d";
}
.icon-social-facebook-fill:before {
  content: "\e92d";
}
.icon-favicon:before {
  content: "\e9df";
}
.icon-elice-favicon:before {
  content: "\e9df";
}
.icon-file:before {
  content: "\e9b7";
}
.icon-file-cancel:before {
  content: "\e92e";
}
.icon-file-check:before {
  content: "\e92f";
}
.icon-files:before {
  content: "\e930";
}
.icon-filter:before {
  content: "\e9aa";
}
.icon-flag:before {
  content: "\ea01";
}
.icon-folded:before {
  content: "\e932";
}
.icon-folded-narrow:before {
  content: "\e931";
}
.icon-fullscreen1:before {
  content: "\e9e1";
}
.icon-fullscreen2:before {
  content: "\e9e2";
}
.icon-fullscreenmode:before {
  content: "\e9e0";
}
.icon-fullview:before {
  content: "\e934";
}
.icon-google:before {
  content: "\e935";
}
.icon-social-google:before {
  content: "\e935";
}
.icon-groups:before {
  content: "\e936";
}
.icon-groups-bold:before {
  content: "\e9e3";
}
.icon-halfmode:before {
  content: "\e9e4";
}
.icon-heart:before {
  content: "\e938";
}
.icon-heart-fill:before {
  content: "\e9c8";
}
.icon-help:before {
  content: "\e9e7";
}
.icon-tips:before {
  content: "\e9e7";
}
.icon-help-center:before {
  content: "\e9e5";
}
.icon-help-center-thin:before {
  content: "\e9e5";
}
.icon-help-request:before {
  content: "\e9e6";
}
.icon-hide:before {
  content: "\e9c6";
}
.icon-history:before {
  content: "\e93b";
}
.icon-home:before {
  content: "\e93c";
}
.icon-html:before {
  content: "\e9bc";
}
.icon-htmlcss:before {
  content: "\e9bd";
}
.icon-image:before {
  content: "\e93d";
}
.icon-indent-left:before {
  content: "\e903";
}
.icon-indent-right:before {
  content: "\e933";
}
.icon-info:before {
  content: "\e9b0";
}
.icon-info-fill:before {
  content: "\e9c4";
}
.icon-instagram:before {
  content: "\e9ab";
}
.icon-java:before {
  content: "\e9be";
}
.icon-js:before {
  content: "\e9bf";
}
.icon-kaist:before {
  content: "\e93e";
}
.icon-kakao:before {
  content: "\e93f";
}
.icon-social-kakao:before {
  content: "\e93f";
}
.icon-learn:before {
  content: "\e940";
}
.icon-lecture:before {
  content: "\e941";
}
.icon-lectureview:before {
  content: "\e942";
}
.icon-left-pane:before {
  content: "\e943";
}
.icon-level:before {
  content: "\e9e9";
}
.icon-level-bold:before {
  content: "\e9e8";
}
.icon-link:before {
  content: "\e944";
}
.icon-list:before {
  content: "\e9ea";
}
.icon-list-bold:before {
  content: "\e9ea";
}
.icon-list-bullet:before {
  content: "\e9fc";
}
.icon-list-compact:before {
  content: "\e9d5";
}
.icon-list-compact-bold:before {
  content: "\e9ee";
}
.icon-list-wide:before {
  content: "\e9fa";
}
.icon-list-wide-bold:before {
  content: "\e9fb";
}
.icon-live:before {
  content: "\e9eb";
}
.icon-live-bold:before {
  content: "\e9ec";
}
.icon-live-lecture:before {
  content: "\e946";
}
.icon-loading:before {
  content: "\e948";
}
.icon-lock:before {
  content: "\e94a";
}
.icon-lock-thick:before {
  content: "\e949";
}
.icon-medium:before {
  content: "\e9b1";
}
.icon-minus:before {
  content: "\e94e";
}
.icon-minus-circle:before {
  content: "\e94d";
}
.icon-minus-circle-fill:before {
  content: "\e94c";
}
.icon-more:before {
  content: "\e94f";
}
.icon-more-vertical:before {
  content: "\e94f";
}
.icon-more1:before {
  content: "\e9c9";
}
.icon-more-horizontal:before {
  content: "\e9c9";
}
.icon-move:before {
  content: "\e950";
}
.icon-naverblog:before {
  content: "\e9b2";
}
.icon-social-naverblog:before {
  content: "\e9b2";
}
.icon-new:before {
  content: "\e9ca";
}
.icon-newwindow:before {
  content: "\e951";
}
.icon-next:before {
  content: "\e954";
}
.icon-next-fill:before {
  content: "\e952";
}
.icon-next-thin:before {
  content: "\e953";
}
.icon-notebook:before {
  content: "\e956";
}
.icon-notebook-thin:before {
  content: "\e955";
}
.icon-noti:before {
  content: "\e957";
}
.icon-noti-off-fill:before {
  content: "\e94b";
}
.icon-notice:before {
  content: "\e9d6";
}
.icon-openmessage:before {
  content: "\e958";
}
.icon-outputview:before {
  content: "\e959";
}
.icon-passbook:before {
  content: "\e945";
}
.icon-password:before {
  content: "\e95a";
}
.icon-pin:before {
  content: "\e9ac";
}
.icon-pin-fill:before {
  content: "\e9d3";
}
.icon-plus:before {
  content: "\e95d";
}
.icon-plus-bold:before {
  content: "\e9ed";
}
.icon-plus-circle:before {
  content: "\e95c";
}
.icon-plus-circle-fill:before {
  content: "\e95b";
}
.icon-plus-thick:before {
  content: "\e9ad";
}
.icon-popout:before {
  content: "\e95e";
}
.icon-power:before {
  content: "\e95f";
}
.icon-preferences:before {
  content: "\e960";
}
.icon-prev-fill:before {
  content: "\e961";
}
.icon-prev-thin:before {
  content: "\e962";
}
.icon-profile:before {
  content: "\e99f";
}
.icon-progress:before {
  content: "\e963";
}
.icon-python:before {
  content: "\e9c0";
}
.icon-qna-advanced:before {
  content: "\e965";
}
.icon-qna-advanced-hover:before {
  content: "\e964";
}
.icon-qna-advtheory:before {
  content: "\e967";
}
.icon-qna-advtheory-hover:before {
  content: "\e966";
}
.icon-qna-ai:before {
  content: "\e969";
}
.icon-qna-ai-hover:before {
  content: "\e968";
}
.icon-qna-applydata:before {
  content: "\e96b";
}
.icon-qna-applydata-hover:before {
  content: "\e96a";
}
.icon-qna-beginner:before {
  content: "\e96d";
}
.icon-qna-beginner-hover:before {
  content: "\e96c";
}
.icon-qna-bigdata:before {
  content: "\e96f";
}
.icon-qna-bigdata-hover:before {
  content: "\e96e";
}
.icon-qna-book:before {
  content: "\e971";
}
.icon-qna-book-hover:before {
  content: "\e970";
}
.icon-qna-datascience:before {
  content: "\e973";
}
.icon-qna-datascience-hover:before {
  content: "\e972";
}
.icon-qna-datavis:before {
  content: "\e975";
}
.icon-qna-datavis-hover:before {
  content: "\e974";
}
.icon-qna-developer:before {
  content: "\e977";
}
.icon-qna-developer-hover:before {
  content: "\e976";
}
.icon-qna-expert:before {
  content: "\e979";
}
.icon-qna-expert-hover:before {
  content: "\e978";
}
.icon-qna-fromadvanced:before {
  content: "\e97b";
}
.icon-qna-fromadvanced-hover:before {
  content: "\e97a";
}
.icon-qna-frombeginner:before {
  content: "\e97d";
}
.icon-qna-frombeginner-hover:before {
  content: "\e97c";
}
.icon-qna-frommid:before {
  content: "\e97f";
}
.icon-qna-frommid-hover:before {
  content: "\e97e";
}
.icon-qna-learn:before {
  content: "\e981";
}
.icon-qna-learn-hover:before {
  content: "\e980";
}
.icon-qna-mid:before {
  content: "\e983";
}
.icon-qna-mid-hover:before {
  content: "\e982";
}
.icon-qna-nlp:before {
  content: "\e985";
}
.icon-qna-nlp-hover:before {
  content: "\e984";
}
.icon-qna-web:before {
  content: "\e987";
}
.icon-qna-web-hover:before {
  content: "\e986";
}
.icon-question-bubble:before {
  content: "\e90b";
}
.icon-quit:before {
  content: "\e9ef";
}
.icon-r:before {
  content: "\e9c1";
}
.icon-radio-fill:before {
  content: "\e9cd";
}
.icon-ranking:before {
  content: "\e9ae";
}
.icon-reorder:before {
  content: "\e9b4";
}
.icon-reset:before {
  content: "\e989";
}
.icon-reset-bold:before {
  content: "\e9b9";
}
.icon-retry:before {
  content: "\e9c3";
}
.icon-right-pane:before {
  content: "\e98a";
}
.icon-run:before {
  content: "\e98b";
}
.icon-save:before {
  content: "\e98c";
}
.icon-saveall:before {
  content: "\e98d";
}
.icon-search:before {
  content: "\e98e";
}
.icon-search-bold:before {
  content: "\e9f0";
}
.icon-search-thin:before {
  content: "\e9f1";
}
.icon-send:before {
  content: "\e9d2";
}
.icon-settings:before {
  content: "\e98f";
}
.icon-share:before {
  content: "\e990";
}
.icon-social-facebook:before {
  content: "\e9cf";
}
.icon-social-github:before {
  content: "\e9d0";
}
.icon-social-naver:before {
  content: "\e9d1";
}
.icon-speechbubble:before {
  content: "\e9af";
}
.icon-square:before {
  content: "\e992";
}
.icon-square-fill:before {
  content: "\e991";
}
.icon-star:before {
  content: "\e993";
}
.icon-star-fill:before {
  content: "\e9fe";
}
.icon-stop:before {
  content: "\e994";
}
.icon-submit-list:before {
  content: "\e995";
}
.icon-support:before {
  content: "\e996";
}
.icon-support-fill:before {
  content: "\e9c5";
}
.icon-survey:before {
  content: "\e997";
}
.icon-teach:before {
  content: "\e999";
}
.icon-ta:before {
  content: "\e999";
}
.icon-template:before {
  content: "\e9b8";
}
.icon-terminal:before {
  content: "\e99d";
}
.icon-terminal-box:before {
  content: "\e99c";
}
.icon-terminal-box-fill:before {
  content: "\e99a";
}
.icon-terminal-box-sq:before {
  content: "\e99b";
}
.icon-test:before {
  content: "\e9f2";
}
.icon-text:before {
  content: "\e99e";
}
.icon-theater:before {
  content: "\e9f3";
}
.icon-theatermode:before {
  content: "\e9f3";
}
.icon-time:before {
  content: "\e9cb";
}
.icon-time-bold:before {
  content: "\e9f4";
}
.icon-tooltip:before {
  content: "\e9a1";
}
.icon-quiz:before {
  content: "\e9a1";
}
.icon-track-ai:before {
  content: "\ea04";
}
.icon-track-algorithm:before {
  content: "\ea05";
}
.icon-track-basic:before {
  content: "\ea06";
}
.icon-track-data:before {
  content: "\ea07";
}
.icon-transfer:before {
  content: "\e947";
}
.icon-twitter:before {
  content: "\e9a2";
}
.icon-social-twitter:before {
  content: "\e9a2";
}
.icon-unfolded:before {
  content: "\e9a4";
}
.icon-unfolded-narrow:before {
  content: "\e9a3";
}
.icon-unlock:before {
  content: "\e9a5";
}
.icon-up:before {
  content: "\e9a6";
}
.icon-upload:before {
  content: "\e9ba";
}
.icon-upload-02:before {
  content: "\e9f5";
}
.icon-upload-03:before {
  content: "\e9f6";
}
.icon-upload-file:before {
  content: "\e9f6";
}
.icon-user:before {
  content: "\e9a7";
}
.icon-user-bold:before {
  content: "\e939";
}
.icon-validation-alert-thin:before {
  content: "\e9f7";
}
.icon-alert-thin:before {
  content: "\e9f7";
}
.icon-validation-check-thin:before {
  content: "\e9f8";
}
.icon-alert-circle-check:before {
  content: "\e9f8";
}
.icon-validation-circle-thin:before {
  content: "\e9f9";
}
.icon-alert-circle-thin:before {
  content: "\e9f9";
}
.icon-video:before {
  content: "\e9a8";
}
.icon-view:before {
  content: "\e9c7";
}
.icon-youtube:before {
  content: "\e9b3";
}
