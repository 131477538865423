.eb-drawer {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: color('white');
  width: 20rem;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 2002;
  animation: drawerOpen 0.4s;

  @keyframes drawerOpen {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  &__shade {
    position: fixed;
    top: 0;
    left: 0;
    background-color: color('black');
    opacity: 0.5;
    z-index: 980;
    width: 100vw;
    height: 100vh;
  }

  &__header-icon-wrapper {
    background-color: color('purple');
    padding: 1.75rem;
    text-align: right;
  }

  &__header-icon {
    color: color('white');
    cursor: pointer;
  }

  &__profile {
    background-color: color('purple');

    &-info-name {
      width: 194px;
    }

    &-info-email {
      width: 194px;
    }
  }

  &__action-group {
    background-color: color('purple-white');
    border-bottom: 1px solid color('purple-bright');

    &--item {
      display: flex;
      align-items: center;
      height: 3.25rem;
      padding: 0 1.5rem;
      text-decoration: none;
    }
  }

  &__list-group {
    &--item {
      display: flex;
      align-items: center;
      height: 3.25rem;
      padding: 0 1.5rem;
      text-decoration: none;
      transition: background-color 0.5s;
      background: none;
      border: none;
      font: inherit;
      outline: none;
      justify-content: space-between;

      &:hover {
        background-color: color('purple-white');
        cursor: pointer;
      }

      &.isActive {
        background-color: color('greyf678');
        border-left: 0.5rem solid color('purple-bright');
      }
    }
  }
}
