@import '../../stylesheets/imports/index';

.eb-user-profile-image {
  border: 1px solid color('greyd');
  background-size: cover;
  background-position: center center;
  overflow: hidden;

  &--round {
    border-radius: 50%;
  }

  &--fill {
    height: 100%;
    width: 100%;
  }

  &:hover &__hover-area {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &--image-select:hover {
    cursor: pointer;
  }

  &__hover-area {
    background-color: color('black');
    width: 100%;
    position: relative;
    top: 70%;
    height: 30%;
    text-align: center;
    transition: all 0.5s ease;
    opacity: 0;
  }

  &__input {
    display: none;
  }

  &__spinner {
    position: relative;
    width: 100%;
    top: -25%;
  }
}
