@import '../../stylesheets/imports/index';

.eb-chapter-header {
  position: relative;
  margin: 0 auto;
  max-width: $standard-max-width;
  min-height: rem(340px);

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    width: rem(440px);
    height: auto;
  }

  &__container {
    padding: rem(16px) rem(440px + 16px) rem(16px) 0;
  }

  &__title {
    padding: rem(8px) 0;
    color: color('grey2');
    font-size: rem(32px);
    font-weight: $bold;
  }

  &__subtitle {
    color: color('grey9');
    font-size: rem(24px);
  }

  &__message {
    padding: rem(48px) 0;
    color: color('grey2');
    font-size: rem(20px);
    line-height: 1.5em;
  }

  &__button-row {
    padding: rem(8px) 0;
  }

  &--size-small {
    min-height: rem(190px);

    .eb-chapter-header {
      &__title {
        font-size: rem(24px);
      }

      &__subtitle {
        font-size: rem(18px);
      }

      &__message {
        padding: rem(32px) 0;
        font-size: rem(16px);
      }
    }
  }
}

@media (max-width: $media-lg-width) {
  .eb-chapter-header {
    &__image {
      width: rem(320px);
    }

    &__container {
      padding: rem(16px) rem(320px + 16px) rem(16px) 0;
    }
  }
}

@media (max-width: $media-sm-width) {
  .eb-chapter-header {
    &__image {
      display: block;
      position: relative;
      margin: 0 auto;
      top: auto;
      right: auto;
      width: auto;
      height: rem(320px);
    }

    &__container {
      padding: rem(24px) rem(16px);
    }

    &__title {
      font-size: rem(28px);
      text-align: center;
    }

    &__subtitle {
      font-size: rem(18px);
      text-align: center;
    }

    &__message {
      font-size: rem(16px);
      text-align: center;
    }

    &__button-row {
      text-align: center;
    }

    &--size-small {
      .eb-chapter-header {
        &__title {
          font-size: rem(18px);
        }

        &__subtitle {
          font-size: rem(16px);
        }

        &__message {
          font-size: rem(14px);
        }
      }
    }
  }
}
