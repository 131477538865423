@import '../../stylesheets/imports/index';

.ant-notification {
  // &-notice-content {
  //   padding: 1rem 1.5rem;
  // }

  &-info .anticon,
  &-loading .anticon {
    color: color('purple');
  }

  &-success .anticon {
    color: color('lime');
  }

  &-error .anticon {
    color: color('red');
  }

  &-warning .anticon {
    color: color('yellow');
  }
}
