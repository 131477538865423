@import '../../stylesheets/imports/index';

.eb-local-tab-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  text-align: center;
  height: rem(40px);

  &--is-not-block {
    display: inline-block;
  }

  &__filler {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    border-left: 1px solid color('greyc');
    border-bottom: 1px solid color('greyc');
    height: rem(40px);
  }
}

.eb-local-tab-button {
  min-width: rem(135px);
  padding: 0 rem(16px);
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  border-left: 1px solid color('greyc');
  border-top: 1px solid color('greyc');
  border-bottom: 1px solid color('greyc');
  height: rem(40px);
  text-align: center;
  font-size: rem(14px);
  line-height: rem(40px);
  color: color('grey9');
  cursor: pointer;

  &:hover {
    color: color('grey2');
  }

  &--is-active {
    color: color('grey2');
    border-bottom: 1px solid transparent;

    &:hover {
      color: color('grey2');
      border-bottom: 1px solid transparent;
    }
  }
}
