@import '../../stylesheets/imports/index';

.eb-files {
  &__drop-box {
    padding: 30px 24px;
    background-color: color('white');
    border: 1px dashed color('greyc');
    font-size: 14px;
    color: color('grey9');
    text-align: center;

    &--is-active {
      border: 1px dashed color('grey2');
      color: color('grey2');
    }
  }

  &__choose-button-wrapper {
    margin-top: 16px;
  }

  &__choose-button {
    border-color: color('grey9');
    background-color: color('grey9');
    &.eb-button--active:hover {
      border-color: color('grey6');
      background-color: color('grey6');
    }
  }

  &__box-wrapper {
    padding-bottom: 24px;

    &:last-of-type {
      padding: 0;
    }
  }

  &__file-list {
    border: 1px solid color('greyd');
    padding-left: 1px;
  }

  &__file-item {
    position: relative;
    padding: 7px 44px 5px 20px;
    font-size: 12px;
    text-align: left;

    &:nth-child(odd) {
      background-color: color('greyfb');
    }

    &:nth-child(even) {
      background-color: color('white');
    }

    &:not(:first-of-type) {
      border-top: 1px solid color('greyd');
    }
  }

  &__file-item-text {
    font-size: 12px;
    line-height: 22px;
  }

  &__file-item-remove-icon {
    position: absolute;
    padding: 8px 20px;
    top: 0;
    right: 0;
    font-size: 16px;
    line-height: 22px;
    color: color('greyc');
    cursor: pointer;

    &:hover {
      color: color('grey2');
    }
  }

  &__explicit-input {
    display: none;
  }
}
