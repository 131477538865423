@import '../../stylesheets/imports/index';

.eb-strip {
  display: flex;
  justify-content: space-between;
  border-radius: 0.125rem;
  background-color: color('purple-black');
  padding: 1rem 1.5rem;
  align-items: center;

  @media #{$mobile} {
    flex-direction: column;
  }

  &__left {
    display: flex;

    @media #{$mobile} {
      width: 100%;
    }
  }

  &__right {
    @media #{$mobile} {
      margin-top: 1rem;
      width: 100%;
    }
  }
}

.eb-strip {
  &__info-item {
    margin-right: 3rem;

    &:last-child {
      margin-right: 0rem;
    }
  }
}
