@import '../../stylesheets/imports/index';

.eb-quiz-dialog {
  position: fixed;
  top: 50%;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  z-index: 981;
  transition: all 0.3s ease;
  word-break: keep-all;
  margin-top: -15.25rem;

  &__content {
    display: inline-block;
    background-color: #24242f;
    margin: auto;
    border-radius: 6px;
    z-index: 982;
    text-align: left;
    cursor: default;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.3);
    height: 30.5rem;
    width: 25rem;
  }

  &__head-row {
    position: relative;
    padding: rem(24px);
    height: rem(32px);
  }

  &__close-button {
    position: absolute;
    top: rem(24px);
    right: rem(24px);
    cursor: pointer;

    i {
      color: color('grey9');
      font-weight: bold;
      font-size: rem(20px);
    }
  }

  &__animation-row {
    height: rem(240px);
    padding: rem(20px);
    text-align: center;
  }

  &__text-row {
    display: block;
    line-height: rem(24px);
    font-size: rem(16px);
    padding: rem(8px);
    white-space: pre-wrap;

    &-title {
      color: color('white');
      font-size: rem(24px);
      font-weight: bold;
      line-height: rem(24px);
      text-align: center;
      margin-bottom: rem(12px);
    }

    &-sub-title {
      opacity: 0.5;
      color: color('white');
      font-size: 1rem;
      line-height: 1rem;
      text-align: center;
    }
  }

  &__button-row {
    padding: 2.5rem;
  }

  &__button-row button {
    color: color('white');
    cursor: pointer;
    display: block;
    height: 3rem;
    width: 100%;
    margin: 0 6px;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    outline: none;
    border: 0;
    transition: all 0.3s ease;
  }

  &__button-row-info {
    background-color: color('purple-light');
  }

  &__button-row-danger {
    background-color: color('red');
  }

  &__button-row &__button-row-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.eb-quiz-dialog--mobile {
  .eb-quiz-dialog {
    &__content {
      width: rem(335px);
    }

    &__close-button {
      display: none;
    }

    &__text {
      line-height: rem(20px);
      font-size: rem(14px);
      padding: 0 rem(32px) rem(24px) rem(32px);
      white-space: pre-wrap;
    }

    &__button-row {
      padding: 0 rem(32px) rem(16px) rem(32px);
    }

    &__button-row button {
      padding: rem(8px) rem(16px);
      font-size: rem(14px);
      line-height: rem(16px);
    }
  }
}

.eb-quiz-dialog-shade {
  position: fixed;
  top: 0;
  left: 0;
  background-color: color('black');
  opacity: 0.5;
  z-index: 980;
  width: 100vw;
  height: 100vh;
}
