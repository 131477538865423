@import '../../stylesheets/imports/index';

$modal-title-height: 64px;
$emoji-mart-bar-height: 45px;
$emoji-mart-search-height: 31px;

// Picker wrapper
.eb-emoji-picker--wrapper {
  position: relative;
}

// Picker toggle button
.eb-emoji-picker--toggle {
  margin: 0;
  padding: 0;
  border: none;
  background: none;

  &:active,
  &:focus {
    outline: none;
  }
}

// Picker
.eb-emoji-picker {
  text-align: left;
  overflow: hidden;

  // Picker popover
  &.eb-emoji-picker--popover {
    position: absolute;
    z-index: 900;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  // Picker modal
  &.eb-emoji-picker--modal {
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh !important;

    // Modal inner
    .eb-modal__body {
      padding: 0;
    }

    // Emoji mart
    .eb-emoji-picker--inner {
      width: 100%;
      height: 100%;

      .emoji-mart {
        width: 100% !important;
        border: none !important;
        border-radius: 0 !important;

        .emoji-mart-scroll {
          @media #{$tabletOrSmaller} {
            height: calc(
              100vh - #{$modal-title-height} - #{$emoji-mart-bar-height} - #{$emoji-mart-search-height}
            );
          }
        }
      }
    }
  }
}
