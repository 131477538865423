@import '../../stylesheets/imports/index';

.eb-user-profile-header {
  background-color: color('purple-black');

  &__main {
    @include contentLayout;
  }

  &__details {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
  }
}
