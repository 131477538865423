@import '../../stylesheets/imports/index';

.eb-course-card-list {
  max-width: 100%;

  &__notice-banner {
    display: flex;
    width: 100%;
    padding-bottom: 1rem;
  }

  &__title {
    &__link {
      display: block;

      .icon-next-fill {
        opacity: 0.3;
        transition: all 0.2s;
      }

      &:hover {
        .icon-next-fill {
          opacity: 1;
          padding-left: 1rem;
        }
      }
    }

    &__link-none {
      cursor: default;
    }
  }

  &__subtitle {
    white-space: pre-wrap;
    word-break: keep-all;
    line-height: 1.5;
  }

  &__feature {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__wrapper {
      line-height: 1;
    }

    &--icon {
      background-color: color('purple');
      color: color('white');
      border-radius: 50%;
    }
  }

  &__body {
    &--empty {
      height: 178px;
      border: 1px solid #cccccc;
      display: flex;

      &--wrapper {
        display: flex;
        flex-direction: column;
        margin: auto;
        align-items: center;
        text-align: center;
        padding: 1rem;
      }
    }
  }

  .ant-list-header {
    padding-top: 0;
    padding-bottom: 0.5rem;
  }

  &__item {
    &__track {
      width: 100%;

      @media #{$desktop} {
        background-size: contain;
        width: 25rem;
        flex: 0 0 25rem;
      }

      @media #{$laptop} {
        background-size: contain;
        width: 25rem;
        flex: 0 0 25rem;
      }
    }

    &--wrapper {
      position: relative;
    }
  }

  &__arrow {
    cursor: pointer;
    background-color: color('greyc');
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    margin-top: -1.5rem;
    position: absolute;
    top: 50%;
    overflow: hidden;

    &--previous {
      left: -1.5rem;
      margin-left: 1rem;

      @media #{$desktop} {
        margin-left: 0.5rem;
      }
    }

    &--next {
      right: -1.5rem;
      margin-right: 1rem;

      @media #{$desktop} {
        margin-right: 0.5rem;
      }
    }

    .eb-text {
      height: 3rem;
      width: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__type-default {
    @media #{$mobile} {
      flex-direction: column;
      flex-wrap: initial;
    }

    .eb-course-card-list__item__course {
      @media #{$mobile} {
        width: 100%;
      }
    }
  }

  &__type-metro {
    .eb-course-card-list__item--wrapper {
      flex-wrap: wrap;
    }

    .eb-course-card-list__item__course {
      width: 50% !important;

      @media #{$mobile} {
        width: 100% !important;
      }
    }
  }

  &__type-grid {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media #{$desktop} {
      width: $container-xl-width;
    }

    @media #{$laptop} {
      width: $container-lg-width;
    }
    @media #{$tablet} {
      width: $container-md-width;
    }
    @media #{$mobile} {
      width: $container-sm-width;
    }

    .eb-course-card-list__item__track,
    .eb-course-card-list__item__course,
    .eb-course-card-list__item__default {
      @media #{desktop} {
        width: 25rem;
        flex: 0 0 25rem;
      }

      @media #{$laptop} {
        width: 18.5rem;
        flex: 0 0 18.5rem;
      }

      @media #{$tablet} {
        width: 50%;
        flex: 0 0 50%;
        margin: 0;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }

    .eb-track-card,
    .eb-course-card {
      @media #{$tabletOrSmaller} {
        width: 100%;
      }
    }
  }

  &__type-fade {
    .eb-course-card-list__arrow--previous {
      margin-right: 0;
    }

    .eb-course-card-list__arrow--next {
      margin-left: 0;
    }
  }

  &__type-scroll {
    &__list {
      position: relative;
    }

    &__list-content {
      position: relative;
    }

    .eb-course-card-list__subtitle {
      font-size: 1.25rem;
    }

    .eb-course-card-list__item {
      margin: 0 0.5rem;
    }

    .eb-course-card-list__scroll__list-content {
      @media #{$mobile} {
        align-items: stretch;
      }
    }

    .eb-horizontal-scroll-handler__hider {
      cursor: pointer;
      border-radius: 50%;
      color: #fff;
      display: block;
      margin-top: -1.5rem;
      position: absolute;
      top: 50%;
      overflow: hidden;
      background-color: color('greyc');
      border-radius: 50%;
      height: 3rem;
      width: 3rem;
      z-index: 10;

      @media #{$mobile} {
        display: none;
      }

      &--prev {
        left: -1.5rem;
      }

      &--next {
        right: -1.5rem;
      }

      &:hover {
        background-color: color('grey9');
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      }

      [class*='icon-'] {
        color: color('white');
        height: 3rem;
        width: 3rem;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__type-landing {
    .eb-course-card-list__subtitle {
      font-size: 1.25rem;
    }

    .eb-course-card-list__head--title__link {
      cursor: pointer;
    }
  }
}

.eb-course-card-list__item__track,
.eb-course-card-list__item__course,
.eb-course-card-list__item__default {
  width: 100%;

  &.eb-course-card-list__item__column {
    width: auto;

    @media #{$tabletOrSmaller} {
      width: 100%;
    }
  }
}
