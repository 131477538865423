@import '../../stylesheets/imports/index';

.eb-help-text {
  display: inline-block;
  text-align: left;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  color: color('grey6');

  &--block {
    display: block;
  }

  &--error {
    color: color('red');
  }

  &--warning {
  }

  &--success {
    color: color('lime');
  }

  &__icon {
    float: left;
    margin-top: 2.5px;
  }

  &__icon + &__description {
    white-space: pre-wrap;
    padding-left: 16px;
  }
}
