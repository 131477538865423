@import '../../stylesheets/imports/index';

.eb-ant-radio {
  font-size: 16px;
  &.ant-radio-wrapper-checked {
    color: color('purple');
    .ant-radio-checked {
      .ant-radio-inner {
        border-color: color('purple');
        &:after {
          background-color: color('purple');
        }
      }
    }
  }
  .ant-radio {
    &:hover .ant-radio-inner {
      border-color: color('purple');
    }
  }

  .ant-radio-focused {
    .ant-radio-inner {
      border-color: color('purple');
    }
  }

  .ant-radio-wrapper {
    &:hover {
      .ant-radio .ant-radio-innner {
        border-color: color('purple');
      }
    }
  }
  .ant-radio-focus .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner {
    border-color: color('purple');
  }
}

.ant-radio-inner:after {
  background-color: color('purple');
}
