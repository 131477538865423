@import '../../stylesheets/imports/index';

.eb-ant-auto-complete {
  display: flex;
  align-items: stretch;

  .ant-select-selection__clear {
    opacity: 1;
    right: 1rem;
  }

  &--size-small {
    &.ant-select-auto-complete {
      font-size: 14px;
      .ant-input {
        height: 40px;
      }
    }
  }

  .ant-select-selection {
    width: 100%;

    &:hover {
      border-color: color('purple');
    }
    border-radius: 0;
  }

  &.ant-select-focused {
    .ant-select-selection {
      border-color: color('purple');
      box-shadow: none;
    }
  }

  &.ant-select-open {
    .ant-select-selection {
      border-color: color('purple');
      box-shadow: none;
    }
  }

  .ant-select-selection__choice {
    max-width: 200px;
  }
}

.ant-select-dropdown-menu {
  margin: 0;
}

.ant-select-dropdown-menu-item {
  &:hover {
    background-color: color('purple-white');
  }

  &-selected {
    &:after {
      color: color('purple-white');
    }
  }
}

.ant-select-dropdown-menu-item-active {
  background-color: color('purple-white');
}
