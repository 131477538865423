@import '../../stylesheets/imports/index';

.eb-track-card {
  border: 1px solid color('gray2');
  border-radius: 8px;
  background-color: #fff;
  // box-shadow: 2px 2px 4px color('greyd');
  cursor: pointer;
  overflow: hidden;
  // transition: all 0.15s;

  &__type-of-default {
    width: 38rem;
    display: flex;

    @media #{$laptopOrSmaller} {
      display: block;
      width: 100%;
    }

    .eb-track-card {
      &__logo {
        min-width: 212px;

        @media #{$laptopOrSmaller} {
          min-width: 100%;
          height: 228px;
        }
      }
    }
  }

  &__type-of-course {
    width: 100%;
    display: block;
    // border: 0;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);

    @media #{$desktop} {
      width: 25rem;
    }
  }

  &__type-of-timeline {
    width: 100%;
    display: block;
    // border: 0;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);

    @media #{$desktop} {
      width: 25rem;
    }
  }

  &__type-of-spread {
    width: 100%;
    height: rem(282px);
    display: block;

    @media #{$tabletOrSmaller} {
      height: auto;
    }

    .eb-track-card__track__header {
      height: 4.5rem;
    }

    .eb-track-card__track__header__content {
      display: flex;
      // align-items: flex-end;
      flex-direction: row;
      padding-top: 1.5rem;
      padding-left: 2rem;
    }
    .eb-track-card__logo {
      width: rem(88px);
      height: rem(88px);
      margin-right: 0.5rem;
    }
    .eb-track-card__badges {
      height: rem(88px);
      margin-top: 0;
      align-items: flex-end;
    }
    .eb-track-card__logo--icon {
      font-size: 3rem;
    }

    .eb-track-card__language {
      height: 4.5rem;
      position: absolute;
      top: 0;
      right: 0;

      margin-top: 0;
      margin-right: 2rem;

      &--item {
        margin-left: 0.5rem;
        margin-right: 0;
      }
    }

    .eb-track-card__course--wrapper {
      @media #{$tabletOrSmaller} {
        padding-top: 0;
      }
    }

    .eb-track-card__content {
      height: rem(210px);

      @media #{$tabletOrSmaller} {
        display: block;
        height: auto;
      }
    }
    .eb-track-card__body {
      padding-top: 3.5rem;
      padding-left: 2rem;
      padding-right: 1rem;
      flex: 1 1 0%;
    }
    .eb-track-card__title {
      height: rem(30px);
      line-height: rem(30px);
    }
    .eb-track-card__subtitle {
      height: rem(60px);
    }
    .eb-track-card__description--wrapper {
      padding-top: 0.5rem;
    }
  }

  &__logo {
    width: rem(64px);
    height: rem(64px);
    position: relative;
    border-radius: 0.5rem;
    border: 1px solid color('gray2');
    background-color: color('greyfb');
    overflow: hidden;

    &--icon {
      font-size: 2rem;
      color: color('black');
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__body {
    width: 100%;
    background-color: #fff;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &__title {
    width: 100%;
    height: rem(30px);
    line-height: rem(30px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__details {
    padding-top: 0.5rem;
  }

  &__subtitle {
    word-break: keep-all;
    height: 2rem;
    line-height: 1.5;
    text-align: left;
  }

  &__description--wrapper {
    width: 100%;
    padding-top: 1rem;
  }

  &__description {
    margin-right: 2rem;

    &__icon {
      flex: 0 0 0.75rem;
      padding-right: 0.5rem;
    }
  }

  &__badges {
    margin-top: 0.5rem;

    &--item {
      margin-right: 0.5rem;
    }
  }

  &__language {
    margin-top: 1rem;

    &--item {
      margin-left: 0.5rem;
    }

    :first-child {
      margin-left: 0;
    }
  }

  &__track__header {
    height: 11rem;
    position: relative;

    &__content {
      position: relative;
      height: 100%;
      padding: 1rem 1rem 1.5rem 1rem;
      z-index: 9;

      .eb-track-card__language {
        margin-top: auto;
      }
    }

    &__background {
      width: 100%;
      height: 6.25rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;

      @media #{$laptopOrLarger} {
        background-size: contain;
      }

      @media #{$tabletOrSmaller} {
        background-size: 100%;
      }
    }

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.6;
      z-index: 1;
    }
  }

  &__track__language {
    height: 100%;
    position: relative;
    z-index: 9;
    padding: 0 2rem;

    &__more {
      color: color('white');
      height: 2rem;
      font-size: 14px;
      font-weight: bold;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid color('white');
      border-radius: 100%;
      background-color: rgba(34, 34, 34, 0.2);
    }
  }

  // &__description {
  //   margin-bottom: 1rem;

  //   &:last-child {
  //     margin-bottom: 0;
  //   }
  // }

  &__anchor {
    text-decoration: none;
  }

  &__timeline {
    min-height: 1.25rem;
  }

  &__period {
    @media #{$mobile} {
      display: block;
    }
  }

  &__course--wrapper {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 2rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    @media #{$tabletOrSmaller} {
      padding-left: 2rem;
    }

    @media #{$mobile} {
      flex-wrap: wrap;
      padding-top: 0;
    }
  }

  &__course {
    width: 8.25rem;
    margin: 0 0.5rem;

    @media #{$mobile} {
      width: 100%;
      max-width: 50%;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin: 0.5rem 0;
      flex: 0 0 50%;
    }
  }

  &__course__inner {
    border: 1px solid color('gray2');
    border-radius: 4px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;

    @media #{$mobile} {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__course-more {
    width: 8.25rem;

    @media #{$mobile} {
      width: 100%;
      max-width: 50%;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin: 0.5rem 0;
      flex: 0 0 50%;
    }

    &__inner {
      background-color: color('gray1');
      border-radius: 4px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__icon {
      background-color: color('white');
      height: 3rem;
      line-height: 3rem;
      width: 3rem;
      border-radius: 50%;
      display: block;
      text-align: center;
    }
  }
}
