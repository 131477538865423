@import '../../stylesheets/imports/index';

.eb-textarea {
  display: block;
  font-family: inherit;
  border: 1px solid color('greyd');
  padding: rem(16px);
  width: 100%;
  height: 100%;
  resize: none;
  color: color('grey2');
  line-height: 1.4;
  outline: none;
  max-height: 165px;
  $placeholder-color: color('grey9');

  &::placeholder {
    color: $placeholder-color;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $placeholder-color;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $placeholder-color;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $placeholder-color !important;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $placeholder-color;
    opacity: 1;
  }

  &:focus {
    border-color: color('purple');
    outline: 0;
  }

  &--disabled {
    color: color('grey9');
    opacity: 0.7;
  }

  &--invalid,
  &--invalid:focus {
    border-color: color('red');
  }

  &--has-button {
    padding-right: rem(60px);
  }

  &--size-small {
    font-size: rem(14px);
  }

  &--size-default {
    font-size: rem(16px);
  }

  &__word-limit {
    display: flex;
    position: absolute;
    top: 3px;
    right: 16px;
    align-items: center;
    color: color('grey9');
    font-size: 12px;

    &--exceeded {
      color: color('red');
    }
  }

  &__button {
    position: absolute;
    right: 16px;
    bottom: 13px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    color: color('purple');

    i {
      font-size: 24px;
    }

    &--disabled {
      color: color('grey9');
      cursor: default;
    }
  }
}

.eb-textarea-container {
  position: relative;
  display: inline-block;
  min-width: rem(140px);

  &--block {
    display: block;
    width: 100%;
  }
}
