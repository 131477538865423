@import '../../stylesheets/imports/index';

.eb-text {
  display: inline-block;

  &--block {
    display: block;
    width: 100%;
  }

  &--hidden {
    transition: opacity 0.5s ease;
    opacity: 0;
    pointer-events: none;
  }

  &--ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--thin {
    font-weight: 300;
  }

  &--bold {
    font-weight: bold;
  }

  &--italic {
    font-style: italic;
  }

  &--underline {
    text-decoration: underline;
  }

  &--line-through {
    text-decoration: line-through;
  }

  &--no-user-select {
    user-select: none;
  }

  &--clickable {
    cursor: pointer;
  }

  &--role-default {
    color: color('grey2');
  }

  &--role-primary {
    color: color('purple');
  }

  &--role-darkblue {
    color: color('darkblue');
  }

  &--role-grey6 {
    color: color('grey6');
  }

  &--role-lightpurple {
    color: color('purple-light');
  }

  &--role-lightgrey {
    color: color('greyc');
  }

  &--role-darkblue {
    color: color('darkblue');
  }

  &--role-important {
    color: color('turq');
  }

  &--role-success {
    color: color('lime');
  }

  &--role-info {
    color: color('grey9');
  }

  &--role-warning {
    color: color('yellow');
  }

  &--role-danger {
    color: color('red');
  }

  &--role-description {
    color: color('grey6');
  }

  &--role-warmwhite {
    color: color('greyfb');
  }

  &--role-white {
    color: color('white');
  }

  &--role-red {
    color: color('red');
  }

  &--role-darkpurple {
    color: color('purple-dark');
  }

  &--role-gray0 {
    color: $color-font-gray-0;
  }

  &--role-gray1 {
    color: $color-font-gray-1;
  }

  &--role-gray2 {
    color: $color-font-gray-2;
  }

  &--role-gray3 {
    color: $color-font-gray-3;
  }

  &--role-gray4 {
    color: $color-font-gray-4;
  }

  &--role-gray5 {
    color: $color-font-gray-5;
  }

  &--role-gray6 {
    color: $color-font-gray-6;
  }

  &--role-gray7 {
    color: $color-font-gray-7;
  }

  &--role-gray8 {
    color: $color-font-gray-8;
  }

  &--role-gray9 {
    color: $color-font-gray-9;
  }

  &--role-transparent {
    color: transparent;
  }

  &--size-tiny {
    font-size: 0.75rem;
  }

  &--size-small {
    font-size: 0.875rem;
  }

  &--size-default {
    font-size: 1rem;
  }

  &--size-medium {
    font-size: 1.125rem;
  }

  &--size-large {
    font-size: 1.25rem;
  }

  &--size-huge {
    font-size: 1.5rem;
  }

  &--size-elephant {
    font-size: 1.75rem;
  }

  &--size-jumbo {
    font-size: 2rem;
  }

  &--size-mega {
    font-size: 2.5rem;
  }

  &--size-giant {
    font-size: 3rem;
  }

  &--size-ultra {
    font-size: 3.5rem;
  }

  &--no-wrap {
    white-space: nowrap;
  }

  &--pre-wrap {
    white-space: pre-wrap;
  }

  &--hover-style-underline {
    &:hover {
      text-decoration: underline;
    }
  }

  &--hover-style-white {
    &:hover {
      color: color('white');
    }
  }

  &--hover-style-purple {
    &:hover {
      color: color('purple');
    }
  }

  &--hover-style-box {
    padding: 0.25rem;
    border-radius: 0.25rem;
    background-color: transparent;
    &:hover {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }

  &__icon {
    margin-right: 0.25rem;
    &--no-text {
      margin-right: 0;
    }
    line-height: normal;
  }
}

.eb-text--hoverable:hover {
  text-decoration: underline;
}
