@import '../../stylesheets/imports/index';

$radio-size: 1.125rem;
$radio-padding-aside-size: 1rem;

.eb-editable-radio {
  display: flex;
  flex-direction: column;
}

.eb-editable-radio-option {
  display: flex;
  margin-bottom: 1rem;
  width: 100%;

  &__input--wrapper--text {
    position: relative;
    width: 100%;
    border: 1px solid color('greyd');
    background: color('white');
  }

  /* HIDE ORIGINAL RADIO INPUT */
  & input[type='radio'] {
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    position: absolute;
  }

  &__input--radio-elem {
    display: inline-block;
    padding: 0.6875rem $radio-padding-aside-size;
    padding-right: 0;
    color: color('greyc');
    font-size: $radio-size;
    line-height: $radio-size;
    vertical-align: middle;
    cursor: pointer;

    &--fill {
      color: color('purple');
    }

    &--disabled {
      color: color('greyc');
    }
  }

  &__label {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 29.125rem;
  }

  &__input--text {
    padding: 0.8125rem 1rem;
    width: calc(100% - #{$radio-padding-aside-size} - #{$radio-size});
    height: 2.5rem;
    border: none;
    color: color('grey2');
    font-size: 0.875rem;
    line-height: 0.875rem;
    vertical-align: middle;
    outline: none;
  }

  &__delete {
    outline: none;
    border: none;
    background: none;
    color: #cccccc;
    font-size: 0.75rem;
    padding: 0.875rem 0.75rem;

    &:hover {
      cursor: pointer;
      color: #524fa1;
    }
  }
}
