@import '../../stylesheets/imports/index';

.eb-form-input-vertical-layout {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  .eb-container__body &:first-child {
    padding-top: 0;
  }

  .eb-container__body &:last-child {
    padding-bottom: 0;
  }

  &__label-wrap {
    padding-bottom: 1rem;
  }

  &__label {
    .eb-label {
      margin: 0;
      padding: 0;
      color: color('gray6');
      font-weight: bold;
      line-height: 1em;
    }
  }

  &__description {
    padding-top: 0.5rem;

    .eb-text {
      margin: 0;
      padding: 0;
      color: color('gray6');
      font-size: 12px;
      line-height: 1em;
    }
  }

  &__input {
    width: 100%;
  }
}
