@import '../../stylesheets/imports/index';

.eb-payment-method-card {
  width: 100%;

  &__card {
    height: 5rem;
    border: 1px solid color('greyd');
    border-right: 0;
    background-color: color('white');
    cursor: pointer;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;

    &:last-child {
      border-right: 1px solid color('greyd');
    }

    &--selected {
      border: 1px solid color('purple');
      border-right: 1px solid color('purple') !important;
      background-color: color('purple-white');
    }

    &:hover {
      border: 1px solid color('purple');
      background-color: color('purple-white');
    }
  }

  &__mobile-card {
    height: 3rem;
    border: 1px solid color('greyd');
    border-bottom: 0;
    background-color: color('white');
    padding-left: 1.5rem;
    cursor: pointer;

    &:last-child {
      border-bottom: 1px solid color('greyd');
    }

    &--selected {
      border: 1px solid color('purple');
      border-bottom: 1px solid color('purple') !important;
      background-color: color('purple-white');
    }

    &:hover {
      border: 1px solid color('purple');
      background-color: color('purple-white');
    }
  }
}
