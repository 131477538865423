@import '../../stylesheets/imports/index';

.eb-educator-card {
  background-color: color('white');
  border: 1px solid color('greyd');
  border-radius: 4px;
  width: 18.5rem;
  height: 24.5rem;
  overflow: hidden;

  &__header {
    background-size: contain;
    background-position: left top;
    text-decoration: none;

    .eb-badge {
      color: color('white');
      font-weight: bold;
    }

    &.type-purple {
      background-color: color('purple');
    }

    &.type-turq {
      background-color: color('turq');
    }
  }

  &__body {
    width: 100%;
    padding-top: 1.25rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &__logo {
    line-height: 1;
    height: 3.74rem;
    position: relative;

    &__image {
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }

    .eb-spinner {
      top: auto;
    }
  }

  &__details {
    width: 100%;

    &__title-shimmer {
      margin: 0 auto 0.5rem;
    }
  }

  &__title {
    text-align: center;
    line-height: normal;
    width: 100%;
    padding-bottom: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__subtitle {
    text-align: center;
    width: 100%;
    height: rem(44px);
    line-height: 1.5;
    white-space: pre-line;
    word-break: keep-all;
  }

  &__careers {
    padding-top: 1.5rem;
    width: 100%;

    .eb-vertical-item-list-input--read-only .eb-vertical-item-list-input__tail {
      top: 0.5rem;
    }

    .eb-vertical-item-list-input__list li {
      line-height: 1;

      &:not(:first-of-type) {
        margin-top: 0.5rem;
      }
    }

    .eb-vertical-item-list-input__value {
      font-weight: normal;
      line-height: normal;
    }
  }
}
