@import '../../../stylesheets/imports/index';

.cm-s-elice-light {
  background-color: color('white');
  color: color('grey6');
}

.cm-s-elice-light .CodeMirror-gutters {
  padding-left: 12px;
  padding-right: 12px;
  background: color('white');
  color: color('white');
  border: 0;
}

.cm-s-elice-light .CodeMirror-guttermarker,
.cm-s-elice-light .CodeMirror-guttermarker-subtle,
.cm-s-elice-light .CodeMirror-linenumber {
  color: color('greyd');
}

.cm-s-elice .CodeMirror-scrollbar-filler {
  background-color: transparent;
}

.cm-s-elice-light .CodeMirror-cursor {
  border-left: 1.5px solid color('grey6');
}

.cm-s-elice-light div.CodeMirror-selected {
  background: color('greye');
}

.cm-s-elice-light.CodeMirror-focused div.CodeMirror-selected {
  background: color('greye');
}

.cm-s-elice-light .CodeMirror-line::selection,
.cm-s-elice-light .CodeMirror-line > span::selection,
.cm-s-elice-light .CodeMirror-line > span > span::selection {
  background: rgba(73, 73, 73, 0.99);
}

.cm-s-elice-light .CodeMirror-line::-moz-selection,
.cm-s-elice-light .CodeMirror-line > span::-moz-selection,
.cm-s-elice-light .CodeMirror-line > span > span::-moz-selection {
  background: rgba(73, 73, 73, 0.99);
}

.cm-s-elice-light .CodeMirror-activeline-background {
  background-color: #202020;
}

.cm-s-elice-light .cm-comment {
  color: color('greyc');
}
.cm-s-elice-light .cm-atom {
  color: color('purple');
}
.cm-s-elice-light .cm-number {
  color: darken(color('yellow'), 6%);
}

.cm-s-elice-light .cm-property {
  color: color('lime');
}
.cm-s-elice-light .cm-attribute {
  color: color('lime');
}
.cm-s-elice-light .cm-keyword {
  color: color('purple');
}
.cm-s-elice-light .cm-builtin {
  color: color('red');
}
.cm-s-elice-light .cm-string {
  color: lighten(color('red'), 12%);
}

.cm-s-elice-light .cm-variable {
  color: color('grey2');
}
.cm-s-elice-light .cm-variable-2 {
  color: color('turq');
}
.cm-s-elice-light .cm-variable-3 {
  color: darken(color('blue'), 4%);
}
.cm-s-elice-light .cm-def {
  color: darken(color('turq'), 4%);
}
.cm-s-elice-light .cm-bracket {
  color: color('purple');
}
.cm-s-elice-light .cm-tag {
  color: color('red');
}
.cm-s-elice-light .cm-header {
  color: color('purple');
}
.cm-s-elice-light .cm-link {
  color: color('purple');
}
.cm-s-elice-light .cm-error {
  background: color('red');
  color: color('greyd');
}

.cm-s-elice-light .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: color('grey2') !important;
}
