@import '../../stylesheets/imports/index';

.eb-content-group {
  display: block;
  padding: 0 0 rem(16px);

  &:last-of-type {
    padding: 0;
  }

  &__title {
    margin-bottom: 1rem;
  }

  &--padding-size-default {
    .eb-content-group__content {
      padding: 0;
    }
  }

  &--padding-size-medium {
    .eb-content-group__content {
      padding: 1.5rem 2rem;
    }
  }

  &--padding-size-large {
    .eb-content-group__content {
      padding: 2rem 2.5rem;
    }
  }

  &--type-bordered {
    .eb-content-group__content {
      border: 1px solid color('greyd');
      background-color: color('white');
    }
  }
}
