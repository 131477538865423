@import '../../stylesheets/imports/index';

.eb-page-layout {
  &--fullheight {
    height: 100%;
  }

  &--column-layout {
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 0;
  }

  &__content {
    padding: 0;
  }

  &__footer {
    padding: 0;
  }
}
