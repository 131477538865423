@import '../../stylesheets/imports/index';

.eb-ant-carousel {
  &__custom-dots {
    .ant-carousel .slick-dots {
      display: flex;
      justify-content: center;
      align-items: center;

      .eb-ant-carousel__total-count {
        color: color('gray3');
      }

      .eb-ant-carousel__dots {
        display: flex;
        align-items: center;
        margin: 0;
      }

      li {
        margin: 0;
        flex: 1 1 0%;

        button {
          width: 100%;
          min-width: 3rem;
        }
      }
    }
  }

  &__custom-arrow--prev {
    border: 0;
    border-radius: 2px 0 0 2px;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    margin: 0;
    padding: 0;
    opacity: 0.3;

    .icon-back {
      color: color('purple');
    }

    &:hover {
      opacity: 1;
    }
  }
  &__custom-arrow--next {
    border: 0;
    border-radius: 0 2px 2px 0;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    margin: 0;
    padding: 0;
    opacity: 0.3;

    .icon-next {
      color: color('purple');
    }

    &:hover {
      opacity: 1;
    }
  }
}
