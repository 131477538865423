@import '../../stylesheets/imports/index';

.eb-pagination {
  display: inline-block;
  text-align: center;
  position: relative;
  height: rem(24px);

  &__icon {
    font-size: rem(14px);
  }

  &__info {
    display: flex;
    // padding: 0 rem(24px);
    height: rem(24px);
    line-height: rem(24px);
  }

  &__page {
    font-size: rem(14px);
    width: rem(24px);
    height: rem(24px);
    line-height: rem(24px);
    text-align: center;
    color: color('grey9');
    margin: 0 rem(6px);
    cursor: default;

    &--current {
      background-color: color('purple');
      width: rem(24px);
      height: rem(24px);
      line-height: rem(24px);
      text-align: center;
      color: color('white');
      transition: background-color 0.5s ease;
    }
  }

  &--page-clickable {
    .eb-pagination__page {
      cursor: pointer;

      &:hover {
        color: color('purple');
        background-color: color('purple-white');
        font-weight: bold;
      }
    }
  }

  &__buttons {
    top: 0;
    right: 0;
    height: 100%;
  }

  &__button {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: rem(24px);
    height: rem(24px);
    line-height: rem(24px);
    text-align: center;
    color: color('grey6');
    transition: background-color 0.5s ease;
    cursor: pointer;

    &-back {
      margin-right: rem(6px);
    }

    &-next {
      margin-left: rem(6px);
    }

    &:hover {
      color: color('purple');
      background-color: color('purple-white');
    }

    &--is-disabled {
      color: color('greyc');
      cursor: not-allowed;

      &:hover {
        // color: color('purple');
        // background-color: color('purple-white');
        color: color('greyc');
        background-color: inherit;
      }
    }
  }
}
