@import '../../stylesheets/imports/index';

.eb-dropdown {
  display: inline-block;
  position: relative;

  &--loading {
    background-color: color('white');
  }

  &--block {
    display: block;
    width: 100%;
  }

  &__inner {
    display: flex;
    align-items: center;
    border: 0;
    margin: 0;
    padding: 0 1rem 0 1rem;
    color: color('grey2');
    cursor: pointer;
    outline: none;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;

    &--fill {
      background-color: color('white');

      &:hover,
      &.eb-dropdown__inner--active {
        background-color: color('purple-white');
      }
    }

    &--border {
      border-radius: 0;
      border: 1px solid color('greyd');
    }

    &--label {
      min-width: 0;
      padding: 0 0.5rem;

      img {
        max-height: 100%;
      }
    }
  }

  &__icon {
    color: black;
  }

  &__drop-icon {
    pointer-events: none;
    margin-left: auto;
    padding-left: 0.75rem;

    &--disabled {
      color: color('greyc');
    }
  }

  &__items {
    position: absolute;
    display: none;
    list-style: none;
    margin: 0.25rem 0 0;
    padding: 0;
    min-width: 100%;
    z-index: 100;
    border: 1px solid color('greyd');
    background-color: color('white');
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);

    &--is-scrollable {
      max-height: 11rem;
      overflow: auto;
    }

    &--flatten {
      box-shadow: none;
      border: 0;
      border-radius: 0;
      margin: 0;
    }

    &--clicked {
      display: none;
    }

    &--active,
    &:not(&--clicked):hover {
      display: block;
    }

    &--align-right {
      right: 0;
    }

    &--placement {
      &-bottomleft {
        left: 0;
        top: 100%;
        right: auto;
        bottom: auto;
      }

      &-bottomright {
        left: auto;
        right: 0;
        top: 100%;
        bottom: auto;
      }

      &-topleft {
        left: 0;
        right: auto;
        top: auto;
        bottom: 100%;
        margin: 0 0 0.25rem;
      }

      &-topright {
        left: auto;
        right: 0;
        top: auto;
        bottom: 100%;
        margin: 0 0 0.25rem;
      }
    }
  }

  &__item {
    padding: 0.5rem 1rem;
    cursor: pointer;
    white-space: nowrap;

    &--active,
    &--active * {
      color: color('purple');
      font-weight: bold;
    }

    &:hover {
      background-color: color('purple-white');
    }

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &--size-tiny {
    height: 2rem;

    .eb-dropdown__inner {
      height: 2rem;
      line-height: 2rem;
      font-size: 0.875rem;
      padding-left: 0.75rem;
      padding-right: 0.5rem;
    }

    .eb-dropdown__item {
      font-size: 0.875rem;
    }
  }

  &--size-small {
    height: 2.5rem;
    .eb-dropdown__inner {
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: 0.875rem;
      padding-left: 1rem;
      padding-right: 0.75rem;
    }

    .eb-dropdown__item {
      font-size: 0.875rem;
    }
  }

  &--size-default {
    height: 3rem;
    .eb-dropdown__inner {
      height: 3rem;
      line-height: 3rem;
      font-size: 1rem;
      padding-left: 1rem;
      padding-right: 0.75rem;
    }

    .eb-dropdown__item {
      font-size: 1rem;
    }
  }

  &--width-auto {
    .eb-dropdown__inner {
      width: inherit;
    }
  }

  &--width-tiny {
    .eb-dropdown__inner {
      min-width: 6rem;
    }
  }
  &--width-small {
    .eb-dropdown__inner {
      min-width: 7.5rem;
    }
  }

  &--width-large {
    .eb-dropdown__inner {
      min-width: 10rem;
    }
  }

  &--width-jumbo {
    .eb-dropdown__inner {
      min-width: 12.5rem;
    }
  }

  &--icon {
    .eb-dropdown__inner {
      min-width: 2rem;
      width: 2rem;
      padding: 0;
      height: 2rem;
      min-height: 2rem;
      justify-content: center;
    }
    .eb-dropdown__item {
      min-width: 7rem;
    }
  }

  &--disabled {
    opacity: 0.5;
    background-color: color('greyd');
    border-color: color('grey9');
    cursor: not-allowed;

    &:hover {
      background-color: color('greyd');
    }
  }

  &--type-dark {
    background-color: color('navy2');
    color: color('greyc');

    .eb-dropdown__inner {
      color: color('greyc');
    }

    .eb-dropdown__inner--fill {
      background-color: color('navy2');
    }
    .eb-text {
      color: inherit;
    }
    .eb-dropdown__items {
      background-color: color('navy2');
    }
    .eb-dropdown__item:hover {
      background-color: color('navy3');
      color: color('greyfb');
    }
  }
}

.eb-dropdown__type-section {
  display: inline-block;
  position: relative;

  &--block {
    display: block;
    width: 100%;
  }

  &__inner {
    display: flex;
    align-items: center;
    border: 0;
    margin: 0;
    padding: 0 1rem 0 1rem;
    color: color('grey2');
    cursor: pointer;
    outline: none;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;

    &--fill {
      background-color: color('white');

      &:hover,
      &.eb-dropdown__type-section__inner--active {
        background-color: color('purple-white');
      }
    }

    &--border {
      border-radius: 0;
      border: 1px solid color('greyd');
    }

    &--label {
      font-weight: bold;
    }
  }

  &__icon {
    color: color('grey2');
  }

  &__drop-icon {
    pointer-events: none;
    margin-left: auto;
    padding-left: 0.75rem;

    &--disabled {
      color: color('greyc');
    }
  }

  &__items {
    position: absolute;
    display: none;
    list-style: none;
    margin: 0.25rem 0 0;
    padding: 0;
    min-width: 100%;
    z-index: 100;
    background-color: color('white');
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    max-height: 180px;
    overflow-y: scroll;

    &--clicked {
      display: none;
    }

    &--active,
    &:not(&--clicked):hover {
      display: block;
    }

    &--align-right {
      right: 0;
    }
  }

  &__item {
    position: relative;
    padding: 0.75rem 1.75rem;
    box-shadow: 0 1px 0 0 #eeeeee;
    cursor: pointer;
    white-space: nowrap;

    &--active,
    &--active * {
      color: color('purple');
      font-weight: bold;
    }

    &:hover {
      background-color: color('purple-white');
    }

    &:hover .eb-text {
      color: color('purple');
    }

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__item-icon {
    position: absolute;
    top: 15px;
    left: 12px;
    font-size: 0.5rem;
    color: color('purple');
  }

  &--size-tiny {
    height: 2rem;

    .eb-dropdown__type-section__inner {
      height: 2rem;
      line-height: 2rem;
      font-size: 0.875rem;
      padding-left: 0.75rem;
      padding-right: 0.5rem;
    }

    .eb-dropdown__type-section__item {
      font-size: 0.875rem;
    }
  }

  &--size-small {
    height: 2.5rem;
    .eb-dropdown__type-section__inner {
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: 0.875rem;
      padding-left: 1rem;
      padding-right: 0.75rem;
    }

    .eb-dropdown__type-section__item {
      font-size: 0.875rem;
    }
  }

  &--size-default {
    height: 3rem;
    .eb-dropdown__type-section__inner {
      height: 3rem;
      line-height: 3rem;
      font-size: 1rem;
      padding-left: 1rem;
      padding-right: 0.75rem;
    }

    .eb-dropdown__type-section__item {
      font-size: 1rem;
    }
  }

  &--width-auto {
    .eb-dropdown__type-section__inner {
      width: inherit;
    }
  }

  &--width-default {
    .eb-dropdown__type-section__inner {
      min-width: 100%;
    }
  }

  &--icon {
    .eb-dropdown__type-section__inner {
      min-width: 2rem;
      width: 2rem;
      padding: 0;
      height: 2rem;
      min-height: 2rem;
      justify-content: center;
    }
    .eb-dropdown__type-section__item {
      min-width: 7rem;
    }
  }

  &--disabled {
    opacity: 0.5;
    background-color: color('greyd');
    border-color: color('grey9');
    cursor: not-allowed;

    &:hover {
      background-color: color('greyd');
    }
  }
}

$content-height: rem(42px);

.eb-dropdown-scroll {
  user-select: none;
  position: relative;

  &.open {
    background-color: color('white');
  }

  &--role-primary {
    & .eb-dropdown-scroll__content {
      background-color: color('purple-bright');
    }
  }

  &--role-secondary {
    & .eb-dropdown-scroll__content {
      background-color: color('darkblue-bright');
    }
  }

  &__content {
    height: $content-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(12px) rem(24px);
    border-radius: 0 0 8px 8px;

    &.hoverable {
      cursor: pointer;
    }

    &.disable {
      cursor: default;
    }
  }

  &__content__title {
    cursor: pointer;

    &.disable {
      cursor: default;
    }
  }

  &__options {
    width: 100%;
    position: absolute;
    top: $content-height;
    left: 0;
    background-color: color('white');
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.4);
  }

  &__option:hover {
    background: color('purple-white');
    cursor: pointer;
  }

  .eb-dropdown-option {
    padding: rem(8px) rem(16px);
  }
}
