@import '../../stylesheets/imports/index';

.eb-check-card {
  border: 1px solid color('greyd');
  cursor: pointer;

  &__checkbox {
    background-color: color('white');

    &:hover {
      background-color: color('purple-white');
      transition: background-color 0.3s linear;
    }
  }

  &__action {
    background-color: color('white');

    &:hover {
      background-color: color('purple-white');
      color: color('purple');
      transition: background-color 0.3s linear;
    }
  }
}
