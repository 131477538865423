@import '../../stylesheets/imports/index';

.eb-ant-tooltip {
  &--size-wide {
    .ant-tooltip-inner {
      max-width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
      white-space: nowrap;
    }
  }
}

.ant-tooltip-inner {
  min-height: initial;
}
