@import '../../stylesheets/imports/index';

.eb-bubble {
  position: relative;
  margin: 0 auto;
  margin-bottom: 1rem;
  line-height: 1.2;
  z-index: 500; // .eb-resizable__grip has 400 so it is greater
  animation: fadein 1s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &--is-type-standard {
    width: 100%;
    max-width: rem(960px);
  }

  &--is-type-narrow {
    width: 100%;
    max-width: rem(500px);
  }

  &--is-type-middle {
    width: 100%;
    max-width: rem(880px);
  }

  &--is-type-wide {
    width: 100%;
    max-width: rem(1040px);
  }

  &--is-type-full {
    width: 100%;
  }

  &--is-size-medium {
    font-size: 16px;
  }

  &--is-size-small {
    font-size: 14px;
  }

  .t-dark & {
    color: color('grey2');
  }

  &--center-align {
    text-align: center;
  }

  &--hidden {
    display: none;
  }

  &--global {
    position: fixed;
    margin-top: 12px;
    left: 50%;
    transform: translate(-50%, 0);
    min-width: 300px;
    text-align: center;
  }

  &-container {
    padding: 1rem 1.5rem;
    border-radius: 4px;
    border: 1px solid color('greyd');
    background-color: color('greye');

    .eb-bubble--success & {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #3c763d;
    }

    .eb-bubble--info & {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #31708f;
    }

    .eb-bubble--warning & {
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #8a6d3b;
    }

    .eb-bubble--danger & {
      color: #a94442;
      background-color: #f2dede;
      border-color: #a94442;
    }

    .eb-bubble--primary & {
      color: color('purple');
      background-color: color('purple-white');
      border-color: color('purple');
    }
  }

  &__title {
    color: color('grey2');

    i {
      margin-right: 6px;
    }
  }

  &__body {
    font-size: 14px;
    .eb-bubble--collapsed & {
      display: none;
    }

    ul {
      margin-top: 0;
    }

    li {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title + &__body,
  &__footer {
    margin-top: 12px;
  }

  &__footer {
    .eb-button {
      margin-right: 8px;
      padding: 0 7px;
      font-size: 11px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__hide-button {
    position: absolute;
    cursor: pointer;
    top: 8px;
    right: 8px;
    font-size: 10px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}
