@import '../../stylesheets/imports/index';

.eb-columned-container {
  position: relative;
  background-color: color('turq');
  overflow: hidden;
  z-index: $common-z-index-bottom;

  &__body {
    margin: 0 auto;
    max-width: $standard-max-width;
  }

  &__right-background {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background-color: color('purple');
    z-index: $common-z-index-middle;
  }
}

.eb-columned-container-column {
  position: relative;
  float: left;
  padding: rem(16px) rem(32px) rem(32px) rem(32px);
  color: color('white');
  z-index: $common-z-index-top;

  &:nth-of-type(odd) {
    background-color: color('turq');
  }

  &:nth-of-type(even) {
    background-color: color('purple');
  }

  @for $i from 1 to 12 {
    &--count-#{$i} {
      width: #{100% / $i};
    }
  }
}

@media (max-width: $media-sm-width) {
  .eb-columned-container {
    background-color: transparent;

    &__right-background {
      display: none;
    }
  }

  .eb-columned-container-column {
    position: relative;
    float: none;
    padding: rem(16px) rem(16px);
    width: 100%;

    &:nth-of-type(odd) {
      background-color: color('turq');
    }

    &:nth-of-type(even) {
      background-color: color('purple');
    }
  }
}
