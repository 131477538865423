@import '../../stylesheets/imports/index';

.eb-track-header {
  height: 19.5rem;
  position: relative;
  background-color: color('purple-black');
  overflow: hidden;

  &--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 77rem;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    padding: 3.5rem 1.5rem 0;
    position: relative;
    z-index: 10;

    @media #{$mobile} {
      padding: 2.5rem 1.5rem;
    }

    &--inner {
      position: relative;
      align-self: stretch;
      display: flex;
      width: 100%;
      align-items: stretch;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 50%;
    width: 80rem;
    height: 100%;
    z-index: 1;

    @media #{$tabletOrSmaller} {
      display: none;
    }
  }

  &__content {
    position: relative;
    z-index: 9;
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 2rem;
    line-height: 1;

    @media #{$mobile} {
      font-size: 1.75rem;
    }
  }

  &__subtitle {
    display: block;
    max-width: 35rem;
    opacity: 0.75;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media #{$mobile} {
      font-size: 0.875rem;
    }
  }

  &__logo--wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 9;
  }

  &__logo {
    margin-bottom: -2.5rem;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    z-index: 9;

    @media #{$tabletOrSmaller} {
      display: none;
    }
  }

  &__bg-image {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 80rem;
    opacity: 0.25;

    @media #{$tabletOrSmaller} {
      display: none;
    }
  }

  &__action-buttons {
    line-height: 1;
    margin-top: 2.5rem;

    @media #{$mobile} {
      margin-top: 1.5rem;
    }

    .eb-button {
      margin-right: 0.5rem;

      @media #{$mobile} {
        display: block;
        margin-right: 0;

        &:not(:last-child) {
          margin-bottom: 0.75rem;
        }
      }
    }
  }
  &__tabs {
    margin-top: 1rem;
  }
}
