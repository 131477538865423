@import '../../stylesheets/imports/index';

.eb-landing-banner {
  display: flex;
  width: 100%;
  position: relative;
  margin: 0 auto;

  @media #{$desktop} {
    height: rem(144px);
    width: $container-xl-width;
  }

  @media #{$laptop} {
    height: rem(180px);
    width: $container-lg-width;
  }

  @media #{$tablet} {
    height: rem(400px);
    width: $container-md-width;
  }

  @media #{$mobile} {
    height: rem(400px);
    width: 100%;
  }

  &__items {
    width: 100%;
  }

  &--banner-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;

    @media #{$desktop} {
      height: rem(144px);
      width: $container-xl-width;
    }

    @media #{$laptop} {
      height: rem(180px);
      width: $container-lg-width;
    }

    @media #{$tablet} {
      height: rem(400px);
      width: $container-md-width;
    }

    @media #{$mobile} {
      height: rem(400px);
      width: 100%;
    }

    .eb-link {
      display: block;
    }

    .eb-safe-image {
      @media #{$desktop} {
        height: rem(144px);
        width: $container-xl-width;
      }

      @media #{$laptop} {
        height: auto;
        width: $container-lg-width;
      }

      @media #{$tablet} {
        height: rem(400px);
        width: auto;
      }

      @media #{$mobile} {
        height: rem(400px);
        width: rem(327px);
      }
    }
  }

  .eb-horizontal-scroll-handler__hider {
    cursor: pointer;
    border-radius: 50%;
    color: #fff;
    height: 3rem;
    width: 3rem;
    position: absolute;
    top: 50%;
    overflow: hidden;
    transform: translateY(-50%);

    @media #{$mobile} {
      height: 2rem;
      width: 2rem;
    }

    &--prev {
      left: auto;
      right: 100%;
      margin-right: 0.5rem;

      @media #{$desktop} {
        margin-right: 2rem;
      }

      @media #{$tablet} {
        left: 0;
        right: auto;
        margin-right: 0;
      }
      @media #{$mobile} {
        left: 0;
        right: auto;
        margin-right: 0;
      }
    }

    &--next {
      left: 100%;
      right: auto;
      margin-left: 0.5rem;

      @media #{$desktop} {
        margin-left: 2rem;
      }

      @media #{$tablet} {
        right: 0;
        left: auto;
        margin-left: 0;
      }

      @media #{$mobile} {
        right: 0;
        left: auto;
        margin-left: 0;
      }
    }

    &:hover {
      [class*='icon-'] {
        color: color('greyb');
      }
    }

    [class*='icon-'] {
      color: color('greyd');
      height: 3rem;
      width: 3rem;
      font-size: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @media #{$tablet} {
        color: color('white');
      }
      @media #{$mobile} {
        color: color('white');
        height: 2rem;
        width: 2rem;
        font-size: 1.5rem;
      }
    }
  }
}
