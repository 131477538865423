@import '../../stylesheets/imports/index';

.eb-markdown-editor {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: rem(44px);

  &__disabled {
    user-select: none;
    cursor: not-allowed;

    .CodeMirror {
      opacity: 0.5;
    }
  }

  &--self {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    background: #fff;
    overflow: hidden;

    .editor-toolbar {
      opacity: 0.6;

      button {
        font-size: 16px;
      }
    }
  }

  &--spinner {
    position: absolute;
    background-color: color('black');
    width: 100%;
    height: 100%;
    opacity: 0.7;
    transition: opacity 500ms ease-in-out;
    z-index: 390;

    &__hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &--footer {
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    width: 100%;
    height: rem(44px);
    padding: 0 10px;
    font-size: rem(12px);
    color: color('grey6');
    line-height: rem(44px);

    &-link {
      padding-left: rem(8px);
      color: color('purple');
      text-decoration: underline;
    }
  }

  &--dropzone {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    transition: opacity 500ms;
    z-index: 390;

    &__hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &--preview {
    padding: 1em;
    background: color('white');
  }

  &__codemirror-wrapper {
    position: relative;
    height: 100%;
    padding-top: 51px;
  }

  &__codemirror-wrapper .editor-toolbar {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    height: 51px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  & .CodeMirror {
    padding-top: 0 !important; // Fix for scroll issue.
    padding-bottom: 0 !important; // Fix for scroll issue.
    height: 100%;
    line-height: 1.5;
    font-family: inherit;
  }

  .editor-toolbar.disabled-for-preview a:not(.no-disable) {
    background-color: transparent;
    color: color('greya') !important;
  }
}
