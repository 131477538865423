@import '../../stylesheets/imports/index';

$shimmer-animation: 2s linear infinite glow !default;
$shimmer-color-start: rgba(#efefef, 1) !default;
$shimmer-color-end: rgba(#dedede, 1) !default;

@keyframes glow {
  0%,
  100% {
    border-color: $shimmer-color-start;
    background-color: $shimmer-color-start;
  }

  50% {
    border-color: $shimmer-color-end;
    background-color: $shimmer-color-end;
  }
}

// This class hides content with a glowing, rounded rectangle. CSS properties that should always
// override consumer values use the "!important" rule.
/* stylelint-disable declaration-no-important */
.eb-shimmer {
  border-color: $shimmer-color-start !important;
  border-radius: 2px;
  box-shadow: none !important;
  background: $shimmer-color-start !important;

  // Prevent background color from extending to the border and overlappping
  background-clip: padding-box !important;
  cursor: default;

  // Transparent text will occupy space but be invisible to the user
  color: transparent !important;
  animation: $shimmer-animation;
  pointer-events: none;
  user-select: none;

  // Make pseudo-elements (CSS icons) and children invisible
  &::before,
  &::after,
  * {
    visibility: hidden !important;
  }
}
