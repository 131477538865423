@import '../../stylesheets/imports/index';

.eb-action-header {
  position: relative;
  margin: 0 auto;
  max-width: $standard-max-width;
  min-height: rem(340px);

  &__container {
    padding: rem(16px) rem(440px + 16px) rem(16px) 0;
  }

  &__title {
    padding: rem(8px) 0;
    color: color('grey2');
    font-size: rem(32px);
    font-weight: $bold;
  }

  &__message {
    padding: rem(32px) 0;
    color: color('grey2');
    font-size: rem(16px);
    line-height: 1.5em;
  }

  &__button-row {
    padding: rem(24px) 0;
  }
}
