@import '../../stylesheets/imports/index';

.eb-action-bar {
  display: block;

  &::after {
    display: block;
    clear: both;
    content: ' ';
  }
}

.eb-action-bar-item {
  display: block;
  float: left;
  padding: 0 12px 0 0;
  line-height: 30px;

  &:last-of-type {
    padding: 0;
  }

  &--align-right {
    float: right;
    padding: 0 0 0 12px;

    &:last-of-type {
      padding: 0;
    }
  }
}
