@import '../../stylesheets/imports/index';

.eb-ant-tabs {
  .ant-tabs-tab {
    padding: 12px;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  &--role-primary {
    .ant-tabs-tab {
      color: fade-out(color('black'), 0.5);
      &:hover {
        color: color('black');
      }
    }

    .ant-tabs-tab-active {
      color: color('black');
      font-weight: bold;
    }

    .ant-tabs-ink-bar {
      background-color: color('purple');
    }
  }

  &--role-white {
    .ant-tabs-tab {
      color: fade-out(color('white'), 0.5);
      &:hover {
        color: color('white');
      }
    }

    .ant-tabs-tab-active {
      color: color('white');
      font-weight: bold;
    }

    .ant-tabs-ink-bar {
      background-color: color('white');
    }
  }

  &--size-small {
    .ant-tabs-tab {
      font-size: 14px;
      padding: 12px 10px;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  &--size-default {
    .ant-tabs-tab {
      font-size: 1rem;
    }
  }

  &--size-large {
    .ant-tabs-tab {
      font-size: 1.25rem;
    }
  }

  .ant-tabs-ink-bar {
    height: 2px;
  }

  .ant-tabs-bar {
    border-bottom-color: color('greyd');
  }
}
