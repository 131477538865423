@import '../../stylesheets/imports/index';

.eb-target-user-card {
  border: 1px solid color('greyd');
  background-color: #fff;
  background-repeat: no-repeat;
  text-decoration: none;
  position: relative;

  @media #{$desktop} {
    height: rem(326px);
    width: rem(336px);
  }

  @media #{$laptop} {
    height: rem(326px);
    width: rem(296px);
  }

  @media #{$tablet} {
    background-image: none;
    height: rem(124px);
    width: 100%;
  }

  @media #{$mobile} {
    background-image: none;
    height: rem(124px);
    width: 100%;
  }

  &__body {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;

    @media #{$desktop} {
      padding-top: rem(194px);
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media #{$laptop} {
      padding-top: rem(194px);
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @media #{$tablet} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    @media #{$mobile} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  &__title {
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &__subtitle {
    width: 100%;
    @media #{$tablet} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    @media #{$mobile} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    @media #{$tablet} {
      display: none;
    }

    @media #{$mobile} {
      display: none;
    }

    &__logo {
      line-height: 1;

      @media #{$desktop} {
        background-position: center 40px;
        background-size: 224px 130px;
      }

      @media #{$laptop} {
        background-position: center 40px;
        background-size: 224px 130px;
      }
    }
  }
}
