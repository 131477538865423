@import '../../stylesheets/imports/index';

.eb-linkify {
  white-space: pre-wrap;
  word-break: break-all;

  a {
    color: color('purple');
  }
}
