@import '../../stylesheets/imports/index';

.eb-button-list {
  border: 1px solid color('greyc');
}

.eb-button-list-item {
  display: block;
  position: relative;
  border-bottom: 1px solid color('greyc');
  padding: rem(24px) rem(72px) rem(24px) rem(48px);
  color: color('grey2');
  font-size: rem(20px);

  &:hover {
    background-color: color('greyfb');
  }

  &:last-of-type {
    border-bottom: 0;
  }

  &__icon {
    position: absolute;
    top: rem(22px);
    right: rem(48px);
    color: color('grey2');
    font-size: rem(24px);
  }
}
