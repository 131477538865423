@import '../../stylesheets/imports/index';

.eb-tabbed-file-browser {
  width: 100%;
  height: 100%;

  &__filetree-column {
    float: left;
    padding: 0;
    min-width: 180px;
    max-width: 300px;
    width: 30%;
    height: 100%;
    border-right: 1px solid color('navy2');
    overflow: auto;

    .t-dark & {
      background-color: color('navy1');
    }

    &--is-hidden {
      display: none;
    }
  }

  &__main-column {
    overflow: hidden;
    height: 100%;

    .t-dark & {
      background-color: color('navy2');
    }
  }

  &__main-column-content {
    position: relative;
    padding-top: 3rem;
    height: 100%;
    overflow: hidden;
  }

  &__tab-row {
    display: flex;
    position: absolute;
    width: 100%;
    height: 3rem;
    top: 0;
    left: 0;
  }

  &__tab-row-item {
    flex: 0 0 auto;
  }

  &__tab-row-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 100%;
    font-size: 1rem;
    color: color('greyc');
    transition: color 0.5s ease;
    cursor: pointer;

    &:hover {
      color: color('greyfb');
    }

    &--is-active {
      color: color('greyfb');
    }

    .t-dark & {
      color: color('greyc');

      &:hover {
        color: color('greyfb');
      }

      &--is-active {
        background-color: color('navy3');
        color: color('greyfb');
      }
    }
  }

  &__tabs {
    padding: 0.5rem 0 0;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    min-width: 0;
  }

  &__body-row {
    width: 100%;
    height: 100%;

    .t-dark & {
      background-color: color('navy1');
    }

    &__no-file {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      line-height: 2rem;

      @media #{$laptopOrSmaller} {
        overflow-x: auto;
      }

      &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 2rem;

        a {
          font-size: 0.9rem;
          text-decoration: underline;
          color: color('purple-light');

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
