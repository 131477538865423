.eb-notification {
  position: fixed;
  top: 32px;
  left: 50%;
  z-index: 1000;

  &-notice {
    position: relative;
    margin: 10px 0;
    padding: 1rem 1.5rem;
    width: auto;
    border: 0 solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background: #fff;

    &-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    // close button
    &--close-btn {
      margin: 0;
      padding: 0;
      border: none;
      background: none;
      cursor: pointer;

      &:active,
      &:focus {
        outline: none;
      }
    }

    // theme
    &__theme {
      &-info {
        .eb-notification-notice--icon {
          color: #524fa1;
        }
      }

      &-success {
        .eb-notification-notice--icon {
          color: #00ab53;
        }
      }

      &-warn {
        .eb-notification-notice--icon {
          color: #e57800;
        }
      }

      &-error {
        .eb-notification-notice--icon {
          color: #f94669;
        }
      }
    }
  }

  // animation
  .eb-notification-fade {
    &-enter {
      opacity: 0;
      animation-duration: 200ms;
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
      animation-play-state: paused;

      &.eb-notification-fade-enter-active {
        animation-name: eb-notification-fade-in;
        animation-play-state: running;
      }
    }

    &-leave {
      animation-duration: 200ms;
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
      animation-play-state: paused;

      &.eb-notification-fade-leave-active {
        animation-name: eb-notification-fade-out;
        animation-play-state: running;
      }
    }
  }
}

@keyframes eb-notification-fade-in {
  0% {
    transform-origin: 0 0;
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes eb-notification-fade-out {
  0% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform-origin: 0 0;
    transform: translateY(-50%);
    opacity: 0;
  }
}
