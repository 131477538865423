@import '../../stylesheets/imports/index';

.eb-modal-basic {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  &__inner {
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: color('white');
    margin: 2rem auto;
  }

  &--size-narrow {
    width: 480px;
    min-height: 480px;

    @media #{$tabletOrSmaller} {
      width: 100vw;
      min-height: 100vh;
      border-radius: 0;
      margin: 0;
    }
  }

  &--size-normal {
    width: 640px;
    min-height: 640px;

    @media #{$tabletOrSmaller} {
      width: 100vw;
      min-height: 100vh;
      border-radius: 0;
      margin: 0;
    }
  }

  &--size-wide {
    width: 800px;
    min-height: 800px;

    @media #{$tabletOrSmaller} {
      width: 100vw;
      min-height: 100vh;
      border-radius: 0;
      margin: 0;
    }
  }

  &--size-full {
    width: 100vw;
    min-height: 100vh;
    border-radius: 0;
    margin: 0;

    @media #{$tabletOrSmaller} {
      width: 100vw;
      min-height: 100vh;
      border-radius: 0;
      margin: 0;
    }
  }

  &__content {
    flex: 1 1 auto;
    padding: 2rem;
  }

  &__button {
    flex: 0 0 auto;
    border-radius: 0;
  }
}
