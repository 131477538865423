@import '../../stylesheets/imports/index';

.eb-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  z-index: 981;
  word-break: keep-all;
  padding: 0 1.5rem;

  &__content {
    display: inline-block;
    background-color: color('white');
    margin: 0 auto 10rem;
    border-radius: 6px;
    z-index: 982;
    text-align: left;
    cursor: default;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.3);
    width: 480px;

    @media #{'only screen and (max-width: 528px)'} {
      width: 100vw;
    }
  }

  &__close-button {
    cursor: pointer;
    margin-right: -0.5rem;

    i {
      color: color('grey9');
      font-size: 14px;
    }
  }

  &__icon {
    font-size: 24px;
    padding-right: 12px;

    &--danger,
    &--error {
      color: color('red');
    }

    &--success,
    &--admin,
    &--social {
      color: color('lime');
    }

    &--info,
    &--confirm {
      color: color('purple');
    }

    &--warning,
    &--alert {
      color: color('yellow');
    }
  }

  &__title-row {
    border-bottom: 1px solid color('greyd');
    padding: 20px 32px;
  }

  &__title {
    font-size: 20px;
    color: color('black');
    font-weight: bold;
  }

  &__text {
    display: block;
    line-height: 24px;
    font-size: 16px;
    color: color('grey2');
    padding: rem(24px) 0;
    white-space: pre-wrap;
    background-color: color('greyf5');

    &__scrollable {
      max-height: 68vh;
      overflow: auto;
      padding: 0 rem(32px);
    }
  }

  &__buttons-row {
    padding: 0 rem(32px) rem(20px) rem(32px);
    background-color: color('greyf5');
    border-radius: 0 0 6px 6px;
  }

  &__buttons-row button {
    background-color: transparent;
    cursor: pointer;
    padding: 12px 27px;
    margin: 0 6px;
    font-size: 14px;
    min-width: 80px;
    line-height: 16px;
    text-align: center;
    color: color('grey6');
    outline: none;
    border: 0;
    transition: all 0.3s ease;

    &:hover {
      background-color: color('greyd');
    }

    &.bold {
      background-color: color('purple');
      color: color('white');

      .eb-dialog--error &,
      .eb-dialog--danger & {
        background-color: color('red');
      }

      .eb-dialog--admin &,
      .eb-dialog--success &,
      .eb-dialog--social & {
        background-color: color('lime');
      }

      .eb-dialog--confirm &,
      .eb-dialog--info & {
        background-color: color('purple');
      }

      .eb-dialog--alert &,
      .eb-dialog--warning & {
        background-color: color('yellow');
      }

      &:hover {
        background-color: hover-color('purple');

        .eb-dialog--error &,
        .eb-dialog--danger & {
          background-color: hover-color('red');
        }

        .eb-dialog--admin &,
        .eb-dialog--success &,
        .eb-dialog--social & {
          background-color: hover-color('lime');
        }

        .eb-dialog--confirm &,
        .eb-dialog--info & {
          background-color: hover-color('purple');
        }

        .eb-dialog--alert &,
        .eb-dialog--warning & {
          background-color: hover-color('yellow');
        }
      }
    }

    &.disabled {
      cursor: default;
    }
  }
}

.eb-dialog-shade {
  position: fixed;
  top: 0;
  left: 0;
  background-color: color('black');
  opacity: 0.5;
  z-index: 980;
  width: 100vw;
  height: 100vh;
}
