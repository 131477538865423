@import '../../stylesheets/imports/index';

.eb-action-textbox {
  display: inline-block;
  position: relative;
  padding: 0 48px 0 0;

  &--block {
    display: block;
    width: 100%;
  }

  &__button {
    display: block;
    position: absolute;
    background-color: color('purple');
    top: 0;
    right: 0;
    text-align: center;
    width: 48px;
    height: 48px;
    line-height: 48px;
    color: color('white');
    cursor: pointer;

    &:hover {
      background-color: hover-color('purple');
    }

    &--disabled {
      cursor: default;
      background-color: color('purple');

      &:hover {
        background-color: color('purple');
      }
    }
  }
}
