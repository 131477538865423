@import '../../stylesheets/imports/index';

.eb-content-layout {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 1.5rem 4rem;
  width: calc(#{$col-12} + 1.5rem * 2);

  @media #{$laptopOrSmaller} {
    display: block;
    max-width: calc(#{$container-xl-width} + 1.5rem * 2);
    width: 100%;
  }

  .eb-content-layout--left-sider {
    width: 100%;
    max-width: $col-2;
    flex: 0 0 $col-2;
    margin-right: 1rem;

    @media #{$laptopOrSmaller} {
      max-width: 100%;
      margin-bottom: 1.5rem;
    }
  }

  .eb-content-layout--right-sider {
    width: 100%;
    max-width: $col-4;
    flex: 0 0 $col-4;
    margin-left: 1rem;

    @media #{$laptopOrSmaller} {
      max-width: 100%;
      margin-top: 1.5rem;
    }
  }

  .eb-content-layout--content {
    max-width: $col-12;
    width: 100%;

    &.has-left-sider {
      max-width: $col-10;
    }

    &.has-right-sider {
      max-width: $col-8;
    }

    @media #{$laptopOrSmaller} {
      max-width: $col-12 !important;
    }
  }

  &.has-sider-content {
    .content {
      overflow: hidden;
    }
  }
}
