@import '../../stylesheets/imports/index';

.eb-quiz-radio {
  transition: background-color 0.5s ease, color 0.5s ease;

  &--block {
    display: flex;

    .eb-quiz-radio-option {
      margin-right: 1rem;
      margin-bottom: 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &--flex {
    display: flex;
    flex-flow: row wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    width: 100%;
  }

  &--vertical {
    display: flex;
    flex-direction: column;
  }
}

.eb-quiz-radio-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: color('grey2');
  margin-bottom: rem(12px);
  border: 1px solid #dddddd;
  border-radius: 2px;
  background-color: #f9f9f9;
  min-height: 3rem;
  padding: 1rem;
  word-break: break-all;

  &:last-of-type {
    margin-bottom: 0;
  }

  &--selected {
    border: 1px solid color('purple');
    background-color: rgba(82, 79, 161, 0.12);
  }

  &--correct {
    color: color('white');
    border: 1px solid color('purple');
    background-color: color('purple');
  }

  &--wrong {
    color: color('white');
    border: 1px solid color('red');
    background-color: color('red');
  }

  &--answer {
    color: color('white');
    border: 1px solid color('purple');
    background-color: color('purple');
    opacity: 0.5;
  }

  &--disabled {
    cursor: not-allowed;
  }

  &__icon {
    font-size: rem(18px);
    line-height: rem(18px);
    color: color('greyc');

    &--selected {
      color: color('purple');
    }

    &--correct {
      color: color('white');
    }

    &--wrong {
      color: color('white');
    }
  }

  &__label {
    padding-left: rem(12px);
    font-size: rem(16px);
    line-height: rem(24px);
    color: inherit;
  }
}
