@import '../../stylesheets/imports/index';

.eb-footer {
  // background-color: color('grey3');
  &__inner {
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    @media #{$desktop} {
      display: block;
      width: $container-xl-width;
      padding-left: 0;
      padding-right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__first-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    order: 0;

    @media #{$mobile} {
      order: 1;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    font-size: rem(14px);
    // color: color('white');
  }

  &__text-links {
    display: flex;
    padding: 0.5rem 0;
    flex-wrap: wrap;
    align-items: center;
    margin-right: rem(32px);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
  }

  &__social-links {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    flex: 0 0 auto;
    flex-wrap: wrap;
    margin: -1rem;

    &__buttons {
      margin: 0.5rem;
    }

    &__icons {
      display: flex;
      margin: 0.5rem;
    }
  }

  &__copyright {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: rem(14px);
    // color: color('white');
    line-height: 1.5em;
    order: 2;

    @media #{$mobile} {
      order: 0;
    }
  }

  &__info {
    padding: 0 1rem;
    font-size: rem(12px);
    // color: color('grey9');
    line-height: 1.5em;
    white-space: pre-wrap;
    order: 2;
  }

  &__language-selector-container {
    display: flex;
    padding: 0.25rem 1rem;
    flex: 0 0 rem(112px);
    align-items: center;
  }

  .eb-dropdown {
    min-width: 10rem;
  }
}

.eb-footer-text-link {
  display: block;
  padding: 0rem 1rem;
  margin: 0.25rem 0;
  font-size: 0.875rem;
  color: #fff;
  cursor: pointer;

  &:last-child {
    border-right: none;
  }
}

.eb-footer-icon-link {
  display: block;
  float: right;
  font-size: rem(18px);
  text-align: center;
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  cursor: pointer;
  border-radius: 50%;
  margin-left: rem(4px);
  margin-right: rem(4px);
}

@media #{$laptop} {
  .eb-footer {
    &__links {
      display: block;
    }

    &__first-row {
      align-items: flex-start;
      flex-wrap: nowrap;
    }
  }
}

@media #{$tablet} {
  .eb-footer {
    &__links {
      display: block;
      margin-bottom: 1rem;
    }

    &__first-row {
      display: block;
      margin-bottom: 1rem;
    }
  }
}

@media #{$mobile} {
  .eb-footer {
    &__links {
      display: block;
      margin-bottom: 1rem;
    }

    &__first-row {
      display: block;
      margin-bottom: rem(24px);
    }

    &__info {
      font-weight: 200;
      font-size: rem(14px);
      // color: #fff;
      order: 2;
    }
  }
}

.eb-org-footer {
  background-color: color('gray0');
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6.5rem;

  @media #{$desktop} {
    height: auto;
  }

  &__inner {
    width: 100%;
    padding: 1rem 1.5rem;
    display: flex;
    flex: none;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    @media #{$desktop} {
      width: $container-xl-width;
      padding-left: 0;
      padding-right: 0;
    }

    @media #{$mobile} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__description-wrapper {
    flex-direction: row;
    color: color('grey6');
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    align-items: center;
  }

  &__description {
    color: color('grey6');
    font-size: 14px;
    line-height: 14px;
  }

  &__logo {
    margin: 4px;
  }

  &__language-selector-container {
    display: flex;
    padding: 0.25rem 1rem;
    flex: 0 0 rem(112px);
    align-items: center;

    @media #{$mobile} {
      flex: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  &__language-selector {
    background-color: transparent;
    color: color('grey6');

    &:hover {
      background-color: color('greye');
      color: color('grey5');
    }
  }

  .eb-dropdown {
    min-width: 10rem;
  }
}

.eb-footer--theme-dark {
  background-color: color('grey3');

  .eb-footer {
    &__links {
      color: color('white');
    }

    &__copyright {
      color: color('white');
    }

    &__info {
      color: color('grey9');

      @media #{$mobile} {
        color: color('white');
      }
    }

    &__language-selector {
      background-color: transparent;
      color: color('white');

      &:hover {
        background-color: color('grey4');
        color: color('white');
      }
    }

    &__language-selector-icon {
      color: color('white');
    }
  }

  .eb-footer-text-link {
    color: #fff;
    border-right: 1px solid color('greyc');

    &:last-child {
      border-right: none;
    }

    &:hover {
      color: hover-color('white');
    }
  }

  .eb-footer-icon-link {
    color: color('white');

    &:hover {
      color: hover-color('white');
    }
  }
}

.eb-footer--theme-light {
  background-color: color('gray0');

  .eb-footer {
    &__links {
      color: color('gray9');
    }

    &__copyright {
      color: color('gray9');
    }

    &__info {
      color: color('grey9');

      @media #{$mobile} {
        color: color('grey9');
      }
    }

    &__language-selector {
      background-color: transparent;
      color: color('gray9');

      &:hover {
        background-color: color(transparent);
        color: color('gray9');
      }
    }

    &__language-selector-icon {
      color: color('gray9');
    }
  }

  .eb-footer-text-link {
    color: color('gray9');
    border-right: 1px solid color('greyc');

    &:last-child {
      border-right: none;
    }

    &:hover {
      color: hover-color('gray9');
    }
  }

  .eb-footer-icon-link {
    color: color('white');
    background-color: color('purple-dark');
    padding-top: 1px; // icon seems not aligned exact middle

    &:hover {
      color: hover-color('white');
    }
  }
}

.eb-footer--position-fixed,
.eb-org-footer--position-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4.5rem;
}
