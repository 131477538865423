@mixin prev-hider-gradient($gradient-color) {
  background: linear-gradient(
    to right,
    $gradient-color 50%,
    rgba($gradient-color, 0) 100%
  );
}

@mixin next-hider-gradient($gradient-color) {
  background: linear-gradient(
    to right,
    rgba($gradient-color, 0) 0%,
    $gradient-color 50%
  );
}

@mixin ts-code {
  white-space: pre-wrap;
  font-family: $monospace-font-stack;
}

@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    -#{$prefix}-#{$property}: $value;
  }

  #{$property}: $value;
}

@mixin button-color-set($name, $transparent-name: false, $color-name: 'white') {
  color: color($color-name);
  @if $name == 'greyfb' {
    border-color: color($color-name);
  } @else {
    border-color: color($name);
  }
  background-color: color($name);

  &:not(.eb-button--disabled):not(.eb-button--no-border):hover {
    border-color: hover-color($name);
    background-color: hover-color($name);
  }

  &.eb-button--transparent,
  &.eb-icon-button--transparent {
    background-color: transparent;
    @if $transparent-name {
      color: color($transparent-name);
    } @else {
      color: color($name);
    }

    &:not(.eb-button--disabled):not(.eb-button--no-border):hover {
      border-color: color($name);
      background-color: rgba(color($name), 0.1);
    }
  }

  &.eb-button--no-border:hover,
  &.eb-icon-button--no-border:hover {
    background-color: transparent;
    @if $transparent-name {
      color: hover-color($transparent-name);
    } @else {
      color: hover-color($name);
    }
  }
}

@mixin text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

@mixin clamp($font-size, $lines, $line-height: 1.4) {
  display: block;
  position: relative;
  font-size: $font-size;
  height: $font-size * $lines * $line-height;
  line-height: $line-height;
  overflow: hidden;

  &:after {
    content: '';
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: $font-size * $line-height;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      white 50%,
      white
    );
  }

  @supports (-webkit-line-clamp: $lines) {
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    /*! autoprefixer: on */

    &:after {
      display: none;
    }
  }
}

@mixin invisible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

@mixin container($padding: 1.5rem) {
  max-width: $container-xl-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $padding;
  padding-right: $padding;
  position: relative;

  @media #{$desktop} {
    max-width: calc(#{$container-xl-width} + #{$padding} * 2);
  }

  @media #{$laptop} {
    max-width: calc(#{$container-lg-width} + #{$padding} * 2);
  }

  @media #{$tablet} {
    max-width: 100%;
  }

  @media #{$mobile} {
    max-width: 100%;
  }
}

@mixin contentLayout {
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.5rem;
  width: calc(#{$col-12} + 1.5rem * 2);

  @media #{$laptopOrSmaller} {
    max-width: calc(#{$col-12} + 1.5rem * 2);
    width: 100%;
  }
}
