@import '../../stylesheets/imports/index';

.eb-button-dropdown {
  position: relative;

  &--open {
    .eb-button-dropdown__single {
      background-color: color('purple-white');

      &:hover,
      &:active,
      &:visited {
        background-color: color('purple-white');
      }
    }
  }

  &__button {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;

    height: auto;

    .fa {
      line-height: inherit;
    }
  }

  &__trigger-button {
    min-width: 1rem;
    width: 2.125rem;
    padding-left: 0;
    padding-right: 0;
    margin-left: 1px;
  }

  .ant-btn-primary {
    background-color: color('purple');

    &:first-child:not(:last-child) {
      border-color: transparent;
      background-color: color('purple');
    }

    &:last-child:not(:first-child) {
      border-color: transparent transparent transparent color('white');
    }
  }

  &__menu {
    border: 1px solid color('greyd');
    background-color: color('white');
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
    position: absolute;
    margin-top: rem(6px);
    z-index: 9;

    &__bottomleft {
      left: 0;
      top: 100%;
      right: auto;
      bottom: auto;
    }

    &__bottomright {
      left: auto;
      right: 0;
      top: 100%;
      bottom: auto;
    }

    &__topleft {
      left: 0;
      right: auto;
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: rem(6px);
    }

    &__topright {
      left: auto;
      right: 0;
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: rem(6px);
    }

    &__left {
      top: 0;
      right: 100%;
      left: auto;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0.5rem;

      @media #{$laptopOrSmaller} {
        left: 0;
        top: 100%;
        right: auto;
        bottom: auto;
        margin-top: 0.5rem;
        margin-bottom: 0;
        margin-left: 0;
      }
    }

    &__right {
      top: 0;
      left: 100%;
      right: auto;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0.5rem;

      @media #{$laptopOrSmaller} {
        left: auto;
        right: 0;
        top: 100%;
        bottom: auto;
        margin-top: 0.5rem;
        margin-bottom: 0;
        margin-left: 0;
      }
    }

    .ant-menu-item {
      white-space: nowrap;

      &:hover {
        color: color('grey2');
      }

      &:hover:not([aria-disabled='true']) {
        background-color: color('purple-white');
      }

      &-selected {
        background-color: color('purple-white');
      }

      a {
        text-decoration: none;
        color: color('grey2');
      }
    }

    &:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: color('purple-white');
    }
  }

  &--type-dark {
    .ant-menu {
      color: color('greyc');
      background-color: color('navy2');
    }

    .ant-menu-item-disabled {
      color: color('grey9') !important;
    }

    .ant-menu-item a {
      color: inherit;
    }

    .ant-menu-item:hover:not([aria-disabled='true']) {
      color: color('greyfb');
      background-color: color('navy3');
    }
  }
}
