@import '../../stylesheets/imports/index';

.eb-ant-select {
  .ant-select-selection {
    border-radius: 0;

    &:hover {
      border-color: color('purple');
    }
  }

  &.ant-select-focused {
    .ant-select-selection {
      border-color: color('purple');
      box-shadow: none;
    }
  }

  &.ant-select-open {
    .ant-select-selection {
      border-color: color('purple');
      box-shadow: none;
    }
  }

  .ant-select-selection__choice {
    max-width: 200px;
  }
}

.eb-ant-select__large {
  .ant-select-selection {
    min-height: rem(48px);
  }

  .ant-select-selection__placeholder {
    height: rem(48px);
    line-height: rem(48px);
    margin-top: 0;
    margin-bottom: 0;
    top: 0;
  }

  .ant-select-selection__rendered {
    line-height: rem(48px);
  }

  .ant-select-selection__rendered > ul > li {
    line-height: rem(30px);
    margin-top: 6px;
  }
}

.eb-ant-select__overflow {
  .ant-select-selection {
    max-height: rem(105px);
    overflow: auto;
    margin-top: 1px;
  }
}

.ant-select-dropdown-menu {
  margin: 0;
}

.ant-select-dropdown-menu-item {
  &:hover {
    background-color: color('purple-white');
  }

  &-selected {
    &:after {
      color: color('purple-white');
    }
  }
}

.ant-select-dropdown-menu-item-active {
  background-color: color('purple-white');
}
