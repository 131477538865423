@import '../../stylesheets/imports/index';

.eb-ant-badge {
  min-width: 10px;
  font-size: 10px;

  > *:first-child {
    display: inline-block;
  }

  .ant-badge-dot {
    height: 8px;
    width: 8px;
  }

  .ant-badge-count {
    padding: 0 2px;
  }

  .ant-badge-multiple-words {
    padding: 0 6px;
  }
}
