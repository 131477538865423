@import '../../stylesheets/imports/index';

.eb-form-input-layout {
  margin-bottom: 2rem;

  @media #{$mobile} {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  &__label {
    flex: 0 0 200px;
    @media #{$mobile} {
      flex: 0 0 auto;
      padding: 0.5rem 0;
    }
    .eb-label {
      color: color(grey48);
      font-weight: bold;
      padding-bottom: 0.75rem;
    }
  }

  &__input {
    min-width: 0;

    @media #{$mobile} {
      width: 100%;
    }
  }
}
