@import '../../stylesheets/imports/index';

.eb-tree {
  color: color('grey2');
  font-size: rem(14px);
  line-height: rem(40px);
  overflow: auto;

  &--is-empty {
    display: none;
  }

  .t-dark & {
    color: color('greye');
  }

  &__body {
    cursor: pointer;

    &:hover,
    &--active {
      color: color('grefb');
      // background-color: rgba(color('grey2'), 0.2);
      background-color: color('navy3');

      .t-dark & {
        color: color('grefb');
        // background-color: rgba(color('white'), 0.06);
        background-color: color('navy3');
      }
    }
  }

  &__children {
    &--is-collapsed {
      display: none;
    }
  }

  &__elice-folder {
    padding: 0 rem(8px);
    font-size: rem(14px);
    line-height: rem(36px);
    color: color('white');
    border-bottom: 1px solid color('grey38');
  }
}
