@import '../../stylesheets/imports/index';

.eb-vertical-item-list-input {
  &__value {
    font-weight: bold;
    font-size: 14px;
  }

  &__tail {
    position: absolute;
    left: 5px;
    top: 1.5rem;
    height: 150%;
    border-left: 2px solid color('greyc');
  }

  &--read-only &__tail {
    top: 1rem;
  }

  &__list {
    padding: 0;
    list-style-type: none;
    margin: 0;

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      line-height: normal;

      &:not(:first-of-type) {
        margin-top: 1.5rem;
      }

      &:last-of-type {
        .eb-vertical-item-list-input__tail {
          display: none;
        }
      }
    }
  }

  &__circle {
    flex: 0 0 12px;
    height: 12px;
    background-color: color('white');
    border: 2px solid color('purple');
    border-radius: 100%;
    margin-right: 12px;
    z-index: 1;
  }

  &__input {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
  }

  &__delete-button {
    padding: 0 16px;
    font-size: 12px;
  }

  &__add-button {
    border: 2px dashed color('greyd');
    background-color: color('greyfb');
    margin: 0.5rem 2.75rem 0 1.5rem;
    cursor: pointer;
    font-size: 12px;
    height: 2rem;
    line-height: 2rem;
    color: color('grey9');

    &:hover {
      background-color: color('greye');
      transition: backgrond-color 0.25s ease;
    }
  }
}
