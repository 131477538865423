@import '../../stylesheets/imports/index';

.eb-filter {
  width: 100%;
  padding: 1.5rem;
  border-radius: 8px;
  border: solid 1px color('greyd');
  background-color: color('white');

  .eb-dropdown {
    margin-bottom: 0.5rem;
  }

  &.no-selected-options {
    padding: 0;
    border-radius: 0;
    border: 0;
    background-color: transparent;

    .eb-dropdown {
      margin-bottom: 0;
    }
  }

  &__selected-option {
    border-radius: 3px;
    background-color: color('purple-white');
    cursor: pointer;
    padding: 0.5rem;
    margin-bottom: 0.5rem;

    &__icon {
      font-size: 0.5rem;
      color: color('purple');
    }
  }

  &__dropdown-wrapper,
  &__selected-option-wrapper {
    flex-wrap: wrap;
  }

  .eb-checkbox:not(.eb-checkbox--on-off) {
    margin-bottom: 0;
  }
  .eb-checkbox:not(last-child) {
    margin-right: 0;
  }
  .eb-dropdown__item {
    position: relative;
  }
}
