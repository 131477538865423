@import '../../stylesheets/imports/index';

.ant-confirm-body {
  .ant-confirm-title {
    overflow: initial;
    font-size: 20px;
    color: color('black');
    font-weight: bold;
  }

  .ant-confirm-content {
    margin: 0;
    padding-top: 16px;
    line-height: 24px;
    font-size: 16px;
    white-space: pre-wrap;
    background-color: color('white');
    color: color('grey2');
  }
}

.ant-confirm-btns {
  .ant-btn {
    background-color: transparent;
    cursor: pointer;
    padding: 12px 27px;
    margin: 0 6px;
    font-size: 14px;
    min-width: 80px;
    line-height: 16px;
    text-align: center;
    color: color('grey6');
    outline: none;
    border: 0;
    border-radius: 0;
    transition: all 0.3s ease;
    height: auto;

    &:hover {
      background-color: color('greyd');
    }
  }

  .ant-btn-primary {
    background-color: color('purple');
    color: color('white');

    &:hover {
      background-color: hover-color('purple');
    }
  }
}
