@import '../../stylesheets/imports/index';

.eb-index-feature-card {
  position: relative;
  background-color: color('white');
  width: 1024px;
  height: 276px;
}

.eb-index-feature-card-item {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 1024px;
  z-index: 0;
  transition: all 0.5s ease;

  &--selected {
    opacity: 1;
    z-index: 1;
    transition: all 0.5s ease;
  }

  &__feature {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    border: 1px solid color('greyd');
    padding: rem(30px) rem(24px);

    &__image {
      flex: 0 0 auto;
      margin-right: 1.5rem;
    }

    &__text {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      width: 100%;
    }

    &__description {
      white-space: pre-line;
    }
  }

  &__navigation {
    flex: 0 0 200px;
    border-top: 1px solid color('greyd');
    border-right: 1px solid color('greyd');

    &__item {
      border-bottom: 1px solid color('greyd');
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: color('purple-white');
        transition: all 0.3s ease;
      }

      &--selected {
        background-color: color('purple');
        font-weight: bold;
        transition: all 0.3s ease;

        & i,
        & div {
          color: color('white');
        }

        &:hover {
          background-color: color('purple-dark');
          transition: all 0.3s ease;
        }
      }
    }
  }
}

.eb-index-feature-card-item-mobile {
  display: none;
  background-color: color('white');
  margin-bottom: rem(8px);

  &__feature {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    border: 1px solid color('greyd');
    padding: rem(30px) rem(24px);
    width: 100%;

    &__image {
      flex: 0 0 auto;
    }

    &__text {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
    }

    &__description {
      white-space: pre-line;
      text-align: center;
      word-break: keep-all;
    }
  }

  &__navigation {
    flex: 0 0 200px;
    border-top: 1px solid color('greyd');
    border-right: 1px solid color('greyd');

    &__item {
      border-bottom: 1px solid color('greyd');
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: color('purple-white');
        transition: all 0.3s ease;
      }

      &--selected {
        background-color: color('purple');
        font-weight: bold;
        transition: all 0.3s ease;

        & i,
        & div {
          color: color('white');
        }

        &:hover {
          background-color: color('purple-dark');
          transition: all 0.3s ease;
        }
      }
    }
  }
}

@media (max-width: $media-lg-width) {
  .eb-index-feature-card {
    position: relative;
    background-color: transparent;
    width: 100%;
    height: auto;
  }

  .eb-index-feature-card-item {
    display: none;
  }

  .eb-index-feature-card-item-mobile {
    display: flex;
  }
}
